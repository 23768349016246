<template>
  <div class="body" @click="touchHandler" :key="query">
    <PostList v-if="category" :key="reload" :category="category.name"
              :comp="category.comp" :touchHandler="touchHandler"
              numrows="3" :rowlen="category.rowlen" :mrowlen="category.mrowlen" 
              :framekls="category.framekls" arrowMore="false"
              :isMobile="isMobile" :rparams="query ? {'query': query} : {}">
    </PostList>
  </div>
</template>

<script>
/* eslint-disable no-console */
import PostListContainer from './PostListContainer';
import PostList from './PostList';

export default {
  name: 'user-posts',
  mixins: [PostListContainer],
  props: {
    cat: { type: String }
  },
  components: {
    PostList,
  },
  data() {
    return {
      category: null,
      reload: false
    }
  },
  created() { 
    this.category = this.categories.find(obj => {return obj.name == this.cat});
  },
  watch: {
    cat : {
      handler(val) {
        console.log ("Watch change on cat " + val);
        this.category = this.categories.find(obj => {return obj.name == val});
        this.reload = !this.reload;
      }
    },
  }
}
</script>

<style>
a {
    cursor: pointer;
}
</style>
