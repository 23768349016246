<template>
<carousel :navigation-enabled="true"
          :perPage=1
          :navigationClickTargetSize=2
          :paginationPadding=0
          @navigation-click="navHandler">
  <slide :key="first" v-if="first != null">  
    <img ref="pimage" class="prep-img"
         :src="first.image || first"
         style="object-fit: cover;"
         @load="handleLoad"/>
    <div class="carousel-caption" v-if="post.title">
      <h3 class="">{{post.title}}</h3>
    </div>
  </slide>
  <slide v-for="prep in rest" :key="prep">
    <v-lazy-image v-if="loadAll" ref="pimage" class="prep-img"
                  :src="prep.image" style="object-fit: cover;"
                  />    
    <div class="carousel-caption" v-if="loadAll && (prep.title || prep.description)">
      <h3 class="">{{prep.title}}</h3>
      <p class="">{{prep.description}}</p>
    </div>
  </slide>
  <inset-navigation slot="navigation" lrSize="40">
  </inset-navigation>
</carousel>
</template>
<script>
/* eslint-disable no-console */

import InsetNavigation from './InsetNavigation';
import VLazyImage from "v-lazy-image";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: 'LazyCarousel', 
  components: {
    VLazyImage,
    InsetNavigation,
    Slide,
    Carousel,
  },
  props: [
    'post',
  ],
  data() {
    return {
      'loadAll':  false,
      'first': this.post.preps ? this.post.preps[0] : this.post.image,
      'rest': this.post.preps ? this.post.preps.slice(1) : [],
    };
  },
  computed: {
    /*first() {
      return this.loadone ? this.post.preps[0] : null;
    },
    rest() {
      return this.loadone ? this.post.preps.slice(1) : this.post.preps;
    },*/
  },
  created() {
    // if (this.post.thumbs)
    // {
    //   let preps = [];
    //   this.post.thumbs.split(',').map((p) => {
    //     preps.push({title: '', description: '', image: p});
    //   });
      
    //   this.first = preps ? preps[0] : this.post.image;
    //   this.rest = preps ? preps.slice(1) : [];
    // }
  },
  mounted() {
    // console.log ("Lazy carousel mounted " + this.post.preps.length + " and " + this.rest.length);
  },
  methods: {
    handleLoad() {
      this.loadAll = true;
    },
    navHandler() {
    },
  }
}
</script>
<style scoped>

.v-lazy-image {
    -filter: blur(10px);
    -transition: filter 0.1s;
}

.v-lazy-image-loaded {
    -filter: blur(0);
}

.VueCarousel-wrapper {
    min-height: unset;
    /*500px;*/
}


.VueCarousel-navigation-prev {
    left:  20px
}

.VueCarousel-navigation-next {
    right: 20px;
}

.carousel-caption{
  background: hsl(0deg 0% 0% / 54%) !important;
}

</style>
