<script>
/* eslint-disable no-console */
import PreYoutube from  './PreYoutube';
import PreQuickie from  './PreQuickie';
import PrePhotos from  './PrePhotos';

import { mapMutations } from "vuex";

export default {
  name: 'post-lister', 
  props: {
    isMobile: { type: Boolean },
    touchHandler: { type: Function },
    showSideBar: { type: Function },
    hideSideBar: { type: Function },
    ctx: { type: String }
  },
  components: {
  },
  created() { 
    console.log ("Created container with context " + this.ctx );
    this.categories = [
      { name: 'quickie', label: "Khal Quickies", rowlen: 4, mrowlen: 4, rows: 1,
        comp: PreQuickie, kls: "x2-body", last: false,
        framekls: "frame-427319030 frame"},
      { name: 'photo', label: "Photos Recipes", rowlen: 4, mrowlen: 4, rows: 1,
        comp: PrePhotos, kls: "x3-body", last: false,
        framekls: "frame-427319030 frame"},
      { name: 'video', label: "Video Recipes", rowlen: 4, mrowlen: 1, rows: 3,
        comp: PreYoutube, kls: "x1-body", last: true, 
        framekls: "frame-427318981-x1 frame-427318981"}
    ];
    this.setViewContext(this.ctx);
  },
  methods: {
    ...mapMutations(["setViewContext"]),
  }

}
</script>
