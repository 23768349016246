<template>
<div class="profile-follower-user-1 profile-follower-user-7">
    <div class="profile-follower-flex-row profile-follower-flex">
      <div class="profile-follower-group-427319012-12 comment-action" @click="showProfile">
        <img :src="user.thumbnail" v-bind:style="{'backgroundImage': 'url(' + static_fn('img/default-avatar.png') + ')'}"/>
        <a href="/badges"><img class="image-3 image" :src="badge" /></a>
      </div>        
      <div class="profile-follower-text-container1">
        <div class="profile-follower-text-2 valign-text-middle inter-bold-eerie-black-25px comment-action" @click="showProfile">
          <div class="name-follow-row">
            <span class="inter-bold-eerie-black-25px name">{{user.name}}</span>
            <img v-if="user.gov_id_proof_status === 'Approved'" class="image-checkmark" src="../assets/mdi_check-decagram.png" />
            <div v-if="me && me.username != user.username" @click="handleFollow" class="profile-follower-frame-427318997 profile-follower-frame mobile-hide" :key="follow_text">
              <div class="profile-follower-text valign-text-middle inter-normal-white-17px-2">
                <span><span class="inter-normal-white-17px-2">{{follow_text}}</span> </span>
              </div>
            </div>
          </div>
          
          <div class="profile-follower-flex-row-1 inter-normal-eerie-black-17px-2">
            <img class="profile-follower-recommend-1" :src="static_fn('img/recommend-1-25@2x.png')" alt="recommend 1" />
            <div class="profile-follower-text-4"><span class="inter-normal-eerie-black-17px-2">{{user.reputation}}</span></div>
            <div class="profile-follower-group-427318974">
              <img class="profile-follower-icon" :src="static_fn('img/user--multiple-1-41@2x.png')" alt="icon-user" />
              <div class="profile-follower-text-6 inter-normal-eerie-black-17px-2">
                <span class="inter-normal-eerie-black-17px-2">{{user.total_followers}}</span>
              </div>
            </div>
          </div>
        </div>
        <!--div class="profile-follower-text-3 inter-normal-eerie-black-17px-2">
          <span class="inter-normal-eerie-black-17px-2">@{{user.username}}</span>
        </div-->
        
      </div>
     
    </div>
    
</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: 'UserLine', 
  props: [
    'user',
  ],
  computed: {
    badge: function () {
      return this.static_fn(this.user.full_badge_image || 'img/image-2-2@2x.png');
    },
    follow_text: function() {
      return this.user.follow_state ? "Unfollow" : "Follow";
    },
    me: function() {
      return this.getUserInfo();
    }
  },
  data () {
    return {
      userData: {}
    }
  },
  methods: {
    ...mapActions(["postOrLogin"]),
    ...mapGetters(["getUserInfo"]),
    showProfile(event) {
      event.preventDefault();
      event.stopPropagation();
      const routeData = this.$router.resolve({name: `profile-detail`, params: {username: this.user.username} });
      window.open(routeData.href, '_blank');

    },
    handleFollow() {
      this.postOrLogin(`accounts/follow/toggle/${this.user.pk}`).then((data) => {
        this.user.follow_state = data.state;
        this.user.total_followers = data.count;
      });
    },
  }
}
</script>
<style scoped>
.profile-follower-text-container {
  padding-top:10px;
}

.post_details .profile-follower-user-1 {
  background-color: transparent !important;
}

.profile-follower-frame {
  cursor: pointer;
}

a {
  display: block;
}
.image-checkmark{
  height: 30px;
  widows: 30px;
}
.image {
  height: 26px;
  object-fit: cover;
  position: absolute;
  width: 26px;
}
.image-3 {
    left: auto !important;
    top: auto !important; 
    right: -5px;
    bottom: -5px;
}
.profile-follower-text-container1{
  margin-left: 10px;
  width: 100%;
  position: relative;
}
.name-follow-row{
  display: flex;
  flex-direction: row;
}
.name-follow-row .name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.profile-follower-group-427319012-12{
  align-self: flex-start !important;
}
.profile-follower-frame-427318997{
  margin-bottom: 0px !important;
}
.comment-action{
  height: auto !important;
}
.profile-follower-flex-row-1{
  align-self: start;
  margin: inherit !important
}
</style>
