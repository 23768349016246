import axios from 'axios';
import axiosRetry from 'axios-retry';
import store from '@/store';

//import isNetworkOrIdempotentRequestError from 'axios-retry';
const qs = require('qs');

//import AuthService from '../auth/AuthService';
const API_URL = ''; //http://127.0.0.1:8000';

const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });

axios.defaults.headers.common = {
  'Content-Type': 'application/json',
};
axios.defaults.timeout = 15000;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

export class APIService {
  constructor() {
    let epoint = new Array('', '');
    if (window.post_api_epoint) epoint = window.post_api_epoint.split('?');

    this.base = window.base_api || 'posts';
    this.epoint = epoint[0]; //.replace(/\//g, '');
    log.info(
      'Epoint is ' +
        epoint[0] +
        ' and this epoint ' +
        this.epoint +
        ' base: ' +
        this.base
    );
    this.ext = epoint.length > 1 ? '?' + epoint[1] : '';
    this.token = window.csrf;
    this.user = window.api_user;

    this.jwt = '';
    this.refresh = '';

    axiosRetry(axios, {
      retries: 2,
      shouldResetTimeout: true,
      retryDelay: axiosRetry.exponentialDelay,
      retryCondition: this.retryCondition.bind(this),
    });
    this.map_prom = axios
      .get('/api/static/', { headers: {} })
      .then((response) => {
        this.static_map = response.data;
      })
      .catch((err) => {
        log.info('Static map error ' + err.code + ' ' + err.message);
        this.static_map = {};
      });
  }

  mkstatic(fn) {
    return this.static_map[fn];
  }

  returnCSRF() {
    return this.token;
  }

  retryCondition(err) {
    let ret =
      err.code == 'ECONNABORTED' ||
      axiosRetry.isNetworkOrIdempotentRequestError(err);
    log.info('ERRor ' + err.code + ' msgs ' + err.message + ' ret ' + ret);
    return ret;
  }

  getFeedPosts(perpage = 12) {
    const epoint = this.epoint;
    const ext = this.ext;
    const base = this.base;
    log.info('getfeedposts with ' + perpage);
    const url = `${API_URL}/api/${base}/${epoint}/${ext}?perpage=${perpage}`;
    return axios.get(url, { headers: {} }).then((response) => response.data);
    /*
        return {'count': 0,
              'numpages': 0,
              'data': {},
              'nextlink': '',
              'prevlink': '',
              'sizes': ''};
      */
  }

  getPost(pk) {
    const epoint = this.epoint;
    const ext = this.ext;
    const base = this.base;
    const url = `${API_URL}/api/${base}-detail/${epoint}/${pk}/${ext}`;
    return axios.get(url, { headers: {} }).then((response) => response.data);
  }

  rePost(pk) {
    //const epoint = this.epoint;
    //const ext = this.ext;
    const base = this.base;
    const url = `${API_URL}/api/${base}/repost/${pk}`;
    return axios
      .get(url, { headers: {}, timeout: 40000, 'axis-retry': { retries: 0 } })
      .then((response) => response.data);
  }

  getPostDetailList(pk) {
    const epoint = this.epoint;
    const ext = this.ext;
    const base = this.base;
    const url = `${API_URL}/api/${base}-detail-list/${epoint}/${pk}/${ext}`;
    return axios.get(url, { headers: {} }).then((response) => response.data);
  }

  getComments(pk, rev) {
    let epoint = !rev ? 'comments' : 'rcomments';
    const url = `${API_URL}/posts/api/${epoint}/${pk}/`;
    return axios.get(url, { headers: {} }).then((response) => response.data);
  }

  getGeneric() {
    let epoint = this.epoint;
    const base = this.base;
    const url = `${API_URL}/api/${base}/${epoint}/`;
    return axios.get(url, { headers: {} }).then((response) => response.data);
  }

  getItemsByURL(link) {
    const url = `${API_URL}${link}`;
    return axios.get(url, { headers: {} }).then((response) => response.data);
  }

  asyncButton = function(url, data) {
    //, token) {
    if (typeof data == 'undefined') data = {};

    data['csrfmiddlewaretoken'] = this.token;
    let data_out = qs.stringify(data);
    return axios
      .post(url, data_out, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => response.data);
  };

  async login(username, password) {
    const payload = { username: username, password: password };
    log.info('Username ' + payload.username);
    var err = null;
    await axios
      .post('/api/accounts/token/', qs.stringify(payload), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((response) => {
        this.jwt = response.data.access;
        this.refresh = response.data.refresh;
        log.info(`Token returned ${this.jwt}`);
      })
      .catch((err) => {
        /* Try to refresh here */
        log.info(`Error is ${err}`);
      });
    return err;
  }

  get(url, payload = {}) {
    const data = {
      ...(true && {
        headers: {
          ...{ 'Content-Type': 'application/x-www-form-urlencoded' },
          ...(this.jwt && { Authorization: `Bearer ${this.jwt}` }),
        },
      }),
      ...(payload && { params: payload }),
    };
    return axios.get(url, data).then((response) => response.data);
  }

  get_data(app, view, category, params) {
    const url = `/api/${[app, store.getters.view_context, view, category].join(
      '/'
    )}/`;
    return this.get(url, params);
  }

  post(url, payload, addApiPrefix = true) {
    log.info('Posting ' + url);
    return axios
      .post(`${addApiPrefix ? '/api' : ''}/${url}/`, qs.stringify(payload), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((response) => response.data);
  }
  put(url, payload, addApiPrefix = true) {
    log.info('Posting ' + url);
    return axios
      .put(`${addApiPrefix ? '/api' : ''}/${url}/`, qs.stringify(payload), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((response) => response.data);
  }

  delete(url, payload, addApiPrefix = true) {
    log.info('Posting ' + url);
    return axios
      .delete(`${addApiPrefix ? '/api' : ''}/${url}/`, qs.stringify(payload), {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((response) => response.data);
  }

  formDataPost(url, payload, addApiPrefix = true) {
    log.info('Posting ' + url);
    return axios
      .post(`${addApiPrefix ? '/api' : ''}/${url}/`, payload, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((response) => response.data);
  }
}
