<template>
<div>
  <span v-if="user">
    <div class="contacts">
      <span v-for="item in show_items" :key="item.pk">
        <component :is="subcomp" :item="item">
        </component>
      </span>
    </div>
    <div class="maybe-less" v-if="items.length > numDefault">
      <a :class="'group-427318966 '+ evenless"
         @click="showRemaining">
        <img class="vector-group-427318966 vector-group" :src="static_fn('img/vector-18@2x.png')" v-bind:style="{'transform': 'rotate(' + rotateDeg + 'deg)'}"/>
        <div class="text-34 inter-normal-eerie-black-17px-2">
          <span class="inter-normal-eerie-black-17px-2">Show {{remainText}} </span>
        </div>
      </a>
      <a class="group-427318966 even-more" v-if="expand && items.length < total" @click="$router.push(all)">
        <div class="text-34 inter-normal-eerie-black-17px-2">
          <span class="inter-normal-eerie-black-17px-2">Show All {{total}} </span>
        </div>
        <img class="vector-group-427318966 vector-group" :src="static_fn('img/vector-18@2x.png')" v-bind:style="{'transform': 'rotate(-90deg)'}"/>
      </a>
    </div>
    <div class="flex-row-1" v-else-if="!items">
      <div class="inter-normal-eerie-black-17px-2">
        <span class="inter-normal-eerie-black-17px-2">{{zero_text}}</span>
      </div>
    </div>
  </span>
  <div class="flex-row-1" v-else>
    <div class="flex-col">
      <div class="inter-normal-eerie-black-17px-2">
        <span class="inter-normal-eerie-black-17px-2">{{signin_text}}</span>
      </div>
      <a @click="showLogin" class="btn-signin">
        <img :src="static_fn('img/ic_user.png')" class="user-multiple-1-overlap-group2">
        <div class="text-16 valign-text-middle inter-normal-eerie-black-17px">
          <span class="inter-normal-eerie-black-17px">Sign in</span>
        </div>
      </a>
    </div>
  </div>
</div>  
</template>
<script>
/* eslint-disable no-console*/
import { mapGetters, mapMutations } from 'vuex';
import SideFollowed from './SideFollowed';
import SideGroup from './SideGroup';

export default {
  name: 'sidebar-block',
  components: {
    SideFollowed,
    SideGroup
  },
  props: [
    'comp',
    'zero_text',
    'signin_text',
    'items',
    'title',
    'numDefault',
    'total',
    'all'
  ],
  data() {
    return {
      expand: false,
    }
  },
  computed: {
    user () {
      return this.getUserInfo()
    },
    evenless() {
      return this.expand && this.items.length < this.total ? "even-less" : ""
    },
    show_items() {
      let real_items = [...this.items].sort((a, b) => (b.is_active) ? 1 : -1);
      return this.expand ? real_items : real_items.slice(0, this.numDefault);
    },
    subcomp() {
      return {'follow': SideFollowed,
              'group': SideGroup,
             }[this.comp];
    },
    rotateDeg() {
      return this.expand ? "180" : "0";
    },
    remainText() {
      return this.expand ? "Less" : (this.items.length - this.numDefault) + " More";
    },
  },
  created() {
  },
  mounted() {
  },
  updated() {
  },
  methods: {
    ...mapMutations(["showLogin"]),
    ...mapGetters(['getUserInfo']),
    showRemaining() {
      this.expand = !this.expand;
    },
  }
}

</script>
<style scoped>
  .even-more,
  .even-less {
  min-width: 0;
  }
  .even-more {
  justify-content: flex-end;
  gap: 8px;
  margin-left: 0px;
  }
  .even-less .inter-normal-eerie-black-17px-2,
  .even-more .inter-normal-eerie-black-17px-2 {
  font-size: .9em;
  }
  .even-more .inter-normal-eerie-black-17px-2 {
  color: grey;
  }
  .even-more img {
  margin-bottom: 5px;
  }

  .maybe-less {
  display: flex;
  gap: 20px;
  }
</style>
