<script>
const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });
import VueYoutube from 'vue-youtube';

export default {
  extends: VueYoutube,
  methods : {
    playerStateChange(e) {
      log.info ("PSC on " + e.data);
      if (e.data !== null && e.data !== -1) { 
        this.$emit(this.events[e.data], e.target)
      }
    }
  }
}
  
</script>
