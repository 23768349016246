<template>
  <div class="x1">
    <div class="comment-line recipe-user-line">
      <UserLine :user="comment.author">
      </UserLine>
      <div class="address valign-post-text-middle address-2 inter-normal-black-17px">
        <span><span class="inter-normal-black-17px">{{comment.timesince}}</span> </span>
        <div>
          
          <b-dropdown size="sm" v-if="user && (user.username == comment.author.username || post.user.username == user.username)" variant="link" toggle-class="text-decoration-none" no-caret>
            
            <template #button-content>
              <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
            </template>
            <b-dropdown-item-button variant="danger" @click="onDelete">
              <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
              Delete {{ user.pk }}
            </b-dropdown-item-button>
            <!-- <b-dropdown-item href="#">Another action</b-dropdown-item>
            <b-dropdown-item href="#">Something else here...</b-dropdown-item> -->
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="comment-line">
      <div class="post-overlap-group1-1">
        <p class="post-text-3 valign-post-text-middle inter-normal-eerie-black-17px-2">
          <span>
            <span class="inter-normal-eerie-black-17px-2">{{comment.text}}</span>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import UserLine from './UserLine';
  
export default {
  name: 'PostComment',
  props: [
    'comment',
    'onDelete',
    'post',
    'user'
  ],
  components: {
    UserLine
  },
  mounted() {
    this.$nextTick(function() { 
      let input = this.$el.querySelector('.comment-input');
      if (input)
        input.addEventListener('keypress',
                               this.handleKey.bind(this))
    })
  },
  methods: {
    handleKey(event) { 
      if (event.which == 13 && !event.shiftKey) {
        event.preventDefault();
        this.buttons.edit.click();
      }
    },

    
  }
}
</script>

<style scoped>
  .post-text-3{
    margin-bottom: 0px !important;
  }
  .btn-group > .btn, .btn-group-vertical > .btn{
    padding: 0px !important;
    --bs-btn-padding-y: 0rem !important;
    --bs-btn-padding-x: 0rem !important;
    --bs-btn-font-size: 1rem !important;
    --bs-btn-border-radius: 0rem !important;
  }
</style>
