<template>
  <div class="body" @click="touchHandler" :key="query">
    <Topbuttons>
    </Topbuttons>
    <div v-for="category in categories" :key="category.name" :class="category.kls">
      <PostList :category="category.name" :comp="category.comp"
                :label="category.label" :touchHandler="touchHandler"
                :numrows="category.rows" :rowlen="category.rowlen"
                :mrowlen="category.mrowlen" 
                :framekls="category.framekls"
                :arrowMore="!category.last"
                :waySide="isMobile && !category.last"
                :isMobile="isMobile" :rparams="query ? {'query': query} : {}">
      </PostList>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import Topbuttons from './Topbuttons';
import PostList from './PostList';
import PostListContainer from './PostListContainer';

export default {
  name: 'home',
  mixins: [PostListContainer],
  props: {
    isMobile: { type: Boolean },
    touchHandler: { type: Function },
    showSideBar: { type: Function },
    hideSideBar: { type: Function },
    query: String,
  },
  components: {
    Topbuttons,
    PostList
  },
  data(){
    return {
      lineImg: null,
    }
  },
  created() {
    if (this.query)
      this.setViewContext("search");
    else
      this.setViewContext("feeds");
  },
  destroyed() {
  },
  methods:{
  },
  mounted(){
  },
  watch: {
    query: function () {
      console.log(this.query);
      if (this.query)
        this.setViewContext("search");
      else
        this.setViewContext("feeds");
    },
  },
  updated() {
  }
}
</script>

<style>
a {
    cursor: pointer;
}
</style>
