<template>
<div class="overlap-group-9" :style="thumbnail"
     @mouseover="playPreview" @mouseleave="stopPreview" ref="me">
  <div class="rectangle-34624102 rectangle"></div>
  <img class="g7433 g7433-2" :src="playImg" alt="g7433"/>
  <div :class="videoStyle">
    <component v-if="compType" ref="subcomp"
               :is="compType" :post="post"
               :width="width" :height="height">
    </component>      
  </div>
</div>
</template>
<script>
const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });

import PreVideo from  './PreVideo';
import PreVideoFile from  './PreVideoFile';
export default {
  name: 'Preview',
  props: ['post'],
  components: {
    PreVideo,
    PreVideoFile,
  },
  computed: {
    videoStyle: function() {
      return {
        'preview': true,
        'preview-playing': this.pRunning,
        'preview-off': !this.pRunning
      }
    },
  },
  data() {
    return {
      compType: null,
      thumbnail: "background-image: url(\"" + this.post.post_image.thumbnail + "\")",
      playImg: "",
      playBaseImg: "img/multiple-svgrepo-com-1-1.png",
      pRunning: false,
      width: 0,
      height: 0
    }
  },
  mounted() {
    var that = this;
    this.api.map_prom.then(() => {
      if (that.post.video_id) {
        that.playBaseImg = "img/g7433-1@2x.png";
        that.compType = PreVideo;
      }
      /*else if (post.video_file) {
      that.playBaseImg = "img/group-3.png";
      that.compType = PreVideoFile;
      }*/
      that.width = that.$refs.me.clientWidth;
      that.height = that.$refs.me.clientHeight;
      log.info ("Width is " + that.width);
      that.playImg = that.api.mkstatic(that.playBaseImg);
    });
  },
  methods: {
    playPreview() {
      if (this.compType) {
        this.pRunning = true;
        let subcomp = this.$refs.subcomp;
        subcomp.playPreview();
        setTimeout (subcomp.reset.bind(subcomp), 20000);        
      }
    },
    stopPreview() {
      if (this.compType) {
        this.pRunning = false;
        this.$refs.subcomp.stopPreview();
      }
    }
  }
}
</script>
