<template>
<div class="x1-frame-427319030 x1-frame" :style="thumbnail">
  <!-- <div class="overlap-group-1"
       @mouseover="playPreview" @mouseleave="stopPreview" ref="me"> -->
  <div class="overlap-group-1" ref="me">
    <img class="group" :src="static_fn('img/group-1@2x.png')" />
    <div :class="videoClass" :key="show"
         :style="videoStyle">
      <video ref="video"
             class="vjs-default-skin vjs-fill video-quickie"
             :width=width :height=height
             :options="playerVars">
      </video>
    </div>
    <div class="text-5 valign-text-middle inter-bold-white-20px">
      <span><span class="inter-bold-white-20px preview-title">{{post.title}}
      </span> </span>
    </div>
    <div class="flex-row-1 flex-row" @click.stop="showProfile">
      <div class="avatar-1" :style="avatar"></div>
      <div class="text-6 inter-normal-white-17px-2">
        <span class="inter-normal-white-17px-2">
          {{post.user.full_name}}
          <img v-if="post.user.gov_id_proof_status == 'Approved'" class="image-checkmark" src="../assets/mdi_check-decagram.png" />
        </span>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import videojs from 'video.js';

const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });


export default {
  name: 'PreQuickie',
  props: ['post'],
  components: {
  },
  computed: {
    videoClass: function() {
      return {
        'preview': true,
        'preview-playing': this.show,
        'preview-off': !this.show
      }
    },
    thumbnail: function() {
      return (!this.show) ? this.bg_thumbnail : "background: black;";
    },
  },
  data() {
    return {
      bg_thumbnail: "background-image: url(\"" + this.post.post_image.thumbnail + "\")",
      player: null,
      show: false,
      playing: false,
      ready: false,
      width: 0,
      height: 0,
      videoStyle: "",
      playerVars: {
        sources: [{
          type: "video/mp4",
          'src': this.post.video_file + "#t=0,5",
        }],
        controls: 0,
        preload: 'metadata',
        muted: true,
        loop: 0,
        loadingSpinner: false,
        errorDisplay: false,
        'poster': this.post.post_image,
        autoplay: true,
      },
      avatar: "background-image: url(\"" + this.post.user.thumbnail + "\"), url(\"static/img/default-avatar.png\")",
    }
  },
  updated() {
    log.info ("Updating prevideo " + this.width + " x " + this.height);
    var that = this;
    this.$nextTick(function() {
      log.info ("UNT " + that.show + " and " + that.ready );
      if (that.show) {
        if (!that.ready) {
          that.player = videojs(that.$refs.video, that.playerVars, (() => {
            log.info ("VIDEOJS preview ready");
            that.ready = true;
            that.playPreview();
          }))
        }
        else {
          that.playPreview();
        }
      }
    });
  },
  mounted() {
    var that = this;
    log.info ("Mounting preyoutube " + this.width + " x " + this.height);
    this.api.map_prom.then(() => {
      that.width = that.$refs.me.clientWidth;
      that.height = that.$refs.me.clientHeight;
      this.videoStyle = "width: " + this.width + "px; height: " + this.height + "px;";
    });
  },
  methods: {
    __playPreview() {
      return this.player.play();
    },
    showProfile(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$router.push({name: `profile-detail`, params: {username: this.post.user.username, fullname: this.post.user.full_name} });
    },
    playPreview() {
      var that = this;
      log.info ("Ready " + that.ready + " Show: " + that.show);
      this.show = true;
      if (this.ready) {
        this.__playPreview().then(() => {
          if (that.timeout)
            clearTimeout(that.timeout);
          that.playing = true;
          that.timeout = setTimeout (that.__reset.bind(that), 10000);          
        });
      }
    },
    __reset() {
      this.player.currentTime(0);
      this.playPreview();
    },
    __stopPreview() {
      return new Promise((resolve, reject) => {
        this.player.pause();
        if (this.player.paused()) {
          resolve();
        }
        else {
          reject();
        }
      })
    },
    stopPreview() {
      if (this.timeout) 
        clearTimeout(this.timeout);
      if (this.playing) { 
        var that = this;
        this.__stopPreview().then(() => {
          log.info ("Stopped preview");
          that.playing = false;
        })
      }
      this.show = false;
      this.ready = false;
    }
  }
}
</script>
<style scoped>
  .text-6{
    text-wrap:initial !important;
    width: 90% !important;
  }
</style>
