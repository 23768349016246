var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[(_vm.overlayLoading)?_c('loading-overlay'):_vm._e(),_c('div',{staticClass:"x02-get-verified screen",attrs:{"id":""}},[_c('div',{staticClass:"back",on:{"click":function($event){return _vm.$router.back()}}},[_vm._m(0),_vm._m(1)]),(_vm.status)?_c('div',{staticClass:"overlap-group71"},[_vm._m(2),_vm._m(3)]):_vm._e(),(!_vm.status)?_c('div',{staticClass:"overlap-group71"},[_vm._m(4),_vm._m(5)]):_vm._e()]),_vm._m(6)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overlap-group-8"},[_c('div',{staticClass:"page-disabled"},[_c('div',{staticClass:"ic-carat-left"})]),_c('img',{staticClass:"vector-3",attrs:{"src":require("../assets/vector-20@2x.png"),"alt":"Vector"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"place inter-medium-log-cabin-17px"},[_c('span',{staticClass:"inter-medium-log-cabin-17px"},[_vm._v("Back")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"text-27 valign-text-middle inter-bold-black-25px"},[_c('span',[_c('span',{staticClass:"inter-bold-black-25px"},[_vm._v("Payment Completed")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-28 inter-normal-eerie-black-17px"},[_c('span',{staticClass:"inter-normal-eerie-black-17px"},[_vm._v("Your payment has been confirmed. Our verification team will soon verify your request.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"text-27 valign-text-middle inter-bold-black-25px"},[_c('span',[_c('span',{staticClass:"inter-bold-black-25px"},[_vm._v("Payment Failed")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-28 inter-normal-eerie-black-17px"},[_c('span',{staticClass:"inter-normal-eerie-black-17px"},[_vm._v("Something went wrong with your payment. Incase you have any queries or want to retry the payment, please contact us on info@khal.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('h4',{staticClass:"text-center"},[_vm._v("© Khal: All rights reserved ")])])
}]

export { render, staticRenderFns }