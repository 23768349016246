<template>
<div class="recipe-detail-cont" :key="reload">
  <div class="iframe-wrapper">
    <iframe title="YouTube video player" :src="post.embed" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
  </div>
  <h2>{{post.caption}}</h2>
  <hr/>
  <!--div class="recipe-comments recipe-cell">
    <LikeAttrs v-if="!shownew && post.comment_form" :comment="post" :commentimg="commentimg" @comment="handleComment">
    </LikeAttrs>
    <EditableComment v-if="shownew && post.comment_form" @clicked="updateComment" @hide="handleHide"
                     :inhtml="post.comment_form" :inkey="0"
                     :parent="post" :abase="abase" :kls="commentkls" :epoint="epoint">
    </EditableComment>
    <PostComments v-if="post.num_comments" :post="post" :newform="post.comment_form" 
                  :count="post.num_comments" :abase="abase">
    </PostComments>
  </div-->
</div>
</template>

<script>
/* eslint-disable no-console */
//import PostComments from './PostComments';
//import EditableComment from './EditableComment';
//import LikeAttrs from './LikeAttrs';

const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });

export default {
  name: 'AboutUsVideo',
  components: {
    //LikeAttrs,
    /*PostComments,
    EditableComment*/
  },
  props: [
    'post',
    'commentimg',
  ],
  data() {
    return {
      //'static_url': window.api_static_url,
      first: true,
      waydir: 0,
      releaseScroll: false,
      waytopper: null,
      commentHeight: "300px",
      showcomment: "false",
      winWidth: window.innerWidth,
      abase: "contact",
      shownew: false,
      commentkls: "comment-in",
      reload: false,
      epoint: "comments"
    };
  },
  mounted() {
    //this.apiItems = this.api.getGeneric.bind(this.api);
    log.info ("Mount AUV " + this.api);
  },
  updated() {
    log.info ("Updated on AUV " + this.post.pk + " form " + this.post.comment_form + " api ");
  },
  methods: {
    handleComment() {
      this.shownew = true;
      log.info ("Activate Comment box ");
    },
    updateComment() {
      log.info ("Update comment");
      this.reload = !this.reload;
    },
    handleHide() {
      this.shownew = false;
    },
  }
}
</script>
<style>
</style>
