<template>
  <img ref="pimage" class="prep-img prep-loadone"
       :srcset="post.thumbs"
       :src="post.image"
       :sizes="sizes" style="object-fit: cover;"
       @load="handleLoad"/>
</template>
<script>
const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });

export default {
  name: 'StoryImage',
  components: {
  },
  props: [
    'post',
    'sizes',
  ],
  data() {
  },
  mounted() {
    log.info ("Loading StoryImage " + this.post.pk);
  },
  methods: {
    handleLoad() {
      this.$emit ("loaded", this.post.pk);
    },
  }
}
</script>
<style scoped>
</style>
