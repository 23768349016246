<template>

  <div class="spinner" v-if="loading || loaderror">
    <div class="loaderr" v-if="loaderror">
      <p>
        We seem to have an issue loading data from the server
      </p>
      <p>
        Check your network connectivity and try again
      </p>
    </div>
    <div v-else>
      <img src="../assets/loading.svg" alt="loading"/>
    </div>
  </div>
</template>

<script>
/*const getLogger = require('webpack-log');
  const log = getLogger({ name: 'vue-khal' });*/


/* eslint-disable */
export default {
    name: 'Loading',
    props: ['loading',
            'loaderror'],
    data () {
        return {}
    },
    updated() {
      //log.info ("Updating loader " + this.loading + " and " + this.loaderror);
    },

}
</script>

<style>
    h1{
        color: black;
    }
  .spinner {
    -position: absolute;
    display: flex;
    justify-content: center;
    height: 100vh;
    -width: 100vw;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .loaderr {
      margin-top: 10vh;
  }
  .loaderr p {
      font-size: 24px;
      font-weight: bold;
  }
</style>
