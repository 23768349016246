<template>
  <div class="profile-body">
    <back/>
    <LoadingOverlay v-if="overlayLoading"/>
    <div id="" class="x02-get-verified screen">
      <div v-if="!user || user.gov_id_proof_status === 'Not Started'" class="overlap-group71">
        <h1 class="text-27 valign-text-middle inter-bold-black-25px">
          <span><span class="inter-bold-black-25px">Get Verified</span> </span>
        </h1>
        
        
        <div class="group-427319040">
          <div class="text-2 inter-semi-bold-eerie-black-20px">
            <span class="inter-semi-bold-eerie-black-20px">
              Benefits of getting Verified
              </span>
          </div>
          <div class="group-427319046">
            <div class="group-42731903">
              <div class="overlap-group">
                <img
                  class="icon-check_mark"
                  src="../assets/vector-92@2x.png"
                  alt="icon-check_mark"
                />
              </div>
              <p
                class="this-will-be-the-benefit inter-normal-eerie-black-17px"
              >
                <span class="inter-normal-eerie-black-17px"
                  >To make money on Khal you need to get verified.</span
                >
              </p>
            </div>
            <div class="group-42731903">
              <div class="overlap-group">
                <img
                  class="icon-check_mark"
                  src="../assets/vector-92@2x.png"
                  alt="icon-check_mark"
                />
              </div>
              <p
                class="this-will-be-the-benefit inter-normal-eerie-black-17px"
              >
                <span class="inter-normal-eerie-black-17px"
                  >If you think the blue check looks cool next to your picture</span
                >
              </p>
            </div>
            <div class="group-42731903">
              <div class="overlap-group">
                <img
                  class="icon-check_mark"
                  src="../assets/vector-92@2x.png"
                  alt="icon-check_mark"
                />
              </div>
              <p
                class="this-will-be-the-benefit inter-normal-eerie-black-17px"
              >
                <span class="inter-normal-eerie-black-17px"
                  >If you think the blue check looks cool next to your picture.</span
                >
              </p>
            </div>
          </div>
          <div class="yt-div row">
            <iframe class="col-lg-5 col-md-12" width="560" height="315" src="https://www.youtube.com/embed/6lRUz740Ugo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe class="col-lg-5 col-md-12" width="560" height="315" src="https://www.youtube.com/embed/HCqN3jcti5Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

          </div>
          <div v-if="user" class="text-2 inter-semi-bold-eerie-black-20px">
            <span class="inter-semi-bold-eerie-black-20px">
              Fill the Form below in order to get Verified
              </span>
          </div>
          <div v-else class="yt-div mt-5 text-2 inter-semi-bold-eerie-black-20px">
            <span class="inter-semi-bold-eerie-black-20px">
              <span class="sign-text" @click="showLogin">Login</span> or <a href="/accounts/signup/" class="sign-text">Signup</a> to apply for verification
            </span>
          </div>
          <form v-if="user" id="verificationForm" @submit="checkForm" @keydown.enter="checkForm" method="post">
            <div class="group-427319046" >
              <div class="group-427319047">
                <div class="text-29 inter-semi-bold-eerie-black-17px">
                  <span class="inter-semi-bold-eerie-black-17px"
                    >Name</span
                  >
                </div>
                <input type="text" class="text-form-control" placeholder="Name" id="name" v-model="verification.name">
                <span class="field-error" v-if="this.errors.find(o => o.name === 'name')"> {{this.errors.find(o => o.name === 'name').error}} </span>
              </div>
              <!-- <div class="group-427319047">
                <div class="text-29 inter-semi-bold-eerie-black-17px">
                  <span class="inter-semi-bold-eerie-black-17px"
                    >Last Name</span
                  >
                </div>
                <input type="text" class="text-form-control" placeholder="Last Name" id="last_name" v-model="verification.last_name">
              </div> -->
              <div class="group-427319047">
                <div class="text-29 inter-semi-bold-eerie-black-17px">
                  <span class="inter-semi-bold-eerie-black-17px"
                    >Full Address</span
                  >
                </div>
                <input type="text" class="text-form-control" placeholder="Full Address" id="address" v-model="verification.address">
                <span class="field-error" v-if="this.errors.find(o => o.name === 'address')"> {{this.errors.find(o => o.name === 'address').error}} </span>

              </div>
              <div class="group-427319047">
                <div class="text-29 inter-semi-bold-eerie-black-17px">
                  <span class="inter-semi-bold-eerie-black-17px"
                    >Phone</span
                  >
                </div>
                <input type="text" class="text-form-control" placeholder="Phone" id="phone_number" v-model="verification.phone_number" @input="acceptNumber">
                <span class="field-error" v-if="this.errors.find(o => o.name === 'phone_number')"> {{this.errors.find(o => o.name === 'phone_number').error}} </span>
              </div>
            </div>
            <div class="text-32 inter-semi-bold-eerie-black-17px mt-5">
                <span class="inter-semi-bold-eerie-black-17px"
                  >Goverment Issued ID</span
                >
              </div>
            <div class="group-427319046">
              
              <div class="group-427319047">
                <div class="text-29 inter-semi-bold-eerie-black-17px">
                  <span class="inter-semi-bold-eerie-black-17px"
                    >ID Front</span
                  >
                </div>
                <FileKhal app="accounts" rel="verified"
                          ref="government_id_image"
                          field="government_id_image"
                          :idfield="user.username"
                          />

                <span class="field-error" v-if="this.errors.find(o => o.name === 'government_id_image')"> {{this.errors.find(o => o.name === 'government_id_image').error}} </span>
              </div>
              <div class="group-427319047">
                <div class="text-29 inter-semi-bold-eerie-black-17px">
                  <span class="inter-semi-bold-eerie-black-17px"
                    >ID Back</span
                  >
                </div>
                <FileKhal app="accounts" rel="verified"
                          ref="government_id_back_image"
                          field="government_id_back_image"
                          :idfield="user.username"
                          />
                
              <span class="field-error" v-if="this.errors.find(o => o.name === 'government_id_back_image')"> {{this.errors.find(o => o.name === 'government_id_back_image').error}} </span>
              </div>
            </div>

            <div class="text-32 inter-semi-bold-eerie-black-17px mt-4">
              <span class="inter-semi-bold-eerie-black-17px"
                >Profile Image</span
              >
            </div>
            <div class="group-427319046">
              <div class="group-427319047">
                <div class="text-29">
                  <span class=""
                    >Please make sure, that you have your own photo in the profile image. It will be used to verify your photo with your identity.</span
                  >
                </div>
                
              </div>
              
            </div>
            <div class="profile-img">
              <FileKhal app="accounts" rel="profile"
                        ref="profile_image"
                        field="profile_image"
                        :idfield="user.username"
                        />
                <span class="field-error" v-if="this.errors.find(o => o.name === 'profile_image')"> {{this.errors.find(o => o.name === 'profile_image').error}} </span>
            </div>
            <p class="text-28 inter-normal-eerie-black-17px">
              <span class="inter-semi-bold-eerie-black-17px"
                >Get Your Profile Verified on Khal with Benefits. We charge {{ getPrice().discount_price }} 
                to get you verified. You can get this money back if you make $50
                in 30 days or less.</span
              >
            </p>
            <p class="text-28 inter-normal-eerie-black-17px ">
              <span class="inter-semi-bold-eerie-black-17px text-red"
                >NOTE: IF YOU ARE NOT IN THE US, PLEASE MAKE SURE THAT YOUR DEBIT/CREDIT CARD IS ACTIVATED FOR INTERNATIONAL PAYMENTS.</span>
            </p>
            
            
            <div class="submit-btn mt-2" >
              <p>Have a referral code? Get flat 20% off on verification fee.</p>
              <p v-if="referalChecked" class="field-error">Applicable Amount: <del>{{getPrice()['og_price']}}</del> {{getPrice()['discount_price']}}</p>
              <b-input-group prepend="Referral Code" class="mt-1 referal_input">
                <b-form-input class="referal-input" v-model="referal_code"></b-form-input>
                <b-input-group-append @click="checkReferral">
                  <!-- {{referalChecked?'success':'outline-success'}} -->
                  <b-button v-if="referalChecked" variant="success"><b-icon icon="check-all"></b-icon></b-button>
                  <b-button v-else variant="outline-success"><b-icon icon="check2"></b-icon></b-button>
                </b-input-group-append>
              </b-input-group>
              <span class="field-error" v-if="this.referral_error !==''"> {{this.referral_error}} </span>

              <div class="overlap-group6 mt-2" @click="checkForm">
                <div class="text valign-text-middle inter-semi-bold-white-17px center-text">
                  <span
                    ><span class="inter-semi-bold-white-17px ">{{isIndia?'Submit & Pay':'Confirm and Pay'}} ({{ getPrice()['discount_price'] }})</span>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div v-if="user && user.gov_id_proof_status === 'In Progress'" class="overlap-group71">
        <h1 class="text-27 valign-text-middle inter-bold-black-25px">
          <span><span class="inter-bold-black-25px">Verification in Progress: Pending Payment</span> </span>
        </h1>
        <p class="text-28 inter-normal-eerie-black-17px">
          <span class="inter-normal-eerie-black-17px"
            >Your payment did not go through. You can retry your payment. Incase you have any queries, please contact us on info@khal.com</span
          >
        </p>
        <br>
        <p class="text-28 inter-normal-eerie-black-17px ">
          <span class="inter-semi-bold-eerie-black-17px text-red"
            >NOTE: IF YOU ARE NOT IN THE US, PLEASE MAKE SURE THAT YOUR DEBIT/CREDIT CARD IS ACTIVATED FOR INTERNATIONAL PAYMENTS.</span>
        </p>
        <p v-if="isIndia" class="text-28 inter-normal-eerie-black-17px ">
          <span class="inter-semi-bold-eerie-black-17px text-red"
            >If you are from India, you can make payment via UPI to the QR below and send us a screenshot/transaction ID to info@khal.com to get verified</span>
        </p>
        <img
              v-if="isIndia"
              class="upi-qr-img"
              :src="static_fn('img/upi-qr.jpeg')"
              alt="upi-qr-img"
            />
        
        <div class="retry-btn mt-4" v-if="verData.payment_status == 2 || verData.payment_status == 1" >
          <div class="overlap-group61" @click="retryPayment">
            <div class="text valign-text-middle inter-semi-bold-white-17px">
              <span
                ><span class="inter-semi-bold-white-17px" >Retry Payment</span>
              </span>
            </div>
          </div>
        </div>
      </div>


      <div v-if="user && user.gov_id_proof_status === 'Pending'" class="overlap-group71">
        <h1 class="text-27 valign-text-middle inter-bold-black-25px">
          <span><span class="inter-bold-black-25px">Verification Pending</span> </span>
        </h1>
        <p class="text-28 inter-normal-eerie-black-17px">
          <span class="inter-normal-eerie-black-17px"
            >Your verification is pending. We are reviewing your application, and get back with a decision within 72 hours of application. Incase you have any queries, please contact us on info@khal.com</span
          >
        </p>
        <br>
      </div>
     
      <div v-if="user && user.gov_id_proof_status === 'Declined'" class="overlap-group71">
        <h1 class="text-27 valign-text-middle inter-bold-black-25px">
          <span><span class="inter-bold-black-25px">More info required for verification</span> </span>
        </h1>
        <p class="text-28 inter-normal-eerie-black-17px">
          <span class="inter-bold-eerie-black-17px"
            >Admin Message: </span>
            <span class="inter-normal-eerie-black-17px"
            >{{this.verData.description}}. </span><br><br>
            <span class="inter-normal-eerie-black-17px">
            Incase you have any queries, please contact us on <a href="mailto:info@khal.com">info@khal.com</a></span>
        </p>
        
      </div>
      </div>
    <footer class="footer">
      <h4 class="text-center">&copy; Khal: All rights reserved </h4>
    </footer>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapGetters, mapMutations } from 'vuex';
import LoadingOverlay from "./LoadingOverlay.vue";
//import { FileKhal, FileState } from "./FileKhal.vue";
import FileKhal, { FileState } from "./FileKhal.vue";
import Back from './Back.vue';


export default {
  name: 'GetVerified',
  components: { 
    LoadingOverlay,
    FileKhal,
    Back
  },
  computed: {
    user () {
      return this.getUserInfo()
    },
  },
  props: {
    
  },
  data() {
    return {
      errors:[],
      verData:{},
      overlayLoading:false,
      isIndia:false,
      ver_price_usd:10,
      ver_price_inr:820,
      verification: {
        name: '',
        address: '',
        phone_number: '',
        government_id_image: '',
        government_id_back_image: '',
        profile_image:'',
        government_id_image_preview:'',
        government_id_back_image_preview:'',
        profile_image_preview:''
      },
      loading: false,
      referalChecked: false,
      referal_code:'',
      referral_error:''
    };
  },
  mounted() {
    
  },
  updated() {
  
  },
  created: function(){
    this.verification.profile_image_preview = this.user.profile_image;
    this.verification.profile_image = this.user.profile_image;
    this.checkCountry()
    if(this.user.gov_id_proof_status !== 'Approved'){
      this.fetchVerificationDetail();
    }
  },
  methods: {
    ...mapGetters(['getUserInfo']),
    ...mapMutations(["showLogin"]),

    getPrice() {
      const discountPercentage = 0.20;
      const priceInr = this.ver_price_inr;
      const priceUsd = this.ver_price_usd;
      
      const currencySymbol = this.isIndia ? '₹' : '$';
      const ogPrice = this.isIndia ? priceInr : priceUsd;
      const discountedPrice = this.referalChecked ? (this.isIndia ? priceInr * (1 - discountPercentage) : priceUsd * (1 - discountPercentage)) : this.isIndia ? priceInr:priceUsd;
      
      return {"og_price":currencySymbol+ ogPrice,"discount_price":currencySymbol + discountedPrice};
    },

    checkReferral(){
      try {
        this.api.post(`accounts/profile/referal-code`,{"referal_code":this.referal_code}).then((data) => {
        if(data.success){
          this.referalChecked = true;
          this.referral_error = '';
        }else{
          this.referalChecked = false;
          this.referral_error = 'Referral code not found';
        }
      })
      } catch (error) {
        console.log(error);
      }
    },

    acceptNumber() {
        var x = this.verification.phone_number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.verification.phone_number = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    checkForm: function (e) {
      this.overlayLoading = true
      e.preventDefault();
      this.errors = [];
      if (!this.verification.name || this.verification.name === '' || !this.verification.name.match(/^[a-z ,.'-]+$/i)) {
        this.errors.push({'name':'name','error':'Name is required.'});
      }
      if (!this.verification.address || this.verification.address === '' ) {
        this.errors.push({'name':'address','error':'Address is required.'});
      }
      if (!this.verification.phone_number.replace(/\D/g, '').match(/^\d{10}$/)) {
        this.errors.push({'name':'phone_number','error':'Phone Number is required.'});
      }

      ['government_id_image', 'government_id_back_image', 'profile_image'].forEach((field) => this.handleFiles(field));

      if(this.errors.length === 0) {
        this.handleSubmit()
      }else{
        this.$bvToast.toast(`Hey, we need some details here in order to get you verified. You cannot skip these the way you can't skip salt on you lasagna`, {
          title: `Please complete the form first`,
          variant: 'danger',
          autoHideDelay: 5000,
        })
        this.overlayLoading = false
      }
    },
    fetchVerificationDetail(){
      this.api.get(`api/verification`,).then((data) => {
        if(data.success){
          this.verData = data.result
        }
      })
    },
    checkCountry(){
      this.api.get(`https://api.country.is/`,).then((data) => {
        if(data.country == 'IN'){
          this.isIndia = true
        }
      })
    },
   

    retryPayment(){
      this.overlayLoading = true
      this.api.get(`api/verification/payment-confirmation`).then((data) => {
        if (data.success){
          window.location.href = data.result;
        }
      }).finally(() => {
        this.overlayLoading = false
      });
    },

    
    handleSubmit() {
      let formData = new FormData();
      formData.append("name", this.verification.name);
      // formData.append("first_name", this.verification.first_name);
      // formData.append("last_name", this.verification.last_name);
      formData.append("address", this.verification.address);
      formData.append("phone_number", this.verification.phone_number);
      formData.append("government_id_image", this.verification.government_id_image);
      formData.append("government_id_back_image", this.verification.government_id_back_image);
      formData.append("profile_image", this.verification.government_id_back_image);
      formData.append("referal_code", this.referalChecked?this.referal_code:'');
      
      this.api.formDataPost(`verification`, formData,true).then((data) => {
        console.log(data);
        if (data.success && data.result.stripe_checkout_url){
          if(this.isIndia){
            this.$router.push({name: 'show-qr'});
            // window.location.href =data.result.stripe_checkout_url;

          }else{
            window.location.href =data.result.stripe_checkout_url;
          }
        }else{
          this.$bvToast.toast(`We could not process the form.`, {
            title: `Please try again`,
            variant: 'danger',
            autoHideDelay: 5000,
          })
        }
      }).catch((error)=>{
        console.log(error);
        this.$bvToast.toast(`We could not process the form.`, {
          title: `Please try again`,
          variant: 'danger',
          autoHideDelay: 5000,
        })
      }).finally(() => {
        this.overlayLoading = false
      });
    },
    onUpdate () {
      this.api.get("/api/accounts/mystats/").then((data) => {
        this.context.commit("setUserInfo", data);
      });
    },
    handleFiles: function (type) {
      switch (this.$refs[type].readyState()) {
      case FileState.absent:
        this.errors.push({'name':type,
                          'error':'Field is required.'});
        break;
      case FileState.present:
        this.errors.push({'name':type, 
                          'error':'Wait for this to finish upload'});
        break;
      default:
        this.verification[type] = this.$refs[type].filename
      }
    }
  }
}
</script>
<style scoped>
.body {
}
.wrap-static p,
  .wrap-static h2 {
  text-align: center;
  }
  .iframe-wrapper {
  position: relative;
  padding-bottom: 55%;
  margin: 50px auto 0px auto;
  max-width: 730px;
  }

  .field-error{
    color: tomato !important;
  }

  .upi-qr-img{
    width: 250px;
    height: 250px;
  }

  .field-message{
    color:#333
  }
  .iframe-wrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}
.re-payment-txt{
  align-self: start;
}
.wrap-static h2 {
  max-width: 730px;
  margin: 10px auto 0 auto;
  font-size: 25px;
  font-size: 7vmin;
  }
  
  .wrap-static p {
  padding-top: 50px;
  margin-bottom: 0px;
  }
  
  .wrap-static#wrap::after {
  opacity: .3;
  }

  .wrap-static #main {
  padding-top: 25px;
  padding-top: 12vmin;
  }
.sign-text{
  cursor: pointer;
  color: blue;
}
  .x02-get-verified {
  align-items: flex-start;
  background-color: var(--zumthor-2);
  border: 1px none;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width:100%;
}


.x02-get-verified .overlap-group-8 {
  border-radius: 115.62px;
  height: 37px;
  position: relative;
  width: 37px;
}


.center-text{
  margin: auto;
}
.x02-get-verified .ic-carat-left {
  
  background-image: url('../assets/ic---carat---down-13@2x.png');
  background-size: 100% 100%;
  height: 28px;
  width: 28px;
}

.x02-get-verified .vector-3 {
  height: 12px;
  left: 14px;
  position: absolute;
  top: 12px;
  width: 7px;
}

.x02-get-verified .place {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2.0px;
  min-height: 21px;
  min-width: 40px;
}
.text-red{
  color: red !important 
}
.x02-get-verified .overlap-group71 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-right: 4.0px;
  margin-top: 25px;
  padding: 44px 36px;
  width: 100%;
}


.x02-get-verified .text-27 {
  align-self: flex-start;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.x02-get-verified .text-28 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 15px;
  white-space: inherit;
  width: 100%;
}

.x02-get-verified .group-427319040 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-right: 6.0px;
  margin-top: 40px;
  min-height: 967px;
  width: 100%;
}

.x02-get-verified .group-427319046 {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(40%, 1fr));
  grid-gap: 10%;
}

@media only screen and (max-width: 900PX){
    .x02-get-verified .group-427319046 {
      grid-template-columns:auto;
      grid-gap: 5%;
      justify-content: normal;
    } 
    .file-form-label{
      width: 100% !important;
    }
}


.x02-get-verified .flex-col-1 {
  align-self: flex-start;
  margin-top: -1px;
  min-height: 143px;
  width: 260px;
}

.x02-get-verified .text-2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 20px;
  margin-top: 10px;
}

.x02-get-verified .group-427319033 {
  align-items: center;
  display: flex;
  gap: 11px;
  margin-left: 3px;
  margin-top: 31px;
  min-width: 226px;
}

.x02-get-verified .overlap-group {
  align-items: flex-start;
  background-color: #23bea2;
  border-radius: 15px;
  display: flex;
  height: 30px;
  min-width: 30px;
  padding: 9.5px 7.5px;
}

.x02-get-verified .icon-check_mark {
  height: 11px;
  width: 15px;
}

.edit-icon{
  position: absolute;
  top:0; right:0;
  margin: 10px;
  border-radius: 50%;
  padding: 10px;
  background-color: black;
}

.x02-get-verified .this-will-be-the-benefit {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 21px;
  min-width: 183px;
}

.x02-get-verified .group-427319036 {
  align-items: center;
  display: flex;
  gap: 11px;
  margin-left: 3px;
  margin-top: 28px;
  min-width: 226px;
}


.x02-get-verified .group-42731903 {
  align-items: center;
  display: flex;
  flex: 1 1 160px;
  gap: 11px;
  min-width: 226px;
  margin: 0px 10px 10px 0px;
}

.x02-get-verified .group-427319047 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 100%;
  gap: 11px;
  margin: 0px 10px 10px 0px;
}


.x02-get-verified .text-form-control{
  align-items: flex-start;
  background-color: var(--zumthor);
  border-radius: 50px;
  display: flex;
  height: 49px;
  padding: 13px 29px;
  width: -webkit-fill-available;
  border-width: 0px;
}



.x02-get-verified .file-form-label{
  background: #EDF4FC;
  border: 1.5px dashed #AECBF7;
  border-radius: 2px;
  align-items: flex-start;
  background-color: var(--zumthor);
  display: flex;
  height: 121px;
  background-size: cover;
  background-position: center center;
  width: -webkit-fill-available;
  position: relative;
}
.x02-get-verified .file-form-label-profile{
  background: #EDF4FC;
  border: 1.5px dashed #AECBF7;
  border-radius: 2px;
  align-items: flex-start;
  background-color: var(--zumthor);
  display: flex;
  height: 220px;
  background-size: cover;
  background-position: center center;
  width: 150px;
  position: relative;
}


.placeholder{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover{
  background: #E0E0E0
}
.x02-get-verified .file-form-control{
  opacity: 0;
   position: absolute;
   z-index: -1;
}


.x02-get-verified .flex-row-1 {
  align-items: center;
  display: flex;
  gap: 84px;
  height: 274px;
  margin-left: 2px;
  margin-top: -1px;
  min-width: 1024px;
}

.x02-get-verified .flex-col-2 {
  min-height: 274px;
  width: 470px;
}

.x02-get-verified .group-427319039 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 46px;
  min-height: 82px;
  width: 470px;
}

.x02-get-verified .text-29 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .overlap-group-1 {
  align-items: flex-start;
  background-color: var(--zumthor);
  border-radius: 50px;
  display: flex;
  height: 49px;
  min-width: 466px;
  padding: 13px 29px;
}

.x02-get-verified .group-427319041 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 40px;
  min-height: 82px;
  width: 470px;
}

.x02-get-verified .text-30 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .group-427319040-1 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 60.0px;
  min-height: 82px;
  width: 470px;
}

.x02-get-verified .text-31 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .group-427319042 {
  align-items: flex-start;
  border-radius: 7.3px;
  display: flex;
  gap: 84px;
  height: 213px;
  margin-left: 2px;
  min-width: 1022px;
}

.x02-get-verified .flex-col-3 {
  gap: 29px;
  margin-top: -1px;
  min-height: 214px;
  width: 470px;
}

.x02-get-verified .text-32 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 21px;
  margin-top: 15px;
}

.x02-get-verified .group-427319043 {
  align-items: flex-start;
  border-radius: 7.3px;
  display: flex;
  flex-direction: column;
  gap: 23px;
  min-height: 164px;
  width: 470px;
}

.x02-get-verified .overlap-group-2 {
  align-items: flex-end;
  background-color: var(--zumthor);
  border-radius: 7.3px;
  display: flex;
  height: 121px;
  min-width: 466px;
  padding: 48px 86px;
}

.x02-get-verified .first-name {
  letter-spacing: 0;
  line-height: normal;
  min-height: 21px;
  min-width: 289px;
}

.x02-get-verified .group-427319044 {
  align-items: flex-start;
  align-self: flex-end;
  border-radius: 7.3px;
  display: flex;
  flex-direction: column;
  gap: 23px;
  min-height: 164px;
  width: 470px;
}


.x02-get-verified .group-427319043-1 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  min-height: 82px;
  width: 470px;
}

.x02-get-verified .text-33 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .group-427319044-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13px;
  min-height: 82px;
  width: 470px;
}

.x02-get-verified .text-34 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .overlap-group3-1 {
  align-items: flex-start;
  background-color: var(--zumthor);
  border-radius: 50px;
  display: flex;
  gap: 13px;
  height: 49px;
  min-width: 466px;
  padding: 12px 20px;
}

.x02-get-verified .icoutline-credit-card {
  height: 24px;
  width: 24px;
}

.x02-get-verified .text-35 {
  align-self: center;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 1.0px;
  min-width: 185px;
  white-space: nowrap;
}

.x02-get-verified .flex-row-2 {
  align-items: flex-end;
  display: flex;
  height: 82px;
  min-width: 876px;
}

.x02-get-verified .group-427319045 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  min-height: 82px;
  width: 199px;
}

.x02-get-verified .text-36 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .overlap-group-3 {
  align-items: flex-start;
  background-color: var(--zumthor);
  border-radius: 50px;
  display: flex;
  height: 49px;
  min-width: 197px;
  padding: 13px 29px;
}

.x02-get-verified .group-427319046-1 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-left: 66px;
  min-height: 82px;
  width: 201px;
}

.x02-get-verified .text-37 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .text-38 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 14px;
  margin-left: 91px;
  min-height: 24px;
  min-width: 319px;
}

.x02-get-verified .span-1 {
  color: var(--eerie-black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-weight: 400;
}

.x02-get-verified .submit-btn{
  display: flex;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
}

.submit-btn p{
  color: blue;
}

.referal_input{
  max-width: 300px;
}

.x02-get-verified .retry-btn{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.x02-get-verified .overlap-group6 {
  align-items: center;
  margin-left: auto;
  background-color: var(--eerie-black);
  border-radius: 50px;
  display: flex;
  padding: 15px 30px;
  cursor: pointer;
  width: auto;
  overflow: hidden !important;
}

.x02-get-verified .overlap-group61 {
  align-items: center;
  background-color: var(--eerie-black);
  border-radius: 50px;
  display: flex;
  height: 49px;
  margin-right: 14px;
  padding: 13px 53px;
  cursor: pointer;
  width: auto;
}

.x02-get-verified .first-name-3 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .flex-col-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.profile-img {
    max-width: 300px;
}
.yt-div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.referal-input{
  height: auto !important;
}
</style>
