<template>
<SideMenuPage v-if="group" :choices="choices" :tab="tab"
              :cat="cat" :ctx="ctx" class="group-body"
              :key="group.join_state">
  <template v-slot:header>
    <header class="profile-header">
      <div class="profile-flex-col">
        <div class="profile-text-4 valign-profile-text-middle inter-bold-eerie-black-40px">
          <span><span class="inter-bold-eerie-black-40px">{{group.name}}</span> </span>
        </div>
      </div>
      <div @click.prevent="updateJoin" class="profile-overlap-group-1 comment-action">
        <div class="profile-text valign-profile-text-middle inter-normal-white-17px-2">
          <span><span class="inter-normal-white-17px-2">{{state_text}}</span> </span>
        </div>
      </div>
    </header>
  </template>
</SideMenuPage>
</template>
<script>

/* eslint-disable no-console */
import { mapActions } from "vuex";
import UserList from './UserList.vue';
import PostListContainer from './PostListContainer';
import PostList from './PostList';
import SideMenuPage from './SideMenuPage';

export default {
  name: 'Group',
  mixins: [PostListContainer],
  props: {
    slug: {type: String },
    tab: {
      type: String,
      default: function() {
        return 'posts';
      },
    },
    ctx: {
      type: String,
      default: function() {
        return 'in-group'
      },
    },
    cat: {
      type: String,
      default: function() {
        return 'any'
      }
    },
  },
  components: {
    //UserList,
    //PostList,
    SideMenuPage
  },
  computed : {
    category: function() {
      return this.categories.find(obj => {return obj.name == this.cat})
    },
    state_text: function() {
      return this.group.join_state ? "Leave" : "Join";
    },
    choices: function() {
      return (!this.group) ? [] : [
        {
          title: `Members (${this.group.member_count})`,
          routeName: 'group-members',
          tab: "members",
          comp: UserList,
          img: 'img/user--multiple-1-13@2x.png',
          props: {
            category: this.slug,
            comp: 'UserLine',
            numrows: 10,
            rowlen: 4,
            mrowlen: 4,
            framekls: "profile-x1",
            arrowMore: false,
            isMobile: this.isMobile,
            viewMore: false,
            urlp: {
              'app': 'groups',
              'view': 'list'
            }
          }
        },
        {
          title: "Recipes",
          summary: this.group.summary,
          which: "in-group",
          routeName: "group-posts",
          tab: "posts",
          img: 'img/book-svgrepo-com--3--1-2@2x.png',
          comp: PostList,
          props: !this.category ? {} : {
            category: this.category.name,
            comp: this.category.comp,
            numrows: 3,
            rowlen: this.category.rowlen,
            mrowlen: this.category.mrowlen,
            framekls: this.category.framekls,
            arrowMore: false,
            viewMore: false,
            isMobile: this.isMobile,
            rparams: {
              'group_pk': this.group.pk
            }
          }
        }
      ];
    },
  },
  data(){
    return {
      group: null,
      reload: false,
    }
  },
  updated() {
  },
  created() {
    this.api.get(`/api/groups/info/${this.slug}`).then((data) => {
      this.group = data;
      this.group.state_text = this.group.join_state ? "Leave" : "Join";
    });
  },
  methods:{
    ...mapActions(["postOrLogin"]),
    updateJoin() {
      console.log ("Update join called");
      this.postOrLogin(`groups/join/toggle/${this.group.pk}`).then((data) => {
        this.group.join_state = data.state;
        this.group.member_count = data.count;
        this.group.state_text = this.group.join_state ? "Leave" : "Join";
      });
    },
  },
}
</script>

<style>
a {
    cursor: pointer;
}

.hide {
  display: none !important; 
}

.comment-action {
  position: relative;
}

.image {
  height: 26px;
  object-fit: cover;
  position: absolute;
  width: 26px;
}
.image-3 {
  right: 0px;
  bottom: 0px;
}
</style>
