<template>
<div :key="expanded" ref="metop">
  <div v-if="expanded && possible">
    <div v-html="inhtml">
    </div>
    <a v-on:click="showLess">View Less</a>
  </div>
  <div v-else>
    <div class="stripped"> {{ trunc }}
      <span v-if="possible"> ... 
        <a v-if="nomore != true" v-on:click="showMore"> View More</a>
      </span>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'KTruncate',
  components: {
  },
  props: [
    'inhtml',
    'stripped',
    'length',
    'nomore'
  ],
  data() {
    return { 'expanded': false,
             'possible': true,
             'expandoff': false,
             'trunc': '',
           };
  },
  updated() {
    this.$nextTick(function() {
      if (this.expandoff) {
        //log.info ("Scroll into view expand");
        this.$el.scrollIntoView({'block': 'end'});
        this.expandoff = false;
      }
    });
  },
  mounted() {
    if (this.inhtml)
    {
      if (this.inhtml.length < this.length) {
        this.possible = false;
        this.trunc = this.stripped;
      }
    }
    else
      this.trunc = this.stripped.slice(0, this.length);
  },
  methods: {
    showMore() {
      this.expanded = true;
    },
    showLess() {
      this.expanded = false;
      this.expandoff = true;
    }
  }
}
</script>
<style>
</style>
