<template>
<div>
  <div class="post-flex-col" v-if="user">
    <div class="add_comment" ref="cbody" tabindex="0">
      <img class="line-205" :src="static_fn('img/line-205-14@2x.png')" alt="Line 205" />
      <div class="post-flex-row-2">
        <img class="photo" :src="user.avatar" v-bind:style="{'backgroundImage': 'url(' + static_fn('img/default-avatar.png') + ')'}"/>
        <form class="post-overlap-group5" @submit.prevent="sendComment">
          <div class="post-text-11 valign-post-text-middle inter-normal-eerie-black-17px-2">
            <input id="id_text" type="text" placeholder="Add Your Comment"
                   ref="text"
                   class="borderless-input inter-normal-eerie-black-17px-2"/>
          </div>
          <input type="image" class="carbonsend" :src="static_fn('img/carbon-send-1@2x.png')" alt="carbon:send" />
        </form>
      </div>
    </div>
  </div>
  <CommentList :post="post" :user="user" @ondeletecomment="onDeleteComment">
  </CommentList>
</div>
</template>

<script>

/* eslint-disable no-console*/
  
import CommentList from './CommentList';
import { mapGetters } from 'vuex';

export default {
  name: 'PostComments',
  components: {
    CommentList,
  },
  props: [
    'post',
    'focus'
  ],
  data() {
    return {
    };
  },
  computed: {
    user () {
      return this.getUserInfo();
    },
  },
  mounted() {
    this.$nextTick(function(){
      if (this.focus) {
        this.$refs.text.focus();
      }
    });
  },
  methods: {
    ...mapGetters(['getUserInfo']),
    getItemsFinished() {
      if (this.scrollTop) {
        //document.documentElement.scrollTop = document.body.scrollTop = this.scrollTop;
        this.$refs.cbody.scrollTop = this.scrollTop;
        this.scrollTop = null;
      }
    },
    onDeleteComment(count){
      // console.log(this.user,this.post);
      this.$emit("newcomment", count)
    },
    sendComment() {
      this.api.post(`posts/comments/add/${this.post.pk}`,
                    {'text': this.$refs.text.value}).then((data) => {
                      if (data.status == 'ok'){
                        this.$emit("newcomment", data.count)
                      }
                    })
    },
  }
}
  
</script>
