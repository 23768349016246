<template>
<div v-if="post" class="body">
  <div class="post_details">
    <div class="post-flex-col">
      <component :is="detailComp"
                 :post="post" :category="category">
      </component>
      <!-- <PostDetail :post="post" :category="category">
      </PostDetail> -->
    </div>
  </div>
</div>
</template>
<script>
/* eslint-disable no-console */

import PostDetail from './PostDetail';
import PostDetailMobile from './PostDetailMobile';

export default {
  name: 'Recipe',
  components: {
    PostDetail
  },
  data() {
    return {
      detailComp: (this.isMobile) ? PostDetailMobile : PostDetail,

      post: null,
    }
  },
  watch: {
    isMobile: function () {
      this.detailComp = (this.isMobile) ? PostDetailMobile : PostDetail;
    },
  },
  computed : {
    category: function() {
      if (this.post.video_file) {
        return 'quickie';
      }
      else if (this.post.video_id) {
        return 'video';
      }
      else {
        return 'photo';
      }
    }
  },
  props: {
    'username':String,
    'slug':String,
    "isMobile": Boolean,
  },
  created() {
    this.api.get(`/api/posts/item/${this.username}/${this.slug}/`).then((data) => {
      this.post = data;
    });
  }
}

</script>
