<template>
<div>
  <img v-if="!show" :src="mainImg"
       class="youtube-banner" style="width: 100%;"
       @click="()=>show=true" />
  <div class="youtube-container">
    <img v-if="!show" class="g7433" :src="static_fn('img/g7433@2x.png')" alt="g7433" @click="()=>show=true" />
    <youtube :video-id="videoId"
      ref="youtube" 
      :width="width" :height="height"
      :player-vars="playerVars" :fitParent="true"
      @paused="()=>show=false"
      >
    </youtube>
  </div>
</div>
</template>
<script>
/* eslint-disable no-console */

export default {
  name: 'YoutubeDetail',
  components: {
  },
  props: [
    'post',
    'height',
    'width'
  ],
  data() {
    return {
      videoId: this.post.video_id,
      show: false,
      playing: false,
      ready: false,
      playerVars: {
        'autoplay': 0,
        'rel': 0,
        'enablejsapi': 1,
        'controls': 1,
        'fs': 1,
        'modestbranding': 1,
        'origin': window.location.origin
      },
      rates: [],
      timeout: null
    }
  },
  updated() {
    this.$nextTick(() => {
      if (this.show) {
        this.$refs.youtube.player.playVideo();
      }
    });
  },
  mounted() {
  },
  computed: {
    mainImg: function() {
      return this.post.preps[0].thumbnail;
    },
  },
  created() {
  },
  methods: {
    play() {
      this.show = !this.show;
    },
  }
}
</script>
<style>
  .youtube-container {
  width: 100%;
  height: 100%;
  }

  .youtube-banner {
      width: 100%;
      position: absolute;
  }
</style>
