<template>
  <div class="body">
    <LoadingOverlay v-if="overlayLoading"/>

    <!-- <div class="way-container" v-if="previousPageURL !== '' || loading == 'E'">
        <Loading :loading="true" :loaderror="loading =='E'" ref="waytop" class="way-div way-top" v-waypoint="{ active: true, callback: onWaypointUp }" id="way-topper"></Loading>
    </div> -->
    <div class="login modal show">
      <div class="modal-dialog global" role="document" ref="reference">
        <div class="modal-content global">
          <div class="modal-header d-flex align-center">
            <h5 class="modal-title">{{user.name }}
              <img v-if="user.gov_id_proof_status == 'Approved'" class="image-checkmark" src="../assets/mdi_check-decagram.png" />
            </h5>
            <div class="overlap-group1-5" @click="handleClose">
              <div class="ellipse-54"></div>
              <img class="noun-cross-1707162-1" :src="static_fn('img/noun-cross-1707162-1-1@2x.png')" alt="noun-cross-1707162 1" />
            </div>
          </div>
          <div class="tip_content">
            <div class="comment-action">
              <img class="profile-image" :src="user.profile_image" alt="avatar" style="background-image: url(/static/img/default-avatar.png)" onload="style.backgroundImage=''"/>
             <img class="image-3 image" :src="badge" />
            </div>
            
            <div class="send-tip" v-if="tipData.tipStep == 1 ">

              <p class="inter-semi-bold-eerie-black-17px text-center" v-if="tipType == 'like'">We are glad you liked the recipe.</p>
              <p class="inter-semi-bold-eerie-black-17px text-center" v-if="tipType == 'make'">We are glad you want to make recipe.</p>
              <p class="inter-semi-bold-eerie-black-17px text-center" v-if="tipType == 'profile'">We are glad you liked the user's profile.</p>
              <p class="inter-semi-bold-eerie-black-17px text-center" v-if="tipType == 'tip'">We are glad you want to tip user.</p>
              <p class="inter-normal-black-15px text-center">Show your apreciation by tipping {{ user.name }}. Tips helps creators to keep creating awesome content.</p>
              
              <div class="pre-amount-button">
                <div :class="this.tipData.tipAmount === 3 ? 'button-container selected':'button-container'" @click="setAmount(3)">
                  <span>$3</span>
                </div>
                <div :class="this.tipData.tipAmount === 5 ? 'button-container selected':'button-container'" @click="setAmount(5)">
                  <span>$5</span>
                </div>
                <div :class="this.tipData.tipAmount === 10 ? 'button-container selected':'button-container'" @click="setAmount(10)">
                  <span>$10</span>
                </div>
                <div :class="this.tipData.tipAmount === 20 ? 'button-container selected':'button-container'" @click="setAmount(20)">
                  <span>$20</span>
                </div>
                <div :class="tipData.tipAmount === 30 ? 'button-container selected':'button-container'" @click="setAmount(30)">
                  <span>$30</span>
                </div>
                <div :class="tipData.tipAmount === 50 ? 'button-container selected':'button-container'" @click="setAmount(50)">
                  <span>$50</span>
                </div>
              </div>
              <div class="custom-input">
                <h1 class="or-text">OR</h1>
                <div class="group-427319047">
                  <input type="number" min="3" max="99999" class="text-form-control" placeholder="Enter Custom Amount" id="tip_amount" v-model="tipData.tipAmount">
                  <span class="field-error" v-if="tipData.amountError !=''"> {{tipData.amountError}} </span>

                </div>

                <div class="next-btn">
                  <div class="overlap-group6" @click="checkForm">
                    <div class="valign-text-middle inter-semi-bold-white-17px center-text">
                      <span class="payText" >Confirm</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="send-tip" v-else>
              <p class="tip-summary">Tip Order Summary</p>
              
              <div class="group-427319047 group-427319048">
                <div class="amountRow">
                  <h1>Tip Amount</h1>
                  <h2>${{tipData.tipAmount.toFixed(1)}}</h2>
                </div>
                <div class="amountRow">
                  <h1>Tip Amount Processing Fee (10%)</h1>
                  <h2>${{(tipData.tipAmount * 0.10).toFixed(1)}}</h2>
                </div>
                <hr class="dotted">

                <div class="amountRow">
                  <h2>Total Amount</h2>
                  <h2>${{(tipData.tipAmount + tipData.tipAmount * 0.10).toFixed(1)}}</h2>
                </div>
              </div>
              <p class=" pay-text">
                  NOTE: IF YOU ARE NOT IN THE US, PLEASE MAKE SURE THAT YOUR DEBIT/CREDIT CARD IS ACTIVATED FOR INTERNATIONAL PAYMENTS.
              </p>
              <div class="next-btn">
                <div class="overlap-group6" @click="checkoutTip">
                  <div class="valign-text-middle inter-semi-bold-white-17px center-text">
                    <span class="payText" >Pay</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  </template>
  <script>
  /* eslint-disable no-console */
  import BaseModal from './BaseModal';
  // import Loading from './Loading';
  import LoadingOverlay from "./LoadingOverlay.vue";

  

  export default {
    mixins: [BaseModal],
    // extends: BaseModal,
    name: 'TipsModal',
    props: [
      'user',
      'tipType'
    ],
    components: {
      // Loading,
      LoadingOverlay
    },
    computed: {
      
      badge: function () {
        return this.static_fn(this.user.full_badge_image || 'img/image-2-2@2x.png');
      },
    },
    data() {
      return {
        overlayLoading:false,
        tipData:{
          tipAmount: 3,
          amountError: '',
          tipStep: 1
        },
      };
    },
    watch: {
    },
    created() {
      
    },

    
    methods: {

      checkForm(){
        if(this.tipData.tipAmount >= 3 && this.tipData.tipAmount <= 50){
          console.log(this.user);
          this.tipData.tipStep = 2
        }else{
          this.tipData.amountError = 'Please enter tip amount between 3 and 50'
          this.$bvToast.toast(`Please enter tip amount between 3 and 50`, {
            title: `Something went wrong`,
            variant: 'danger',
            autoHideDelay: 5000,
          })
        }
      },

      checkoutTip(){
        this.overlayLoading = true
        let formData = new FormData();
        formData.append("receiver_user_id", this.user.pk);
        formData.append("tip_amount", this.tipData.tipAmount);
        this.api.formDataPost(`monetization/user-tip`, formData,true).then((data) => {
          if (data.success){
            window.location.href =data.result.stripe_checkout_url;
          }
        }).catch(()=>{
          this.$bvToast.toast(`Hey, we could not tip your favourite creator. Please try again later.`, {
            title: `Something went wrong`,
            variant: 'danger',
            autoHideDelay: 5000,
          })
        }).finally(() => {
          this.overlayLoading = false
        });
      },

      handleClose() {
        this.$emit("closed");
      },

      setAmount(amount){
        this.tipData.tipAmount = amount
      }
     
    },
    mounted(){
      console.log('this.post',this.post);
    }
  }
  </script>
    
<style scoped>
.modal-content {
  padding: 10px;
  overflow:scroll;
}

@media (min-width: 1200px){
  .modal-dialog {
    width: 900px;
    margin: 30px auto;
  }
}
.field-error{
    color: tomato;
  }
.user_photo{
  position: relative;
  /* align-self: center; */
}

.user_photo .photo{
  position: relative;
}

.group-427319047 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 10px;
}
.group-427319048{
  width: 100%;
  padding: 10px;
}

.amountRow{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.center-text{
  margin: auto;
}
.amountRow h1{
  font-size: 20px;
  color:#000000;
  font-weight: 500;
}

.amountRow h2{
  font-size: 22px;
  color:#000000;
  font-weight: 700;
}

hr.dotted {
  width: 100%;
  border-top: 3px dotted #bbb;
}

.text-form-control{
  align-items: flex-start;
  background-color: var(--zumthor);
  border-radius: 50px;
  display: flex;
  height: 49px;
  padding: 13px 29px;
  width: -webkit-fill-available;
  border-width: 0px;
}

.payText{
  color: #FFFFFF;
}
.tip_content{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  justify-content: space-around;
  align-items: center;
  margin-top: 5px;
}

.or-text{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
/* identical to box height, or 148% */

display: flex;
align-items: center;

color: #000000;
}

.tip_content .username{
  margin-top: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 39px;
  /* identical to box height, or 155% */


  /* Black/100% */

  color: #1C1C1C;
}

.next-btn{
  display: flex;
  width: 100%;
  align-items: flex-end;
  margin: 20px 10px;
}

.overlap-group6 {
  align-items: center;
  margin-left: auto;
  background-color: var(--eerie-black);
  border-radius: 50px;
  display: flex;
  margin-right: 14px;
  padding: 15px 30px;
  cursor: pointer;
  width: auto;
  overflow: hidden !important;
}

.pay-text{
  width: 100%;
  white-space: normal;
  color: red !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.pre-amount-button{
  display: flex;
  justify-items: center;
  flex-flow: row wrap;
  justify-content: center;
}

.custom-input{
  display: flex;
  justify-items: center;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.custom-input .or-text{
  margin:0px;
}

.custom-input .next-btn{
  width: auto;
  margin: 0px;
}
.custom-input .overlap-group6{
  margin-right: 0px;
}

.button-container{
  background: #EDF4FC;
  /* stroke_dark */
  width: 50px;
  height: 50px;
  border: 1px solid #CCDBE2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  justify-content: center;
}

.selected{
  background: #1C1C1C;
  border: 1px solid #000000;
}

.button-container span{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  /* identical to box height, or 118% */
  display: flex;
  /* heading */
  color: #1C1C1C;
}

.selected span{
  color: #FFFFFF;
}

.login .modal-title{
  text-align: start;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 20px;
  /* identical to box height, or 80% */

  display: flex;
  align-items: center;

  color: #000000;
}

.send-tip{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.send-tip .tip-summary{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 39px;
  /* identical to box height, or 194% */


  /* Black/100% */
  color: #1C1C1C;
}
.comment-action{
  height: 150px !important;
  width: 150px !important;
}
.comment-action .profile-image{
  height: 150px !important;
  width: 150px !important;
  border-radius: 50%;
}
.comment-action .image-3{
  right: 12px !important;
  bottom: 12px !important;
  left: auto;
  top: auto;
}
</style>
