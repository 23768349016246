<template>
<div v-if="items" class="body">
  <Back/>
  <div class="way-container" v-if="previousPageURL !== '' || loading == 'E'">
      <Loading :loading="true" :loaderror="loading =='E'" ref="waytop" class="way-div way-top" v-waypoint="{ active: true, callback: onWaypointUp }" id="way-topper"></Loading>
  </div>
  <div v-for="post in items" class="post_details"  :key="post"
       ref="detail" 
       :id="`PD-${post.pk}`">
    <div class="post-flex-col">
      <component :is="detailComp"
                 :post="post" :category="category">
      </component>
    </div>
  </div>
  <div class="way-container" v-if="nextPageURL !== '' || loading == 'E'">
      <Loading :loading="true" :loaderror="loading == 'E'" ref="waybottom" class="way-div way-bottom" v-waypoint="{ active: true, callback: onWaypointDown }"></Loading>
  </div>
</div>
</template>

<script>
/* eslint-disable no-console */
import PostDetail from './PostDetail';
import PostDetailMobile from './PostDetailMobile';
import ItemList from './ItemList';
import BaseModal from './BaseModal';
import Loading from './Loading';
import { mapMutations } from "vuex";
import Back from './Back.vue';

export default {
  mixins: [ItemList, BaseModal],
  name: 'PostModal',
  components: {
    PostDetail,
    Loading,
    Back
  },
  props: {
    'category': String,
    'pk': Number,
    'idx': Number,
    'urlp': {
      type: Object,
      default: function() {
        return {'app': 'posts',
                'view': 'detail-list'};
      },
    },
    "isMobile": Boolean,
  },
  data() {
    return {
      detailComp: (this.isMobile) ? PostDetailMobile : PostDetail,
      scrollDest: null,
    };
  },
  watch: {
    isMobile: function () {
      this.detailComp = (this.isMobile) ? PostDetailMobile : PostDetail;
    },
  },
  created() {
    
  },
  methods: {
    ...mapMutations(["setViewContext"]),
    addURLParams() {
      this.params.pk = this.pk;
      this.params.per_page = 3;
    },
    postGetItems() { 
      this.scrollDest = this.idx % this.params.per_page;
      console.log ("postGetItems " + this.idx + " i.e. " + this.scrollDest + " previous " + this.previousPageURL + " Next: " + this.nextPageURL);
      // for boundaries, get previous or next so we don't land on a waypoint
      if (!this.scrollDest && this.previousPageURL) {
        this.getPreviousPage();
      }
      else if (this.scrollDest == this.params.per_page - 1 &&
               this.nextPageURL) {
        this.getNextPage();
      }
      else {
        this.loading = null;
        this.scrollToElem();
      }
    },
    scrollToElem() {
      
      if (this.scrollDest != null) {
        var that = this;
        this.$nextTick(function() {
          const arr = that.$refs.detail.map( e => e.getAttribute("id"));
          console.log ("Scroll to elem " + that.scrollDest + " of " + that.$refs.detail.length +
                       " ID " + that.$refs.detail[that.scrollDest].getAttribute("id") + " ARR " + arr);
          that.$refs.detail[that.scrollDest].scrollIntoView();
          that.scrollDest = null;
        });
      }
    },
    postUp() {
      this.scrollToElem();
    },
    postDown() {
      this.scrollToElem();
    },
  },
  mounted(){
    
  }
}
</script>

