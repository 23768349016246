<template>
  <div class="profile-body" @click="touchHandler">
    <Back/>
    <div class="profile-overlap-group6">
      <div class="profile-flex-row-1">
        <div class="profile-second_sidebar mobile-hide">
          <div class="profile-frame-427319003 frame">
            <div v-for="cat in items" :key="cat.name">
              <a :class="'profile-overlap-group1 ' + active_class(cat)" @click="chooseCat(cat)">
                <div class="profile-text-10 valign-profile-text-middle inter-semi-bold-eerie-black-17px">
                  <span><span class="inter-semi-bold-eerie-black-17px">{{cat.name}} ({{cat.group_count}})
                  </span></span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="profile-overlap-group11 mobile-show">
          <select class="profile-tabs" v-model="activeCat">
            <option v-for="cat in items" :key="cat.pk" :value="cat.pk"><span class="inter-normal-eerie-black-17px-2">{{cat.name}}</span> </option>
          </select>
        </div>
        <div class="tab-content profile-flex-col-2 profile-flex-col-4">
          <div class="profile-overlap-group7">
            <div class="profile-achievements-3">
              <div class="profile-x1" v-if="activeCat" :key="activeCat">
                <GroupList :category="activeCat.toString()" :comp="grpline"
                           :numrows="10" :rowlen="4" :mrowlen="4" 
                           framekls="profile-x1" :arrowMore="false"
                           :isMobile="isMobile" :viewMore="false"
                           :urlp="{'app': 'groups', 'view': 'list'}">
                </GroupList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

/* eslint-disable no-console */

import { mapMutations } from "vuex";
import ItemList from './ItemList';
import GroupList from './GroupList.vue';
import GroupLine from './GroupLine.vue';
import Back from './Back.vue';

export default {
  name: 'GroupPage',
  mixins: [ItemList],
  props: {
    activeCat: { type: Number },
    isMobile: { type: Boolean },
    touchHandler: { type: Function },
    showSideBar: { type: Function },
    hideSideBar: { type: Function },
    choice: {
      type: String,
      default: function() {
        return 'category'
      }
    },
  },
  components: {
    GroupList,
    Back,
  },
  computed : {
    grpline : () => GroupLine
  },
  data(){
    return {
    }
  },
  created() {
    this.setViewContext(this.choice);
  },
  methods:{
    ...mapMutations(["setViewContext"]),
    setupURL() { 
      this.url = `/api/groups/${this.choice}/list`;
    },
    postGetItems() {
      if (!this.activeCat) {
        this.activeCat = this.items[0].pk;
      }
      this.loading = null;
    },
    active_class(cat) { 
      return this.activeCat == cat.pk ? "active" : "";
    },
    chooseCat (cat) {
      this.activeCat = cat.pk;
    }
  },
  mounted(){
  }
}
</script>

<style>
a {
    cursor: pointer;
}

.hide {
  display: none !important; 
}

.comment-action {
  position: relative;
}

.image {
  height: 26px;
  object-fit: cover;
  position: absolute;
  width: 26px;
}
.image-3 {
  right: 0px;
  bottom: 0px;
}
</style>
