<template>
  <div>
    <div ref="me" :class="postListClass"> <!--:style="`top: -${scrollOff}px`"-->
      <div class="profile-x1">
        <div v-for="post in items" :key="post.pk">
          <div v-if="post.image != ''" class="profile-achievements profile-achievements-3" @click="selectPost(index, post)" style="cursor: pointer;">
            <div class="profile-overlap-group">
              <img
                class="profile-rectangle-34624102 profile-rectangle"
                :src="post.image"
                alt="image"
              />
            </div>
            <div class="profile-text-container">
              <p class="profile-text-2 inter-bold-eerie-black-20px">
                <span class="inter-normal-black-17px" v-html="post.message"></span>
              </p>
              <!-- <div class="profile-text-3 inter-normal-eerie-black-17px-2">
                <span class="inter-normal-eerie-black-17px-2">Read More</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div v-if="viewMore && count && (nextPageURL != undefined && nextPageURL != '')" class="profile-text-22 inter-normal-eerie-black-17px-2" @click="getNextPage">
        <span class="inter-normal-eerie-black-17px-2">View More</span>
      </div>
      <div class="way-container" v-else-if="nextPageURL !== '' || loading == 'E'">
        <Loading :loading="'L'" :key="loading" :loaderror="loading == 'E'" class="way-div" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" id="More">
        </Loading>
      </div>
    </div>
    <PostModal v-if="selectedPost > 0" ref="modal" :pk="selectedPost" 
              @closed="onHideDetail" @modalOut="onModalOut" :key="selectedPost"></PostModal>
  </div>
</template>

<script>
/* eslint-disable no-console */
import Loading from './Loading';
import ItemList from './ItemList';
import PostModal from './PostModal';
// import KTruncate from './KTruncate';
// import PreVideoFile from  './PreVideoFile';
// import PreImage from  './PreImage';

export default {
  extends: ItemList,
  name: 'StoryList',
  components: {
    Loading,
    PostModal,
    // KTruncate,
    // PreVideoFile,
    // PreImage,
  },
  props: {
    'username': String,
    'numrows': Number,
    'rowlen': Number,
    'mrowlen': Number,
    'comp': Object,
    'framekls': String,
    'arrowMore': Boolean,
    'ignoreCategory': Boolean,
    'viewMore': {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    'isMobile': Boolean,
    'touchHandler': Function,
    'rparams': {
      type: Object,
      default: function() {
        return {per_page: this.numrows*this.rowlen};
      }
    },
  },
  data() {
    return {
      rowlength: this.rowlen,
      postLisClass: "post-scroll",
      selectedPost:0,
      selectedVideo:0,
        postListClass: "post-fixed",
      loading: null, //false,
      intersectionOptions: {
        root: null,
        //rootMargin: '0px 0px 0px 0px',
        threshold: [0.25, 0.75] // [0.25, 0.75] if you want a 25% offset!
      },
    };
  },
  watch: {
    isMobile: function () {
      this.rowlength = this.isMobile ? this.mrowlen : this.rowlen;
    },
    count: {
      handler(newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        console.log(newValue, oldValue);
      },
      deep: true
    }
  },
  methods: {
    addURLParams() { 
      this.params.per_page = this.numrows*this.rowlen;
    },
    handleScroll: function(e) {
      let newScrollLeft = e.currentTarget.scrollLeft;
      let width = e.currentTarget.clientWidth;
      let sWidth = e.currentTarget.scrollWidth;
      if ((sWidth - newScrollLeft - width) <= 0) {
        this.getNextPage();
      }
    },
    setPage(data) {
      let len = Math.ceil(data.length / this.rowlength);
      for (let i = 0; i < len ; i++) {
        let temp = data.slice(i, i+this.rowlength);
        temp.map((e) => {
          this.rows.push(e);
        })
      }
    },
    selectPost(index, post) {
      let route_cat = 'story';
      this.$router.push({name: `${route_cat}-detail`, params: {pk: post.pk, username: this.username, idx: index}});
    },
    selectVideo(post) {
      this.selectedVideo = post.pk;
      this.modalOpen = true;
      this.postListClass = "post-fixed";
      let sides = document.querySelectorAll ("tofix");
      for (let i = 0; i < sides.length; i++) {
        sides[i].classList.add ("postbg-fixed");
      }
    },
  },
  updated() {
  },
}
</script>
