<template>
<a :class="'avatar-name menu-entry ' + active_class" @click="gotoUser">  
  <img class="user-multiple-1-group" :src="static_fn('img/user--multiple-1-13@2x.png')" />
  <div class="text-25 valign-text-middle inter-normal-eerie-black-17px">
    <span><span class="inter-normal-eerie-black-17px">{{item.name}}</span> </span>
  </div>
  <div class="overlap-group-6" v-if="item.newnum">
    <div class="text-26 valign-text-middle inter-normal-eerie-black-14px">
      <span><span class="inter-normal-eerie-black-14px">{{item.newnum}} New</span> </span>
    </div>
  </div>
</a>
</template>
<script>
/* eslint-disable no-console */
export default {
  name: 'sidebar-group',
  components: {
  },
  props: [
    'item'
  ],
  computed: {
    active_class() {
      return this.item.is_active ? "active": "";
    }
  },
  methods: {
    gotoUser() {
      this.$router.push({name: 'group-members',
                         params: {'slug': this.item.slug,
                                  'tab': 'members'}}).catch((err) => {
                           if (err.name !== 'NavigationDuplicated' &&
                               !err.message.includes('Avoided redundant navigation to current location')
                              ) {
                             console.log(err);
                           }
                           else {
                             console.log("Redundant: " + this.$router.path);
                           }
                         });
    },
  }
  
}
</script>
