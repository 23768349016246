<template>
  <div class="w-100" v-if="count > 0 || rparams.query == undefined">
    <div class="text-1 valign-text-middle inter-bold-black-25px">
      <span class="inter-bold-black-25px">{{label}}
      </span>
    </div>
    <p v-if="rparams.query != undefined" class="profile-text-21 valign-profile-text-middle inter-bold-eerie-black-20px">
      <span
        ><span class="inter-bold-eerie-black-20px">Your search for {{rparams.query}} returned {{ count }} results</span>
      </span>
    </p>
    <div id="items" :class="[framekls, isMobile ? 'list_mobile' : 'list_app', waySide ? 'horizontal' : 'vertical' ]" ref="items">
        <!-- 
             The preview depends on the type of post this is. 
          -->
        <component v-for="(post, index) in items" :key="post.pk" :is="comp" :post="post" 
                   @click.native="selectPost(index, post)" style="cursor: pointer;">
        </component>
        <div v-if="waySide && (nextPageURL !== '' || loading == 'E')" :class="'way-container way-side ' + framekls">
          <Loading :loading="'L'" :key="loading" :loaderror="loading == 'E'" class="way-div" v-waypoint="{ active: true, callback: onWaypointDown, options: intersectionOptions }" id="More">
          </Loading>
        </div>
    </div>
    <div v-if="!waySide && count && nextPageURL != undefined && nextPageURL != ''">
      <div v-if="viewMore" class="profile-text-22 inter-normal-eerie-black-17px-2" @click="getNextPage">
        <span class="inter-normal-eerie-black-17px-2">View More</span>
      </div>
      
      <div v-else-if="arrowMore" :class="{'arrow-container': true}" @click="getNextPage" ref="arrowContainer">
        <img class="vector-x2" :src="static_fn('img/vector-10@2x.png')"/>
        <img class="line-204-3" :src="static_fn('img/line-204-1@2x.png')" />
      </div>
      
      <div v-else class="way-container">
        <Loading :loading="'L'" :key="loading" :loaderror="loading == 'E'" class="way-div" v-waypoint="{ active: true, callback: onWaypointDown, options: intersectionOptions }" id="More">
        </Loading>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import Loading from './Loading';
import PostModal from './PostModal';
import ItemList from './ItemList';
import VideoModal from './VideoModal';
import { mapGetters } from "vuex";

export default {
  extends: ItemList,
  name: 'PostList',
  components: {
    Loading,
    PostModal,
    VideoModal,
  },
  props: {
    'category': String,
    'label': String,
    'numrows': Number,
    'rowlen': Number,
    'mrowlen': Number,
    'comp': Object,
    'framekls': String,
    'arrowMore': Boolean,
    'waySide': Boolean,
    'showZero': {
      type: Boolean,
      default: function() {
        return true;
      }
    },
    'viewMore': {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    'isMobile': Boolean,
    'ignoreCategory': {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    'rparams': {
      type: Object,
      default: function() {
        return {per_page: this.numrows*this.rowlen};
      }
    },
    'urlp': {
      type: Object,
      default: function() {
        return {'app': 'posts',
                'view': 'list'};
      }
    },
  },
  data() {
    return {
      rowlength: this.rowlen,
      postLisClass: "post-scroll",
      selectedPost:0,
      selectedVideo:0,
      loading: null, //false,
      intersectionOptions: {
        root: null,
        //rootMargin: '0px 0px 0px 0px',
        threshold: [0.25, 0.75] // [0.25, 0.75] if you want a 25% offset!
      },
    };
  },
  computed: {
  },
  watch: {
    isMobile: function () {
      console.log("name change"); // print out when the name changes
      this.rowlength = this.isMobile ? this.mrowlen : this.rowlen;
      console.log(this.rowlength);
    },
  },
  methods: {
    ...mapGetters(["view_context"]),
    addURLParams() { 
      this.params.per_page = this.numrows*this.rowlen;
    },
    handleScroll: function(e) {
      let newScrollLeft = e.currentTarget.scrollLeft;
      let width = e.currentTarget.clientWidth;
      let sWidth = e.currentTarget.scrollWidth;
      if ((sWidth - newScrollLeft - width) <= 0) {
        alert ("Getting next page " + this.nextPageURL);
        this.getNextPage();
      }
    },
    setPage(data) {
      let len = Math.ceil(data.length / this.rowlength);
      for (let i = 0; i < len ; i++) {
        let temp = data.slice(i, i+this.rowlength);
        temp.map((e) => {
          this.rows.push(e);
        })
      }
    },
    selectPost(index, post) {
      let route_cat = (this.category == "quickie") ? 'quickie' : 'recipe';
      this.$router.push({name: `${route_cat}-detail`,
                         params: {pk: post.pk, 
                                  category: this.category,
                                  idx: index,
                                  ctx: this.view_context()},
                         query: this.rparams
                         //{username: post.user.username}
                        });
    },
    selectVideo(post) {
      console.log ("Select video is " + post.pk);
      this.selectedVideo = post.pk;
      this.modalOpen = true;
      this.postListClass = "post-fixed";
      let sides = document.querySelectorAll ("tofix");
      for (let i = 0; i < sides.length; i++) {
        sides[i].classList.add ("postbg-fixed");
      }
      console.log ("Select post done * " + this.selectedPost);
    },
    myEventHandler() {
      // your code for handling resize...
      if (this.$refs.items)
      {
        let w = this.$refs.items.clientWidth;
        let gap = parseInt($(this.$refs.items).css('gap')) || 48;
        if (isNaN(gap))
          return;
        let c = parseInt((w + gap) / (266 + gap));
        $(this.$refs.arrowContainer).width(((266 + gap) * c - gap));
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.myEventHandler();
  },
  updated() {
    this.myEventHandler();
  },
}
</script>
<style scoped>

.post-fixed {
    position: fixed;
    z-index: -1;
    opacity: 0; 
}

.w-100 {
    width: 100%;
}

.list_app {
  width: 100%;
  flex-flow: wrap;
}

.list_mobile {
  width: 100%;
  height: fit-content;
  overflow-x: scroll;
}

</style>
