<template>
  <a :class="'group-427318968-x1 menu-entry ' + ifactive" @click="gotoList">
    <img class="book-svgrepo-com-3-1"
         :src="static_fn(entry.img)" />
    <div class="text-14 valign-text-middle inter-normal-eerie-black-17px">
      <span><span class="inter-normal-eerie-black-17px">{{entry.title}} ({{entry.num}})</span> </span>
    </div>
  </a>
</template>
<script>
/* eslint-disable no-console */
import { mapMutations, mapGetters } from "vuex";

export default {
  name: 'SubMenuEntry',
  components: {
  },
  computed: {
    ...mapGetters(["view_context"]),
    ifactive() {
      return (this.view_context == this.which &&
              this.$route.params.cat == this.entry.name &&
              this.$route.name == this.routeName) ? "active": "";
    },
  },
  props: ['which', 'entry', 'routeName', 'tab'],
  methods: {
    ...mapMutations(["setViewContext"]),
    gotoList() { 
      if (this.entry.num) { 
        const params = {...this.$router.params,
                        ...(this.tab) && {'tab': this.tab},
                        'cat': this.entry.name,
                        'ctx': this.which
                        }
        this.setViewContext(this.which)
        this.$router.push({'name': this.routeName, 
                           'params': params}).catch((err) => {
                             if (err.name !== 'NavigationDuplicated' &&
                                 !err.message.includes('Avoided redundant navigation to current location')
                                ) {
                               console.log(err);
                             }
                             else {
                               console.log("Redundant: " + this.$route.path);
                             }
                           });
      }
    },
  }
}
</script>
