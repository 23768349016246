<template>
<Loading v-if="$store.state.initialized == false"
         :loading=1 :loaderror=0 />

<div v-else class="home screen" ref="home">
  <Topbar :toggleSideBar="toggleSideBar" :toggleSideMenu="toggleSideMenu"/>
  <Sidebar v-if="isSideBar" :hideSideBar="hideSideBar" :key="getUserInfo == null"/>
  <router-view v-if="!isLegacy" :key="$route.params" :showSideBar="showSideBar" :hideSideBar="hideSideBar" :isMobile="isMobile" :touchHandler="touchHandler"></router-view>
  <Login v-if="!loggedIn" />
  <SideMenu v-if="isSideMenu" :hideSideMenu="hideSideMenu"/>
</div>
</template>

<script>
/* eslint-disable no-console */

import { mapGetters } from "vuex";

import Topbar from './components/Topbar';
import Sidebar from './components/Sidebar';
import SideMenu from './components/SideMenu';
import Loading from './components/Loading';
import Login from './components/Login';

export default {
  name: 'app',
  components: {
    Sidebar,
    SideMenu,
    Topbar,
    Loading,
    Login,
},
  data () {
    return {
      isSideBar: true,
      isSideMenu: false,
      isMobile: false,
      sidebarReload: false,
    }
  },
  computed: {
    ...mapGetters(["loggedIn", "getUserInfo"]),    
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  created() {
    console.log ("App created " + this.$route.name + " Resolve " + this.$router.resolve("/").route.name);
    window.addEventListener("resize", this.myEventHandler);
    this.$store.dispatch("initialize", this.api);
  },
  updated() {
    if (this.isLegacy && this.$refs.home) {
      this.$nextTick(function() { 
        var old = document.querySelector('.oldstuff');
        console.log ("Old is ", old, " and ", this.$refs.home);
        try
        {
          if (old.parentElement.className != this.$refs.home.className)
          {
            if (this.$refs.home.getElementsByClassName('body').length > 0)
              this.$refs.home.removeChild(this.$refs.home.getElementsByClassName('body')[0]);
            this.$refs.home.appendChild(old);
          }
        }
        catch(e)
        {
          console.log(e);
        }
      });
    }
  },
  mounted() {
    this.isMobile = (window.innerWidth < 600);
    if (this.isMobile)
      this.hideSideBar();
  },
  methods: {
    toggleSideBar() {
      if (this.isSideMenu)
      {
        this.isSideMenu = false;
        return;
      }
      this.isSideBar = !this.isSideBar;
    },
    toggleSideMenu() {
      if (this.isSideBar)
      {
        this.isSideBar = false;
        return;
      }
      this.isSideMenu = !this.isSideMenu;
    },
    showSideBar() {
      this.isSideBar = true;
    },
    hideSideBar() {
      this.isSideBar = false;
    },
    showSideMenu() {
      this.isSideMenu = true;
      this.isSideBar = false;
    },
    hideSideMenu() {
      this.isSideMenu = false;
    },
    myEventHandler() {
      // your code for handling resize...
      this.isMobile = (window.innerWidth < 600);
      if (!this.isMobile)
      {
        this.showSideBar();
        this.hideSideMenu();
      }
    },
    touchHandler() {
      if (this.isMobile)
      {
        this.hideSideBar();
        this.hideSideMenu();
      }
    }
  }
}

</script>

