import { render, staticRenderFns } from "./PreYoutube.vue?vue&type=template&id=f6487900&scoped=true"
import script from "./PreYoutube.vue?vue&type=script&lang=js"
export * from "./PreYoutube.vue?vue&type=script&lang=js"
import style0 from "./PreYoutube.vue?vue&type=style&index=0&id=f6487900&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6487900",
  null
  
)

export default component.exports