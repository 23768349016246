<template>
  <div>
    <img v-if="!show" :src="mainImg"
         class="youtube-banner" style="width: 100%;"
         @click="()=>show=true"/>
    <div class="youtube-container">
      <img v-if="!show" class="g7433" :src="static_fn('vplayer.png')" alt="g7433" @click="()=>show=true" />
      <video ref="videoPlayer"
             @ended="ended" class="video-js vjs-default-skin vjs-big-play-centered"
             :width=width :height=height controls 
             v-bind:style="{ 'width': width, 'height': height }"
             :options="options">
        <source :src="src">
      </video>
    </div>
  </div>
</template>
<script>

import videojs from 'video.js';
const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });

export default {
  name: 'Video',
  props: [
      'post',
      'width',
      'height'
  ],
  data() {
    return {
      show: false,
      src: this.post.video_file,
      options: { 
        sources: [{
          //type: "video/mp4",
          'src': this.post.video_file,
          }],
        'poster': (this.post.post_image || this.post.image),
        controls: 1,
        autoplay: 0
      },
      'playImg': null,
      'player': null,
      timeout: 60,
    };
  },
  computed: {
    mainImg: function() {
        return this.post.preps[0].thumbnail;
    },
  },
  updated() {
      this.$nextTick(() => {
          this.startVideo();
      });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  mounted() {
      log.info ("Mounted video file");
      this.$nextTick(() => this.makePlayer());
      if (!this.playImg) {
          this.api.map_prom.then(() => {
              this.playImg = this.api.mkstatic("vplayer.png");
          });
      }
  },
  methods: {
    pauseVideo() {
        this.player.pause();
    },
    stopVideo() {
        this.player.pause();
    },
    startVideo() {
        this.player.play(); 
        this.$parent.$emit("played");
    },
    play() {
        this.show = !this.show;
    },
    makePlayer() { 
      var that = this;
        if (that.player) { 
            that.player.dispose();
        }
        that.player = videojs(that.$refs.videoPlayer, that.options, function onPlayerReady() {
            that.player.player_.duration = function() {
                return that.timeout;
            }
        });
    },
    ended() {
    },
  }
}
</script>
<style>
  .youtube-container {
  width: 100%;
  height: 100%;
  }

  .youtube-banner {
  width: 100%;
  position: absolute;
  height: 100%;
  }
</style>
