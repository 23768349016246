<template>
<div>
  <file-pond
    :name="fileid"
    ref="pond"
    label-idle="Drop files here..."
    v-bind:allow-multiple="false"
    accepted-file-types="image/jpeg, image/png"
    :server="server"
    :chunkSize="2048*1024"
    chunkUploads="true"
    :files="files"
    @processfilestart="Present"
    @processfile="Ready"
    @removefile="Absent"
    @addfile="handleLoad"
    />
</div>
</template>

<script>
/* eslint-disable no-console */
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond =  vueFilePond(FilePondPluginImagePreview,
                              FilePondPluginFileValidateType)

export const FileState = {
  absent: 0,
  present: 1,
  ready: 2
}

export default {
  name: "FileKhal",
  data: function () {
    return {
      ready: FileState.absent,
      filename: ''
    }
  },
  props: [
    'app',
    'rel',
    'field',
    'idfield', 
  ],
  computed: {
    server: function() {
      return {
        url: `/api/${this.app}/files/`,
        headers: {
          'X-CSRFToken': this.api.token
        },
        revert: 'delete/',
      };
    },
    fileid: function() {
      return `${this.idfield}-${this.rel}-${this.field}`
    },
    files: function() {
      return [{
        source: this.fileid,
        options: {
          type: 'limbo', 
          },
        }];
    },
  },
  components: {
    FilePond,
  },
  mounted: function() {
    this.$nextTick(function() { 
      if (this.$refs.pond.getFiles().length)
        this.ready = FileState.present;
    });
  },
  methods: {
    Absent: function (err) {
      if(!err) this.ready = FileState.absent
    },
    Present: function() {
      this.ready = FileState.present
    },
    Ready: function (err, file) {
      if(!err) {
        this.ready = FileState.ready
        this.filename = file.serverId
      }
    },
    readyState: function() {
      return this.ready;
    },
    handleLoad: function(err, fn) { //rsp) {
      if (fn.fileSize == 0) {
        this.$refs.pond.removeFiles()
      }
      else 
        this.Present();
    },
  },
};


</script>
<style scoped>
div {
    width: 100%;
}
>>>.filepond--root{
    min-height: 288px;
    width: 100%;
}
</style>
  
