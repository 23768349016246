/* eslint-disable */

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'home',
    component: 'Home',
  },
  {
    path: '/:ctx/recipe-detail/:category/:pk/:idx',
    name: 'recipe-detail',
    component: 'PostModal',
    props: true,
  },

  {
    path: '/story-detail/:username',
    name: 'story-detail',
    component: 'StoryModal',
    props: true,
  },
  {
    path: '/contact/howto/:lang',
    name: 'howto',
    component: 'Howto',
    props: true,
  },
  {
    path: '/contact/about_us',
    name: 'aboutus',
    component: 'AboutUs',
    props: true,
  },
  {
    path: '/:ctx/quickie-detail/:category/:pk/:idx',
    name: 'quickie-detail',
    component: 'VideoModal',
    props: true,
  },
  {
    path: '/recipe/:username/:slug',
    name: 'recipe',
    component: 'Recipe',
    props: true,
  },
  {
    path: '/accounts/profile/:username/:tab/:cat',
    name: 'profile',
    component: 'Profile',
    props: true,
  },
  {
    path: '/accounts/get-verified',
    name: 'getverified',
    component: 'GetVerified',
    props: true,
  },
  {
    path: '/accounts/my-earnings',
    name: 'myearnings',
    component: 'MyEarning',
    props: true,
  },
  {
    path: '/accounts/my-purchases',
    name: 'mypurchases',
    component: 'MyPurchases',
    props: true,
  },
  {
    path: '/accounts/payment-stat',
    name: 'paymentstatverification',
    component: 'PaymentStatVerification',
    props: true,
  },
  {
    path: '/accounts/show-qr',
    name: 'show-qr',
    component: 'ShowQRIndia',
    props: true,
  },
  {
    path: '/tip/payment-stat',
    name: 'tipstatverification',
    component: 'TipStatVerification',
    props: true,
  },
  {
    path: '/accounts/profile/:username/',
    name: 'profile-detail',
    component: 'Profile',
    props: true,
    redirect: (route) => {
      return {
        name: 'profile',
        params: {
          username: route.params.username,
          tab: route.params.tab ? route.params.tab : 'bio',
          cat: 'any',
        },
      };
    },
  },

  {
    path: '/posts/:query',
    name: 'search',
    component: 'Home',
    redirect: (to) => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/posts', query: { q: to.params.query } };
    },
  },

  {
    path: '/:ctx/user-posts/:cat',
    name: 'user-posts',
    component: 'UserPostList',
    props: true,
  },
  /*{
    path: '/post/add',
    name: 'add-post',
    component: 'AddPost',
    props: true,
  },
  {
    path: '/post/edit/:post_pk',
    name: 'edit-post',
    component: 'AddPost',
    props: true,
  },*/
  {
    path: '/posts',
    component: 'Home',
    props: (route) => ({ query: route.query.q }),
  },
  {
    path: '/groups',
    component: 'GroupPage',
    name: 'all-groups',
  },
  {
    path: '/mygroups',
    component: 'GroupPage',
    props: {
      choice: 'mine',
    },
    name: 'my-groups',
  },
  {
    path: '/followedbyme',
    component: 'MyFollowedBy',
    name: 'followedbyme',
    props: true,
  },
  {
    path: '/groups/:slug/:tab/:cat',
    component: 'Group',
    props: true,
    name: 'group-posts',
  },
  {
    path: '/groups/:slug/:tab/',
    component: 'Group',
    name: 'group-members',
    props: true,
  },
  {
    path: '/groups/:slug/',
    component: 'Group',
    props: true,
    redirect: (route) => {
      return {
        name: 'group-members',
        params: { slug: route.params.slug, tab: 'members', cat: 'any' },
      };
    },
  },
  // {
  //   path: '/posts/:query',
  //   name: 'search',
  //   component: 'Search',
  //   redirect: to => {
  //     // the function receives the target route as the argument
  //     // we return a redirect path/location here.
  //     return { path: '/posts', query: { q: to.params.query } }
  //   }
  // },
  // {
  //   path: '/posts',
  //   component: 'Search',
  //   props: route => ({ query: route.query.q })
  // }
].map((route) => ({
  ...route,
  component: require(`@/components/${route.component}.vue`).default,
}));

const router = new Router({
  //mode: 'history',
  routes,
});

export default router;
