<template>
    <div class="profile-body">
        <LoadingOverlay v-if="overlayLoading"/>

      <Back/>
      <div class="profile-overlap-group6 mt-2">
        <h1 class="text-27 valign-text-middle inter-bold-black-25px">
          <span><span class="inter-bold-black-25px">Show the world your new Recipe</span> </span>
        </h1>
        <div v-if="!user" class="yt-div mt-5 text-2 inter-semi-bold-eerie-black-20px">
            <span class="inter-semi-bold-eerie-black-20px">
              <span class="sign-text" @click="showLogin">Login</span> or <a href="/accounts/signup/" class="sign-text">Signup</a> to apply to add a new Recipe
            </span>
        </div>
        <b-col v-else class="w-100 mt-5">
            <div v-if="recipeType == ''">
                <b-row class="w-100 justify-content-center">
                    <h1 class="inter-semi-bold-eerie-black-20px">
                        Choose your recipe type
                    </h1>
                </b-row>
                <b-row class="w-100 justify-content-center mt-2" align-h="between">
                    <b-col  sm="12" md="6" lg="4"><b-card
                        title="Photo Recipe"
                        style="max-width: 20rem;"
                        class="h-100 mb-2 cursor-pointer text-center"
                        align-h="between"
                        @click="setRecipeType('photo')"
                    >
                        <b-col class="h-100 w-100 justify-content-between align-items-center">
                            <img
                                class="icon-check_mark"
                                src="../assets/photos.jpg"
                                alt="icon-check_mark"
                            />
                            <p>
                                Indulge your senses with our visually enticing photo recipe, guiding you to culinary perfection.
                            </p>
                        </b-col>
                    </b-card></b-col>
                    <b-col sm="12" md="6" lg="4"><b-card
                        title="Video Recipe"
                        style="max-width: 20rem;"
                        class="h-100 mb-2 cursor-pointer text-center"
                        @click="setRecipeType('video')"

                    >
                        <b-col class="h-100 w-100 justify-content-between align-items-center">
                            <img
                                class="icon-check_mark my-4"
                                src="../assets/youtube_red.png"
                                alt="icon-check_mark"
                            />
                            <p>
                                Elevate cooking skills with immersive video recipe, bringing delicious dishes to life before your eyes.
                            </p>
                        </b-col>
                    </b-card></b-col>
                    <b-col sm="12" md="6" lg="4"><b-card
                        title="Quickies"
                        style="max-width: 20rem;"
                        class="h-100 mb-2 cursor-pointer text-center"
                        @click="setRecipeType('quickies')"

                    >
                        <b-col class="h-100 w-100 justify-content-between align-items-center">
                            <img
                                class="icon-check_mark my-3"
                                src="../assets/vplayer.png"
                                alt="icon-check_mark"
                            />
                            <p>
                                Quickies: Delightful one-minute video recipes for instant culinary satisfaction.
                            </p>
                        </b-col>
                       

                    </b-card></b-col>
                </b-row>
            </div>
            <div v-else>
                <div>
                    <b-tabs content-class="mt-3" v-model="tabIndex" pills justified>
                        <b-tab title="Photo Recipe"><div>
                            <b-form >
                                <b-row>
                                    <b-col cols="12" lg="6"><b-form-group id="input-group-3" label="Select Group" label-for="input-3">
                                        <b-form-select
                                        id="input-3"
                                        v-model="photo_recipe.group"
                                        :options="groups"
                                        required
                                        class="w-100"
                                        ></b-form-select>
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'photo_recipe.group')"> {{this.errors.find(o => o.name === 'photo_recipe.group').error}} </span>

                                    </b-form-group></b-col>
                                    <b-col cols="12" lg="6"></b-col>
                                    <b-col  cols="12" lg="6"><b-form-group id="input-group-3" label="Recipe Main Photo" label-for="input-3">
                                        <b-col >
                                          <FileKhal app="posts" rel="self"
                                                    :allowMultiple="false"
                                                    ref="photo_main"
                                                    field="main_image"
                                                    :idfield="post_pk || 'new'"
                                                    />
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'photo_recipe.photo_main')"> {{this.errors.find(o => o.name === 'photo_recipe.photo_main').error}} </span>

                                        <div class="w-50"></div>
                                        </b-col>
                                        
                                    </b-form-group></b-col>
                                    <b-col  cols="12" lg="6"><b-form-group class="filepond--item--row" id="input-group-3" label="Recipe Photos" label-for="input-3">
                                        <FileKhal app="posts" rel="post_prep"
                                                  :allowMultiple="true"
                                                  :allowReorder="true"
                                                  ref="photo_other"
                                                  field="post_prep"
                                                  :idfield="post_pk || 'new'"
                                        />
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'photo_recipe.photo_other')"> {{this.errors.find(o => o.name === 'photo_recipe.photo_other').error}} </span>

                                    </b-form-group></b-col>
                                    <b-col cols="12"><b-form-group
                                        id="input-group-1"
                                        label="Recipe Title"
                                        label-for="input-1"
                                    >
                                        <b-form-input
                                        id="input-1"
                                        v-model="photo_recipe.title"
                                        type="text"
                                        placeholder="Enter Recipe title"
                                        required
                                        ></b-form-input>
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'photo_recipe.title')"> {{this.errors.find(o => o.name === 'photo_recipe.title').error}} </span>

                                    </b-form-group></b-col>
                                    <b-col  cols="12"><b-form-group
                                        id="input-group-1"
                                        label="Recipe Description"
                                        label-for="input-1"
                                    >
                                        <vue-editor v-model="photo_recipe.description" />
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'photo_recipe.description')"> {{this.errors.find(o => o.name === 'photo_recipe.description').error}} </span>

                                    </b-form-group></b-col>
                                </b-row>
                                <b-button @click="checkForm('photo')" variant="primary">Submit</b-button>
                            </b-form>      
                        </div>
                        </b-tab>
                        <b-tab title="Video Recipe"><div>
                            <b-form>
                                <b-row>
                                    <b-col cols="12" lg="6" ><b-form-group id="input-group-3" label="Select Group" label-for="input-3">
                                        <b-form-select
                                        id="input-3"
                                        v-model="video_recipe.group"
                                        :options="groups"
                                        required
                                        class="w-100"
                                        ></b-form-select>
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'video_recipe.group')"> {{this.errors.find(o => o.name === 'video_recipe.group').error}} </span>

                                    </b-form-group></b-col>
                                    <b-col cols="12" lg="6"></b-col>
                                    <b-col cols="12"><b-form-group
                                        id="input-group-1"
                                        label="Enter youtube video URL"
                                        label-for="input-1"
                                    >
                                        <b-form-input
                                        id="input-1"
                                        v-model="video_recipe.youtube_url"
                                        type="text"
                                        placeholder="Enter Recipe title"
                                        v-on:input="checkYoutube($event)"
                                        required
                                        ></b-form-input>
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'video_recipe.youtube_url')"> {{this.errors.find(o => o.name === 'video_recipe.youtube_url').error}} </span>

                                    </b-form-group></b-col>
                                    <b-col  cols="12" lg="6"><b-form-group id="input-group-3" label="Video Thumbnail" label-for="input-3">
                                        <b-col >
                                            <FileKhal app="posts" rel="self"
                                                      ref="video_thumbnail"
                                                      field="main_image"
                                                      :idfield="post_pk || 'new'"
                                        />
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'video_recipe.video_thumbnail')"> {{this.errors.find(o => o.name === 'video_recipe.video_thumbnail').error}} </span>

                                        <div class="w-50"></div>
                                        </b-col>
                                        
                                    </b-form-group></b-col>
                                    <b-col cols="12"><b-form-group
                                        id="input-group-1"
                                        label="Recipe Title"
                                        label-for="input-1"
                                    >
                                        <b-form-input
                                        id="input-1"
                                        v-model="video_recipe.title"
                                        type="text"
                                        placeholder="Enter Recipe title"
                                        required
                                        ></b-form-input>
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'video_recipe.title')"> {{this.errors.find(o => o.name === 'video_recipe.title').error}} </span>

                                    </b-form-group></b-col>
                                    <b-col  cols="12"><b-form-group
                                        id="input-group-1"
                                        label="Recipe Description"
                                        label-for="input-1"
                                    >
                                        <vue-editor v-model="video_recipe.description" />
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'video_recipe.description')"> {{this.errors.find(o => o.name === 'video_recipe.description').error}} </span>

                                    </b-form-group></b-col>
                                </b-row>
                                <b-button @click="checkForm('video')" variant="primary">Submit</b-button>
                            </b-form>      
                        </div></b-tab>
                        <b-tab title="Quickies"><div>
                            <b-form>
                              <b-row>
                                    <b-col cols="12" lg="6"><b-form-group id="input-group-3" label="Select Group" label-for="input-3">
                                        <b-form-select
                                        id="input-3"
                                        v-model="quickie.group"
                                        :options="groups"
                                        required
                                        class="w-100"
                                        ></b-form-select>
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'quickie.group')"> {{this.errors.find(o => o.name === 'quickie.group').error}} </span>

                                    </b-form-group></b-col>
                                    <b-col cols="12" lg="6"></b-col>
                                    <b-col  cols="12" lg="6"><b-form-group id="input-group-3" label="Quickie Video" label-for="input-3">
                                        <b-col >
                                          <FileKhal app="posts" rel="self"
                                                    :allowMultiple="false"
                                                    ref="video_file"
                                                    field="video_file"
                                                    :idfield="post_pk || 'new'"
                                        />
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'quickie.video_file')"> {{this.errors.find(o => o.name === 'quickie.video_file').error}} </span>
                                        </b-col>
                                    </b-form-group></b-col>
                                    <b-col  cols="12" lg="6"><b-form-group class="" id="input-group-3" label="Quickie Thumbnail" label-for="input-3">
                                        <FileKhal app="posts" rel="self"
                                                  ref="quickie_thumbnail"
                                                  field="main_image"
                                                  :idfield="post_pk || 'new'"
                                        />
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'quickie.quickie_thumbnail')"> {{this.errors.find(o => o.name === 'quickie.quickie_thumbnail').error}} </span>

                                    </b-form-group></b-col>
                                    <b-col cols="12"><b-form-group
                                        id="input-group-1"
                                        label="Recipe Title"
                                        label-for="input-1"
                                    >
                                        <b-form-input
                                        id="input-1"
                                        v-model="quickie.title"
                                        type="text"
                                        placeholder="Enter Recipe title"
                                        required
                                        ></b-form-input>
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'quickie.title')"> {{this.errors.find(o => o.name === 'quickie.title').error}} </span>

                                    </b-form-group></b-col>
                                    <b-col  cols="12"><b-form-group
                                        id="input-group-1"
                                        label="Recipe Description"
                                        label-for="input-1"
                                    >
                                        <vue-editor v-model="quickie.description" />
                                        <span class="field-error" v-if="this.errors.find(o => o.name === 'quickie.description')"> {{this.errors.find(o => o.name === 'quickie.description').error}} </span>

                                    </b-form-group></b-col>
                                </b-row>
                                <b-button type="submit" variant="primary">Submit</b-button>
                            </b-form>      
                        </div></b-tab>
                    </b-tabs>
                </div>
            </div>
        </b-col>
      </div>
    </div>
</template>
  
<script>

/* eslint-disable no-console */

import { mapGetters } from 'vuex';
import Back from './Back.vue';
import { VueEditor } from "vue2-editor";
import FileKhal, { FileState } from "./FileKhal.vue";
import LoadingOverlay from './LoadingOverlay.vue';

  export default {
    name: 'AddPost',
    mixins: [],

    components: {
        Back,
        VueEditor,
        FileKhal,
        LoadingOverlay
    },
    computed : {
        user () {
            return this.getUserInfo()
        },
    },
    props: {
        post_pk: {
        type: Number,
        default: function() {
            return null
        }
        },
    },
    data(){
      return {
        recipeType:'',
        tabIndex: 0,
        groups:[],
        overlayLoading:false,

        photo_recipe: {
            group:'',
            title:'',
            description:'',
            photo_main:'',
            photo_other:[],
        },
        video_recipe: {
            group:'',
            title:'',
            youtube_url:'',
            youtube_id:'',
            description:'',
            video_thumbnail:'',
        },
        quickie: {
            group:'',
            title:'',
            description:'',
            video_file:'',
            quickie_thumbnail:'',
        },
        errors:[]
      }
    },

    methods:{
        ...mapGetters(['getUserInfo']),

        checkForm: function () {
            this.overlayLoading = true
            this.errors = [];
            if(this.recipeType == 'photo'){
                if (!this.photo_recipe.title || this.photo_recipe.title === '') {
                    this.errors.push({'name':'photo_recipe.title','error':'Title is required.'});
                }
                if (!this.photo_recipe.group || this.photo_recipe.group === '' ) {
                    this.errors.push({'name':'photo_recipe.group','error':'Group is required.'});
                }
                if (!this.photo_recipe.description || this.photo_recipe.description ==='') {
                    this.errors.push({'name':'photo_recipe.description','error':'Description is required.'});
                }
                ['photo_main', 'photo_other'].forEach((field) => this.handleFiles(field,'photo_recipe'));
            }
            if(this.recipeType == 'video'){
                if (!this.video_recipe.title || this.video_recipe.title === '') {
                    this.errors.push({'name':'video_recipe.title','error':'Title is required.'});
                }
                if (!this.video_recipe.group || this.video_recipe.group === '' ) {
                    this.errors.push({'name':'video_recipe.group','error':'Group is required.'});
                }
                if (!this.video_recipe.description || this.video_recipe.description ==='') {
                    this.errors.push({'name':'video_recipe.description','error':'Description is required.'});
                }
                if (!this.video_recipe.youtube_url || this.video_recipe.youtube_url ==='') {
                    this.errors.push({'name':'video_recipe.youtube_url','error':'Youtube URL is required.'});
                }
                this.handleFiles('video_thumbnail','video_recipe')

            }
            if(this.recipeType == 'quickies'){
                if (!this.quickie.title || this.quickie.title === '') {
                    this.errors.push({'name':'quickie.title','error':'Title is required.'});
                }
                if (!this.quickie.group || this.quickie.group === '' ) {
                    this.errors.push({'name':'quickie.group','error':'Group is required.'});
                }
                if (!this.quickie.description || this.quickie.description ==='') {
                    this.errors.push({'name':'quickie.description','error':'Description is required.'});
                }
                this.handleFiles('quickie_thumbnail','quickie')
                this.handleFiles('video_file','quickie')
            }
            
            if(this.errors.length === 0) {
                this.postRecipe()
            }else{
                this.$bvToast.toast(`Hey, we need some details here in order to get you verified. You cannot skip these the way you can't skip salt on you lasagna`, {
                title: `Please complete the form first`,
                variant: 'danger',
                autoHideDelay: 5000,
                })
                this.overlayLoading = false
            }
        },


        postRecipe(){
            let formData = new FormData();
            if(this.recipeType == 'photo'){
                formData.append("group_id", this.photo_recipe.group);
                formData.append("title", this.photo_recipe.title);
                formData.append("post_type", 1);
                formData.append("message", this.photo_recipe.description);
                formData.append("post_prep", [this.photo_recipe.photo_main, ...this.photo_recipe.photo_other]);
            }
            if(this.recipeType == 'video'){
                formData.append("group_id", this.video_recipe.group);
                formData.append("title", this.video_recipe.title);
                formData.append("video", this.video_recipe.youtube_url);
                formData.append("post_type", 2);
                formData.append("message", this.video_recipe.description);
                formData.append("post_prep", this.video_recipe.video_thumbnail);
            }
            if(this.recipeType == 'quickie'){
                formData.append("group_id", this.quickie.group);
                formData.append("title", this.quickie.title);
                formData.append("post_type", 3);
                formData.append("message", this.quickie.description);
                formData.append("video_file", this.quickie.video_file);
                formData.append("post_prep", this.quickie.quickie_thumbnail);
            }

            this.api.formDataPost(`posts/recipe/list/`, formData,true).then((data) => {
                if (data.success){
                    this.$router.push({name: 'post-success'});
                }else{
                    this.$bvToast.toast(`We could add your new recipe.`, {
                        title: `Please try again`,
                        variant: 'danger',
                        autoHideDelay: 5000,
                    })
                }
            }).catch((error)=>{
                console.log(error);
                this.$bvToast.toast(`We could not process the form.`, {
                title: `Please try again`,
                variant: 'danger',
                autoHideDelay: 5000,
                })
            }).finally(() => {
                this.overlayLoading = false
            });
        },

        handleFiles: function (type,recipe_type) {
            console.log(type);
            switch (this.$refs[type].readyState()) {
            case FileState.absent:
                this.errors.push({'name':recipe_type+'.'+type,
                                'error':'Field is required.'});
                break;
            case FileState.present:
                this.errors.push({'name':recipe_type+'.'+type, 
                                'error':'Wait for this to finish upload'});
                break;
            default:
                console.log(type,recipe_type);
                if(type == 'photo_other'){
                    let temp_array = [];
                    this.$refs['photo_other'].$refs.pond.getFiles().forEach(file => {
                        temp_array.push(file.file.name)
                    });
                    if(temp_array.length<2){
                        this.errors.push({'name':recipe_type+'.'+type,
                                'error':'You must have atleast 2 additional photos'});
                    }else{
                        this[recipe_type][type] = temp_array
                    }
                }else{
                    this[recipe_type][type] = this.$refs[type].filename
                }
            }
        },

        checkYoutube(url){
            let youtube_id = this.youTubeGetID(url);
            let youtube_url = `https://www.youtube.com/watch?v=${youtube_id}`;
            this.video_recipe.youtube_url = youtube_url;
            this.youtube_id = youtube_id
            this.getYoutubeDetail(youtube_id)
        },
        

        getYoutubeDetail(youtube_id){
            // AIzaSyCqUdPiskcVJOfAaejZ6QjuIAHcp7XcHww
            this.api.get(`https://www.googleapis.com/youtube/v3/videos?id=${youtube_id}&key=AIzaSyB75sCvG-v1mO1bJJTXUtaFgX8hiQAd4WY&part=snippet`,).then((data) => {
                console.log(data);
                this.video_recipe.title = data.items[0].snippet.title;
                this.video_recipe.description = data.items[0].snippet.description;
                console.log(this.$refs['video_thumbnail']);
                this.$refs['video_thumbnail'].$refs.pond.addFile(data.items[0].snippet.thumbnails.standard.url);
                this.video_recipe.video_thumbnail = data.items[0].snippet.thumbnails.standard.url;
            })
        },
        youTubeGetID(url){
            var ID = '';
            url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
            if(url[2] !== undefined) {
                // eslint-disable-next-line no-useless-escape
                ID = url[2].split(/[^0-9a-z_\-]/i);
                ID = ID[0];
            }
            else {
                ID = url;
            }
            return ID;
        },
        getGroups(){
            this.api.get(`api/groups/category/list/1`,).then((data) => {
                if(data.data.length > 0){
                    data.data.forEach(group => {
                        this.groups.push({text:group.name, value:group.pk})
                    });
                }
            })
        },
   
        setRecipeType(type){
            this.recipeType = type
            switch (type) {
                case 'photo':
                    this.tabIndex = 0
                    break;
                case 'video':
                    this.tabIndex = 1
                    break;
                
                case 'quickies':
                    this.tabIndex = 2
                    break;
                default:
                    break;
            }
        }
    },
    mounted(){
    },
    created() {
        this.getGroups()
    },
}
</script>

<style scoped>
  .sign-text{
        cursor: pointer;
        color: blue;
    }
    .filepond-holder{
        min-height: 288px;
        width: 100%;
    }
    .cursor-pointer{
        cursor: pointer !important;
    }
    .custom-select {
        border: 1px solid #CCDBE2; 
        border-radius: 50px;
        height: 50px;
        padding: 0px 20px;
    }
    @media (min-width: 480px) {
        .filepond--item {
            width: calc(50% - 0.5em) !important;
        }
    }
    @media (min-width: 1080) {
        .filepond--item {
            width: calc(33.33% - 0.5em) !important;
        }
    }

    @media (max-width: 480px) {
        .filepond--item {
            width: calc(100% - 0.5em) !important;
        }
    }

    .field-error{
        color: tomato;
    }
   
</style>
  
