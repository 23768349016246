<template>
<div class="post-overlap-group6">
  <header class="header mobile-show">
    <div class="post-flex-col-1 post-flex-col-3">
      <UserLine :user="post.user">
      </UserLine>
      <div class="post-flex-row">
        <div class="video" ref="poster"> <!--v-bind:style="{ 'backgroundImage': 'url(' + mainImg + ')'}"-->
          <div style="width: 100%;">
            <!-- <img :src="mainImg" style="width: 100%;"/> -->
            <component v-if="postImg.width > 0" :is="subcomp" :post="post" :width="postImg.width" :height="postImg.height">
            </component>
          </div>
        </div>
      </div>
      <div class="post-flex-row">
        <div class="posted-3-hours-ago valign-post-text-middle inter-normal-black-17px">
          <span><span class="inter-normal-black-17px">Posted: {{post.created_at}}</span> </span>
        </div>
        <div class="report_button"  @click="handleReport">
          <img class="icon-flag" :src="static_fn('img/ion-flag-outline-1@2x.png')" alt="icon-flag" />
          <div class="report valign-post-text-middle inter-normal-black-17px">
            <span><span class="inter-normal-black-17px">Report</span> </span>
          </div>
        </div>
      </div>
      <div class="txt">
        <h1 class="post-text-6 valign-post-text-middle inter-bold-eerie-black-30px">
          <span><span class="inter-bold-eerie-black-30px">{{post.title}}</span>
          </span>
        </h1>
        <div class="action_buttons">
          <img class="line-204" :src="static_fn('img/line-204-41@2x.png')" alt="Line 204" />
          <div class="post-flex-row">
            <div class="post-overlap-group3 col-xs-6" :inkey="post.pk">
              <img @click="handleLike(post.pk)" class="icon" :src="static_fn(post.like_state ? 'img/ic_like_selected.png' : 'img/fluent-thumb-like-20-regular-1@2x.png')" alt="icon-thumbs_up"/>
              <div class="post-text-1 valign-post-text-middle inter-semi-bold-eerie-black-17px" @click="showLike(post.pk)">
                <span><span class="inter-semi-bold-eerie-black-17px"><span v-if="post.num_likes">{{post.num_likes}}</span> {{likestr}}</span> </span>
              </div>
            </div>
            <div class="post-overlap-group3 col-xs-6" :inkey="post.pk">
              <img class="icon" @click="handleMake(post.pk)" :src="static_fn(post.make_state ? 'img/ic_star_filled.png' : 'img/star-svgrepo-com--2--1-15@2x.png')" alt="icon-star" />
              <div class="post-text-1 valign-post-text-middle inter-semi-bold-eerie-black-17px" @click="showMake(post.pk)">
                <span><span class="inter-semi-bold-eerie-black-17px"><span v-if="post.num_makes">{{post.num_makes}}</span> {{makestr}}</span> </span>
              </div>
            </div>
          </div>
          <div class="post-flex-row">
            <div class="post-overlap-group3 col-xs-6"  @click="handleShare">
              <img class="icon-share" :src="static_fn('img/ph-share-network-1@2x.png')" alt="icon-share" />
              <div class="post-text-9 valign-post-text-middle inter-semi-bold-eerie-black-17px">
                <span><span class="inter-semi-bold-eerie-black-17px">Share</span> </span>
              </div>
            </div>
            <div class="post-overlap-group3 col-xs-6" :id="`comment_button-${ post.pk }`" @click="handleComment">
              <img class="icon-share" :src="static_fn('img/bytesize-message-1@2x.png')" alt="icon-share" />
              <div class="post-text-9 valign-post-text-middle inter-semi-bold-eerie-black-17px">
                <span><span class="inter-semi-bold-eerie-black-17px">{{post.num_comments}} Comment</span> </span>
              </div>
            </div>
            
          </div>
          <div class="post-flex-row">
            <div class="post-overlap-group3 col-xs-6" v-if="user && post.user.gov_id_proof_status =='Approved' && post.user.accept_tip" @click="handleTip">
              <img
                class="phcurrency-circle-dollar-fill"
                src="../assets/ph-currency-circle-dollar-fill-4@2x.png"
                alt="ph:currency-circle-dollar-fill"
              />
              <div class="post-text-9 valign-post-text-middle inter-semi-bold-eerie-black-17px">
                <span class="inter-semi-bold-eerie-black-17px">Send Tip</span>
              </div>
              
            </div>
          </div>
          <div v-if="user && user.username == post.user.username" class="post-flex-row">
            <a class="post-overlap-group3 col-xs-6" :href="'/posts/delete/' + post.slug">
              <span class="glyphicon glyphicon-remove text-danger" ></span>
              <div class="post-text-9 valign-post-text-middle inter-semi-bold-eerie-black-17px">
                <span><span class="inter-semi-bold-eerie-black-17px">Delete</span> </span>
              </div>
            </a>
            <a class="post-overlap-group3 col-xs-6" :href="'/posts/dredit/' + post.pk">
              <span class="glyphicon glyphicon-pencil text-warning" ></span>
              <div class="post-text-9 valign-post-text-middle inter-semi-bold-eerie-black-17px">
                <span><span class="inter-semi-bold-eerie-black-17px">Edit</span> </span>
              </div>
            </a>
          </div>
          <!-- <div class="right" @click="handleProof">
            <img class="group" :src="static_fn('img/group-33@2x.png')" alt="Group" />
            <div class="post-text-1 valign-post-text-middle inter-normal-white-17px2">
              <span class="inter-normal-white-17px2">I have Made This Recipe</span> 
            </div>
          </div> -->
        </div>
        <p class="dalle-antiche-ricett valign-post-text-middle inter-normal-black-17px">
          <span class="inter-normal-black-17px" v-html="post.message"></span>
        </p>
      </div>
    </div>
  </header>
  <PostComments :post="post" @newcomment="handleNewComment" :key="reloadComments" :focus="inComment">
  </PostComments>
  <UserListModal v-if="selectedPost > 0" :context="userContext" :title="userTitle" :users="users" ref="modal" :pk="selectedPost" @closed="onHideDetail" @modalOut="onModalOut" :key="selectedPost"></UserListModal>
  <Share v-if="showShare" :post="post" @closed="onHideDetail"/>
  <TipsModal v-if="showTip" :post="post" @closed="onHideDetail"/>

</div>
</template>

<script>
/* eslint-disable no-console */
import PostDetail from './PostDetail';
import PostComments from './PostComments';

export default {
  name: 'PostDetailMobile',
  extends: PostDetail,
  components: {
    PostComments,
  },

}
  
</script>

<style scoped>
.action_buttons {
  cursor: pointer;
}
.tip-div{
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: flex-start;
  background-color: var(--zumthor);
  border-radius: 10px;
  gap: 11px;
  height: 49px;
  padding: 14px 18px;
}
.phcurrency-circle-dollar-fill {
  height: 25px;
  width: 25px;
  align-self: center;
}
</style>
