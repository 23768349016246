<template>
  <div class="vid-cont">
    <div v-if="first" class="thumb-place">
      <img :src="first.image" ref="pimage" 
           :srcset="first.thumbs"
           :sizes="sizes" style="object-fit: cover;"
           @load="handleLoad"/>
    </div>
    <div v-if="first" v-bind:class="thumbZ" @click="startVideo">
      <img :src="first.image"
           :srcset="first.thumbs"
           :sizes="sizes" style="object-fit: cover;">
      <img v-if="playImg" class="play-button" :src="playImg" :key="playImg"/>
    </div>
    <div v-if="iReady" class="player-cont" :key="iReady">
      <youtube :video-id="videoId" ref="youtube"
               @ended="ended"
               :width="width" :height="height"
               :player-vars="playerVars" :fitParent="true"></youtube>
    </div>
  </div>
</template>
<script>
const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });

export default {
  name: 'Video',
  props: [
    'post',
    'sizes',
  ],
  data() {
    return {
      'first': (this.post.preps.length > 0) ? this.post.preps[0] : null,
      'videoId': this.post.video_id,
      'width': 0,
      'height': 0,
      'iReady': (this.post.preps.length == 0), 
      'pRunning': false,
      'playerVars': {
        //'autoplay': 1,
        'rel': 0,
        'enablejsapi': 1,
        'fs': 1,
        'modestbranding': 1,
      },
      'playImg': null,

    };
  },
  computed: {
    thumbZ: function() {
      return {
        'thumb-cont': true,
        'thumb-top': !this.pRunning
      };
    },
  },
  mounted() {
    log.info ("Video mount " + this.iReady);
  },
  methods: {
    pauseVideo() {
      if (this.iReady) {
        this.$refs.youtube.player.pauseVideo();
        this.pRunning = false;
      }
    },
    stopVideo() {
      if (this.iReady) {
        this.$refs.youtube.player.stopVideo();
        this.pRunning = false;
      }
    },
    startVideo() {
      if (this.iReady) {
        this.$refs.youtube.player.playVideo();
        this.$parent.$emit("playing", this);
        this.pRunning = true;
        setTimeout(() => this.showMake(), 15000)
      }
    },
    showMake() {
      if (this.pRunning) {
        document.querySelector("#make-section-" + this.post.pk + " .inter-bubble").style.display = 'inline-block';
        setTimeout(() => document.querySelector("#make-section-" + this.post.pk + " .inter-bubble").style.display = 'none', 
                   5000);
      }
    },
    handleLoad(ev) {
      if (!this.pRunning) { 
        this.height = ev.target.clientHeight; 
        this.width = ev.target.clientWidth;
        //this.pReady = true;
        this.api.map_prom.then(() => {
          this.playImg = this.api.mkstatic("youtube_red.png");
          this.iReady = true;
        });
      }
      this.$emit ("loaded", this.post.pk);
    },
    ended() {
      //log.info ("Ended");
      this.pRunning = false;
    },
  }
}
</script>
