<template>
<div class="post-overlap-group6">
  <header class="header mobile-hide">
    <div class="recipe-cont">
      <div class="video recipe-imgs" ref="poster"> <!--v-bind:style="{ 'backgroundImage': 'url(' + mainImg + ')'}"-->
        <div style="width: 100%; position: absolute; min-height: 100%;">
          <component v-if="postImg.width > 0" ref="subcomp" :is="subcomp" :post="post" :width="postImg.width" :height="postImg.height">
          </component>
        </div>
      </div>
    </div>
    <div class="recipe-msgs-cont">
      <div class="post-flex-col-1 post-flex-col-3 recipe-msgs">
        <div class="post-flex-row">
          <UserLine :user="post.user">
          </UserLine>
           <div v-if="!user || user.username!=post.user.username" class="report_button" @click="handleReport">
            <img class="icon-flag" :src="static_fn('img/ion-flag-outline-1@2x.png')" alt="icon-flag" />
            <div class="report valign-post-text-middle inter-normal-black-17px">
              <span><span class="inter-normal-black-17px">Report</span> </span>
            </div>
          </div>
        </div>
        <div class="txt">
          <div class="posted-3-hours-ago valign-post-text-middle inter-normal-black-17px">
            <span><span class="inter-normal-black-17px">Posted: {{post.created_at}}</span> </span>
          </div>
          <h1 class="post-text-6 valign-post-text-middle inter-bold-eerie-black-30px">
            <span><span class="inter-bold-eerie-black-30px">{{post.title}}</span>
            </span>
          </h1> 
          <p class="dalle-antiche-ricett valign-post-text-middle inter-normal-black-17px">
            <span class="inter-normal-black-17px" v-html="post.message"></span>
          </p>
        </div>
      </div>
    </div>
  </header>
  <div class="action_buttons mobile-hide">
    <img class="line-204" :src="static_fn('img/line-204-41@2x.png')" alt="Line 204" />
    <div class="post-flex-row-1">
      <div class="left">
        <div class="post-overlap-group3" :key="'like-'+post.like_state" @click="handleLike(post.pk)">
          <img class="icon" :src="static_fn(post.like_state ? 'img/ic_like_selected.png' : 'img/fluent-thumb-like-20-regular-1@2x.png')" alt="icon-thumbs_up" />
          <div class="post-text-1 valign-post-text-middle inter-semi-bold-eerie-black-17px">
            <span><span class="inter-semi-bold-eerie-black-17px"><span v-if="post.num_likes" @click.stop="showLike(post.pk)">{{post.num_likes}}</span> {{likestr}}</span> </span>
          </div>
        </div>
        <div class="post-overlap-group3" :key="'make-'+post.make_state" @click="handleMake(post.pk)">
          <img class="icon" :src="static_fn(post.make_state ? 'img/ic_star_filled.png' : 'img/star-svgrepo-com--2--1-15@2x.png')" alt="icon-star"/>
          <div class="post-text-1 valign-post-text-middle inter-semi-bold-eerie-black-17px" >
            <span><span class="inter-semi-bold-eerie-black-17px">
                <span v-if="post.num_makes" @click.stop="showMake(post.pk)">{{post.num_makes}}</span> {{makestr}}</span> </span>
          </div>
        </div>
        <div class="post-overlap-group3" @click="handleShare">
          <img class="icon-share" :src="static_fn('img/ph-share-network-1@2x.png')" alt="icon-share" />
          <div class="post-text-9 valign-post-text-middle inter-semi-bold-eerie-black-17px">
            <span><span class="inter-semi-bold-eerie-black-17px">Share</span> </span>
          </div>
        </div>
        <div class="post-overlap-group3" :id="`comment_button-${ post.pk }`"
             @click="handleComment">
          <img class="icon-share" :src="static_fn('img/bytesize-message-1@2x.png')" alt="icon-share"/>
          <div class="post-text-9 valign-post-text-middle inter-semi-bold-eerie-black-17px">
            <span><span class="inter-semi-bold-eerie-black-17px">{{post.num_comments}} Comments</span> </span>
          </div>
        </div>
        <div class="post-overlap-group3 " v-if="user && post.user.gov_id_proof_status =='Approved' && post.user.accept_tip" @click="handleTip">
          <img
            class="phcurrency-circle-dollar-fill"
            src="../assets/ph-currency-circle-dollar-fill-4@2x.png"
            alt="ph:currency-circle-dollar-fill"
          />
          <div class="post-text-9 valign-post-text-middle inter-semi-bold-eerie-black-17px">
            <span class="inter-semi-bold-eerie-black-17px">Send Tip</span>
          </div>
        </div>
        <a v-if="user && user.username==post.user.username" class="post-overlap-group3" :href="'/posts/delete/' + post.slug">
          <div class="post-text-9 valign-post-text-middle inter-semi-bold-eerie-black-17px">
            <span class="glyphicon glyphicon-remove text-danger" ></span>
            <span><span class="inter-semi-bold-eerie-black-17px">Delete</span> </span>
          </div>
        </a>
        <a v-if="user && user.username==post.user.username" class="post-overlap-group3" :href="'/posts/dredit/' + post.pk">
          <div class="post-text-9 valign-post-text-middle inter-semi-bold-eerie-black-17px">
            <span class="glyphicon glyphicon-pencil text-warning" ></span>
            <span><span class="inter-semi-bold-eerie-black-17px">Edit</span> </span>
          </div>
        </a>
        <div v-if="user && user.admin==true" class="post-overlap-group3"
             :key="'featured-'+post.featured" @click="handleFeature">
          <img class="icon" :src="static_fn(post.featured ? 'featured.jpg' : 'not_featured.jpg')"/>
        </div>

      </div>
      <!-- <div class="right" @click="handleProof">
        <img class="group" :src="static_fn('img/group-33@2x.png')" alt="Group" />
        <div class="post-text-1 valign-post-text-middle inter-normal-white-17px2">
          <span class="inter-normal-white-17px2">I have Made This Recipe</span> 
        </div>
      </div> -->
    </div>
  </div>
  <PostComments :post="post" @newcomment="handleNewComment" :key="'comments-'+reloadComments" :focus="inComment">
  </PostComments>
  <UserListModal v-if="selectedPost > 0" :context="userContext" :title="userTitle" :users="users" ref="modal" :pk="selectedPost" @closed="onHideDetail" @modalOut="onModalOut" :key="selectedPost"></UserListModal>
  <Share v-if="showShare" :post="post" @closed="onHideDetail"/>
  <TipsModal v-if="showTip" :tipType="tipType" :user="post.user" @closed="onHideDetail"/>
</div>
</template>

<script>
/* eslint-disable no-console */

import UserLine from './UserLine';
import LazyCarousel from './LazyCarousel';
import YoutubeDetail from './YoutubeDetail';
import VideoFile from './VideoFile';
import UserListModal from './UserListModal';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Share from './Share.vue';
import TipsModal from './TipsModal.vue';
import PostComments from './PostComments';


export default {
  name: 'PostDetail',
  components: {
    LazyCarousel,
    YoutubeDetail,
    VideoFile,
    UserLine,
    UserListModal,
    Share,
    TipsModal,
    PostComments,
  },
  props: [
    'post',
    'category',
    'external',
    'showSideBar',
    'hideSideBar',
    'isMobile',
  ],
  computed: {
    user () {
      return this.getUserInfo()
    },
    mainImg: function() {
      return this.post.preps[0].thumbnail;
    },
    likestr: function() {
      return this.post.num_likes > 1 ? "Likes" : "Like";
    },
    makestr: function() {
      return this.post.num_makes == 1 ? "Wants to Make" : "Want to Make";
    }
  },
  data() {
    return {
      //'static_url': window.api_static_url,
      scrollOff: 0,
      origTop: null,
      selectedPost:0,
      feature_html: this.post.feature_html,
      feature: false,
      reload: true,
      modalOpen: false,
      reloadComments: false,
      inComment: false,
      showShare: false,
      showTip:false,
      tipType:'tip',
      postImg: {
        width: 0,
        height: 0
      },
      users: [],
      subcomp: {
        'video': YoutubeDetail,
        'photo': LazyCarousel,
        'quickie': VideoFile
      }[this.category],
      userContext: 'users-like',
      userTitle: 'People who like this',
    };
  },
  mounted() {
    this.$nextTick(() => {
        this.postImg = {
            width: this.$refs.poster.clientWidth,
            height: this.$refs.poster.clientWidth
        }
    });  
  },
  beforeDestroy() {
  },
  updated() {
  },
  methods: {
    ...mapGetters(['getUserInfo']),
    ...mapMutations(['showLogin']),
    ...mapActions(['postOrLogin']),
    handleLike(pk) {
      this.postOrLogin(`posts/like/toggle/${pk}`).then((data) => {
        this.post.like_state = data.state;
        this.post.num_likes = data.count;
        if(data.state && this.user && this.post.user.gov_id_proof_status =='Approved' && this.post.user.accept_tip && this.user.username!=this.post.user.username){
          this.tipType = 'like';
          this.showTip = true;
        }
      })
      
    },
    handleMake(pk) {
      if(this.user && this.user.username != this.post.user.username)
      {
        this.postOrLogin(`posts/make/toggle/${pk}`).then((data) => {
          this.post.make_state = data.state;
          this.post.num_makes = data.count;
          if(data.state && this.user && this.post.user.gov_id_proof_status =='Approved' && this.post.user.accept_tip && this.user.username!=this.post.user.username){
            this.tipType = 'make';
            this.showTip = true;
          }
        })
      }
    },
    handleShare() {
      this.showShare = true;
    },
    handleTip() {
      if(this.user.username!=this.post.user.username){
        this.tipType = 'tip';
        this.showTip = true;
      }else{
        this.$root.$bvToast.toast('You cannot tip yourself. Try tipping other awesome creators on Khal', {
          title: `Something went wrong`,
          variant: 'danger',
          autoHideDelay: 5000,
        })
      }
      
    },

    handleReport() {
      window.location.href = "/report/" + this.post.user.username;
    },
    handleProof() {
      window.location.href = "/proof/new_proof/" + this.post.slug;
      //window.location.href = "/posts/dredit/" + this.post.pk;
    },
    handleFeature() {
      if(this.user && this.user.admin) {
        this.postOrLogin(`posts/feature/toggle/${this.post.pk}`).then((data) => {
          this.post.featured = data.feed != null
        })
      }
    },
    
    showLike(pk) {
      if (this.post.num_likes > 0)
      {
        this.selectedPost = pk;
        this.userContext = "users-like";
        this.userTitle = "People who like this";
      }
    },
    showMake(pk) {
      if (this.post.num_makes > 0)
      {
        this.selectedPost = pk;
        this.userContext = "users-make";
        this.userTitle = "People who want to make this";
      }
    },
    handleNewComment(count) {
      this.post.num_comments = count;
      this.reloadComments = !this.reloadComments;
    },
    handleComment() {
      if (this.user) {
        this.inComment = true;
        this.reloadComments = !this.reloadComments;
      }
      else {
        this.showLogin();        
      }
    },
    onHideDetail() {
      this.selectedPost = 0;
      this.showShare = false;
      this.showTip = false;
    },
    onModalOut() { 
      setTimeout(this.scroller.bind(this), 100);
    },
    scroller() {
      document.documentElement.scrollTop = document.body.scrollTop = this.scrollOff + this.origTop; //document.body.scrollTop = this.scrollOff;
      this.scrollOff = 0;
    },
  }
}
</script>
<style scoped>
.left > div, .right > div {
  cursor: pointer;
}
.tip-div{
  display: flex;
  flex-direction: row;
  align-self: center;
}
.phcurrency-circle-dollar-fill {
  height: 33px;
  margin-left: 11px;
  width: 33px;
  align-self: center;
}
.text-green{
  color: #43A91F;
}
.recipe-msgs-cont {
  overflow: scroll;
}
</style>
