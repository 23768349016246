<template>
<div class="recipe-msgs-cont">
  <div v-if="!isMobile" class="recipe-msgs-cont-inner" ref="cont" :key="index">
    <div :class="`recipe-msgs recipe-cell recipe-msgexp-${expand}`" ref="msgs">
      <div v-if="!post.ignore_user" class="recipe-user-line" ref="userline">
        <UserLine :user="post.user">
        </UserLine>
      </div>
      <div class="action_buttons">
        <div class="post-overlap-group3" :key="post.like_state" @click.prevent="handleLike">
          <img class="icon" :src="static_fn(post.like_state ? 'img/ic_like_selected.png' : 'img/fluent-thumb-like-20-regular-1@2x.png')" alt="icon-thumbs_up"/>
          <div class="post-text-1 valign-post-text-middle inter-semi-bold-eerie-black-17px" >
            <span><span class="inter-semi-bold-eerie-black-17px"><span v-if="post.num_likes" @click.stop="showLike">{{post.num_likes}}</span> {{likestr}}</span> </span>
        </div>
        </div>
        <div class="post-overlap-group3" :key="post.make_state" @click.prevent="handleMake">
          <img class="icon" :src="static_fn(post.make_state ? 'img/ic_star_filled.png' : 'img/star-svgrepo-com--2--1-15@2x.png')" alt="icon-star"/>
          <div class="post-text-1 valign-post-text-middle inter-semi-bold-eerie-black-17px" >
            <span><span class="inter-semi-bold-eerie-black-17px">
                <span v-if="post.num_makes" @click.stop="showMake">{{post.num_makes}}</span> {{makestr}}</span> </span>
          </div>
        </div>
        <div class="post-overlap-group3" @click="handleShare">
          <img class="icon-share" :src="static_fn('img/ph-share-network-1@2x.png')" alt="icon-share" />
          <div class="post-text-9 valign-post-text-middle inter-semi-bold-eerie-black-17px">
            <span><span class="inter-semi-bold-eerie-black-17px">Share</span> </span>
          </div>
        </div>
        <div class="tip-div" v-if="user && post.user.gov_id_proof_status =='Approved' && post.user.accept_tip" @click="handleTip">
          <img
            class="phcurrency-circle-dollar-fill"
            src="../assets/ph-currency-circle-dollar-fill-4@2x.png"
            alt="ph:currency-circle-dollar-fill"
          />
          <div class="text-41 valign-text-middle inter-semi-bold-apple-17px">
            <span><span class="inter-bold-eerie-black-17px text-green">Send Tip</span> </span>
          </div>
        </div>
      </div>
      <div class="recipe-text" tabindex="0"> 
        <div v-if="ingredients">
          Ingredients
        </div>
        <div v-for="ingredient in post.ingredients" :key="ingredient">
          {{ingredient.amount}} {{ingredient.unit}} {{ingredient.material}}
        </div>
        <div v-if="steps">
          Steps
        </div>
        <div v-for="step in post.steps" :key="step">
          {{step.order}}. {{step.action}}
        </div>
        <h3 class post-recipe-title>{{post.title}} </h3>
        <div v-html="post.message">
        </div>
      </div>
      <div class="recipe-group-line" ref="creatime">
        <span>
          <time><b>{{post.created_at}}</b></time>
          <span v-if="post.group && post.group.name !=''">in <b><a @click="$router.push('/groups/'+post.group.slug)">{{post.group.name}}</a></b></span>
        </span>
        <div v-if="extras || !isMobile" v-bind:class="extrasZ">
          <div class="recipe-inter" v-if="user && user.username==post.user.username">
            <div class="recipe-inter-sym">
              <a :href="'/posts/delete/' + post.slug" class="btn btn-simple">
                <span class="glyphicon glyphicon-remove text-danger" ></span>
              </a>
            </div>
            <div class="recipe-inter-num">
              <span class="icon-label text-danger" >Delete Recipe</span>
            </div>
          </div>
          <div class="recipe-inter" v-if="user && user.username==post.user.username">
            <div class="recipe-inter-sym">
              <a :href="'/posts/dredit/' + post.pk" class="btn btn-simple">
                <span class="glyphicon glyphicon-pencil text-warning" ></span>
              </a>
            </div>
            <div class="recipe-inter-num">
              <span class="icon-label text-warning" >Edit Recipe</span>
            </div>
          </div>
          <div v-if="user && user.admin==true" class="post-overlap-group3"
               :key="post.featured" @click="handleFeature">
            <img class="icon" :src="static_fn(post.featured ? 'featured.jpg' : 'not_featured.jpg')"/>
          </div>

          <div class="recipe-inter" @click="handleReport">
            <div class="recipe-inter-sym">
              <a :href="'/report/' + post.user.username" class="btn btn-link" title="Report inappropriate comment">
                <img :src="static_fn('Flag.png')" height="25px" />
              </a>
            </div>
            <div class="recipe-inter-num">
              <span style="color:grey;">Report</span>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  <div v-else>
    <a v-if="!post.ignore_user" class="mobile-user-line" ref="userline" @click="showProfile">
      <div>
        <img :src="post.user.thumbnail" class="post-img"/>
      </div>
      <div>
        {{post.user.name}}
      </div>
    </a>
    <div class="recipe-vert" :key="index">
      <AsyncButton @toggle="handleLike" @show="showLike"
                   :image="post.like_state ? 'HEART.RED.png' : 'HEART.png'"
                   :count="post.num_likes" />
      <AsyncButton @toggle="handleMake" @show="showMake"
                   :image="post.make_state ? 'Wanna Make This.RED.png' : 'Wanna Make This.png'"
                   :count="post.num_makes" />
      <AsyncButton @toggle="handleShare"
                   image="share.png" />
      <AsyncButton @toggle="toggleExtras" image="dots.png" />
    </div>
  </div>
  
  <UserListModal v-if="selectedPost > 0" :context="userContext"
                 :title="userTitle" ref="modal"
                 :pk="selectedPost" @closed="onHideDetail"
                 @modalOut="onModalOut" :key="selectedPost" />
  <Share v-if="showShare" :category="category" :post="post" @closed="onHideDetail"/>
  <TipsModal v-if="showTip" :tipType="tipType" :user="post.user" @closed="onHideDetail"/>

</div>
</template>

<script>
/* eslint-disable no-console */
import UserLine from './UserLine';
import UserListModal from './UserListModal';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Share from './Share.vue';
import AsyncButton from './AsyncButton.vue';
import TipsModal from './TipsModal.vue';

const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });

export default {
  name: 'VideoDetail',
  components: {
    UserLine,
    UserListModal,
    Share,    
    TipsModal,
    AsyncButton,
  },
  props: {
    'posts': Array,
    'sizes': String,
    'height': Number,
    'width': Number,
    'index': Number,
    'category': String,
  },
  computed: {
    post: function() {
      var p = this.index < this.posts.length ? this.posts[this.index] : null;
      return p;
    },
    textHeight: {
      get: () => {
        return (this.post && this.post.comment_form) ? "70%" : "90%";
      }
    },
    user () {
      return this.getUserInfo()
    },
    likestr: function() {
      return this.post.num_likes > 1 ? "Likes" : "Like";
    },
    makestr: function() {
      return this.post.num_makes == 1 ? "Wants to Make" : "Want to Make";
    },
    isMobile: function() {
      return this.winWidth <= 600;
    },
    extrasZ: function() {
      return {
        'recipe-extras': true,
        'recipe-inters-full': true,
        'kshowing': this.extras,
        'khidden': !this.extras
        }
    },
  },
  data() {
    return {
      expand: false,
      collapsed: true,
      reload: true,
      commentHeight: "300px",
      showcomment: "false",
      winWidth: window.innerWidth,
      abase: 'posts',
      selectedPost:0,
      showShare: false,
      userContext: 'users-like',
      userTitle: 'People who like this',
      extras: false,      
      showTip:false,
      tipType:'tip',
    };
  },
  mounted() {
    this.$nextTick (function() {
      log.info ("PostDetail mounted " + this.posts.length + " index " + this.index); //.pk + " and " + this.post.video_id); //this.$parent.isFullscreen);
      this.handleUpdate();
      this.$emit("loaded", this.post.pk);
    });
  },
  beforeDestroy() {
  },
  updated() {
    this.$nextTick (function() {
      log.info ("PostDetail update ");
      this.handleUpdate();
    });
  },
  methods: {
    ...mapGetters(['getUserInfo']),
    ...mapMutations(['showLogin']),
    ...mapActions(['postOrLogin']),
    stop() {
      this.$refs.videoFile.stopVideo();
    },
    handleTip() {
      if(this.user.username!=this.post.user.username){
        this.tipType = 'tip';
        this.showTip = true;
      }else{
        this.$root.$bvToast.toast('You cannot tip yourself. Try tipping other awesome creators on Khal', {
          title: `Something went wrong`,
          variant: 'danger',
          autoHideDelay: 5000,
        })
      }
      
    },
    play() {
      this.$refs.videoFile.runVideo();
    },
    handleUpdate() {
      console.log ("Handle update called");
      if (this.$refs.cont) { 
        let style = getComputedStyle(this.$refs.cont);
        this.expand = (style.display == 'inline-block');
      }
      if (this.$refs.msgs && this.$refs.creatime && this.$refs.userline) {
        let msgHeight = this.$refs.msgs.clientHeight; //getComputedStyle(this.$refs.msgs).;
        let creaHeight = this.$refs.creatime.clientHeight;
        let userHeight = this.$refs.userline.clientHeight;
        this.textHeight = msgHeight - (creaHeight + userHeight + 2) + "px";
        this.commentHeight = ( msgHeight * 2 ) + "px";
      }
    },
    handleResize() {
      if (window.innerWidth != this.winWidth) {
        this.winWidth = window.innerWidth;
        this.reload = !this.reload;
      }
    },
    handleFeature() {
      if(this.user && this.user.admin) {
        this.postOrLogin(`posts/feature/toggle/${this.post.pk}`).then((data) => {
          this.post.featured = data.feed != null
        })
      }
    },
    handleLike() {
      this.postOrLogin(`posts/like/toggle/${this.post.pk}`).then((data) => {
        this.post.like_state = data.state;
        this.post.num_likes = data.count;
        if(data.state && this.user && this.post.user.gov_id_proof_status =='Approved' && this.post.user.accept_tip && this.user.username!=this.post.user.username){
          this.tipType = 'like';
          this.showTip = true;
        }
      })
    },
    handleShare() {
      this.showShare = true;
    },
    handleMake() {
      this.postOrLogin(`posts/make/toggle/${this.post.pk}`).then((data) => {
        this.post.make_state = data.state;
        this.post.num_makes = data.count;
        if(data.state && this.user && this.post.user.gov_id_proof_status =='Approved' && this.post.user.accept_tip && this.user.username!=this.post.user.username){
          this.tipType = 'make';
          this.showTip = true;
        }
      })
    },
    handleReport() {
      window.location.href = "/report/" + this.post.user.username;
    },
    showLike() {
      if (this.post.num_likes > 0)
      {
        this.selectedPost = this.post.pk;
        this.userContext = "users-like";
        this.userTitle = "People who like this";
      }
    },
    showMake() {
      if (this.post.num_makes > 0)
      {
        this.selectedPost = this.post.pk;
        this.userContext = "users-make";
        this.userTitle = "People who want to make this";
      }
    },
    onHideDetail() {
      this.selectedPost = 0;
      this.showShare = false;
      this.showTip = false;
    },
    onModalOut() { 
      setTimeout(this.scroller.bind(this), 100);
    },
    showProfile() {
      this.$router.push({name: `profile-detail`, params: {username: this.post.user.username} });
    },
    toggleExtras() {
      this.extras = !this.extras;
    }
  }
}
</script>
<style scoped>
.action_buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid black;
  padding: 4px;
}

.action_buttons div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.action_buttons img {
  width: 32px;
}

.recipe-msgs {
  display: flex;
  flex-direction: column;
}
.profile-follower-text-container{
  max-width: auto !important;
}

</style>
