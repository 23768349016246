<template>
  <div class="login modal show">
    <div class="modal-dialog global" role="document">
      <div class="modal-content global">
        <div class="modal-header d-flex align-center">
          <h5 class="modal-title inter-bold-black-25px">{{title}}</h5>
          <div class="overlap-group1-5" @click="handleClose">
            <div class="ellipse-54"></div>
            <img class="noun-cross-1707162-1" :src="static_fn('img/noun-cross-1707162-1-1@2x.png')" alt="noun-cross-1707162 1" />
          </div>
        </div>
        <div class="scrollView">
          <UserList :category="pk" :comp="UserLine" :numrows="1" :rowlen="4" :mrowlen="4" 
          framekls="profile-x1" :arrowMore="true" :isMobile="isMobile" :viewMore="true" ref="list" :urlp="{'app':'posts', 'view': 'list'}"/>
        </div>
      </div>
    </div>
  </div>
  </template>
<script>
import BaseModal from './BaseModal';
import UserList from './UserList.vue';
import { mapMutations } from 'vuex';

export default {
  name: "UserListModal",
  extends: BaseModal,
  props: [
    'title',
    'pk',
    'context',
  ],
  components: {
    UserList
  },
  data: () => {
    return {
      users: [],
      totalCount: 0,
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    ...mapMutations(["setViewContext"]),
    handleClose() {
      this.$emit("closed");
    }
  },
  created() {
    this.setViewContext(this.context);
    // var that = this;
    // this.api.get(this.url, this.params).then((page) => {
    //     console.log ("Items setting page " + page.count + " and " + page.numpages);
    //     that.count = page.count;
    //     that.users = page.data;
    //     that.nextPageURL = page.nextlink;
    //     that.previousPageURL = page.prevlink;
    //     that.numberOfItems = page.count;
    //     that.numberOfPages = page.numpages;
    //     that.postGetItems();
    //   }).catch (this.pageErr.bind(this));
  },
}
</script>
<style scoped>
.scrollView {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
