<template>
<div class="overlap-group-2 testing-cp" 
     ref="me">
  <div class="rectangle-34624099 rectangle"></div>
  <div class="rectangle-34624101 rectangle" :style="thumbnail"></div>
  <div class="avatar-2" :style="avatar"></div>
  <div class="text-7 valign-text-middle inter-bold-white-20px">
    <span><span class="inter-bold-white-20px preview-title">{{post.title}}
    </span> </span>
  </div>
  <div @click="showProfile" class="text-8 inter-normal-white-17px-2">
    <span class="inter-normal-white-17px-2">
      {{post.user.full_name}}
      <img v-if="post.user.gov_id_proof_status == 'Approved'" class="image-checkmark" src="../assets/mdi_check-decagram.png" />
    </span>
    
  </div>
  <img 
    class="multiple-svgrepo-com-1"
    :src="static_fn('img/multiple-svgrepo-com-1-1@2x.png')"
    alt="multiple-svgrepo-com 1"
    />
</div>
                
</template>
<script>
const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });


export default {
  name: 'PreQuickie',
  props: ['post'],
  components: {
  },
  computed: {
    videoStyle: function() {
      return {
        'preview': true,
        'preview-playing': this.pRunning,
        'preview-off': !this.pRunning
      }
    },
  },
  data() {
    return {
      thumbnail: "background-image: url(\"" + this.post.post_image.thumbnail + "\")",
      playImg: "",
      pRunning: false,
      width: 0,
      height: 0,
      isPlaying: false,
      hasPlayed: false,
      videoId: this.post.video_id,
      playerVars: {
        'autoplay': 1,
        'rel': 0,
        'enablejsapi': 1,
        'controls': 0,
        'disablekb': 1,
        'fs': 0,
        'modestbranding': 1,
        'cc_load_policy': 0,
        'origin': document.location.origin
      },
      rates: [],
      avatar: "background-image: url(\"" + this.post.user.thumbnail + "\"), url(\"static/img/default-avatar.png\")",
    }
  },
  updated() {
    log.info ("Updating prevideo " + this.width + " x " + this.height);
    this.$nextTick(function() {
      /*var that = this;
      this.$refs.youtube.player.mute().then(() => {
        that.$refs.youtube.player.getAvailablePlaybackRates().then (data => {
          let rate = Math.max.apply(null, data);
          that.$refs.youtube.player.setPlaybackRate(rate).then(() => {
            that.playPreview();
          })
      })*/
    });
  },
  mounted() {
    log.info ("Mounting preyoutube " + this.width + " x " + this.height);
    var that = this;
    this.api.map_prom.then(() => {
      that.width = that.$refs.me.clientWidth;
      that.height = that.$refs.me.clientHeight;
    });
  },
  methods: {
    __playPreview() {
      var that = this;
      if (this.hasPlayed) {
        this.$refs.youtube.player.playVideo().then(() => {
          that.isPlaying = true;
        });
      }
      else if (!this.isPlaying) {
        this.hasPlayed = true;
      }
    },
    showProfile(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$router.push({name: `profile-detail`, params: {username: this.post.user.username, fullname: this.post.user.full_name} });
    },
    playPreview() {
      if (this.width) { 
        this.pRunning = true;
        //this.__playPreview();
        //setTimeout (this.__reset.bind(this), 10000);
      }
    },
    __reset() {
      var that = this;
      that.$refs.youtube.player.seekTo(0, true).then(function(){
        that.playPreview();
      });
    },
    __stopPreview() {
      if (this.hasPlayed) {
        var that = this;
        this.$refs.youtube.player.stopVideo().then(() => {
          that.isPlaying = false;
        })
      }
    },
    stopPreview() {
      if (this.width) { 
        this.pRunning = false;
        //this.__stopPreview();
      }
    }
  }
}
</script>
