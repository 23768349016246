<script>
export default {
  name: 'BaseModal',
  mounted() {
    window.addEventListener('keyup', this.handleKey);
  },
  beforeDestroy() { 
    window.removeEventListener('keyup', this.handleKey);
  },
  methods: {
    handleKey (event) {
      switch (event.keyCode) {
      case 27:
        this.hideModal();
        break;
      }
    },
    hideModal () {
      this.$router.go(-1);
    },
  }
}  
</script>
