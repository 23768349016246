<template>
<div class="profile-body">
  <Back/>
    <div class="profile-overlap-group6">
      <header class="profile-header">
        <div class="profile-flex-col">
          <div class="profile-text-4 valign-profile-text-middle inter-bold-eerie-black-40px">
            <span><span class="inter-bold-eerie-black-40px">Chefs I Follow</span> </span>
          </div>
        </div>
      </header>
      <div class="profile-flex-row-1 my-followed">
        <UserList v-if="user" :category="user.username"
                  :comp='usrline'
                  numrows=10 mrowlen=4 rowlen=4 framekls="profile-x1"
                  arrowMore="false" viewMore="false"
                  />
      </div>
    </div>
</div>
</template>
<script>
/* eslint-disable no-console */

import UserLine from './UserLine.vue';
import UserList from './UserList.vue';
import Back from './Back.vue';
import { mapMutations, mapGetters } from "vuex";

export default {
  name: 'MyFollowedBy',
  components: {
    UserList,
    Back
  },
  data () {
    return {
      user: null,
    }
  },
  created: function() {
    this.setViewContext('followed-by');
    this.user = this.getUserInfo
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
    usrline: () => UserLine,
  },
  methods:{
    ...mapMutations(["setViewContext"]),
  }
}
</script>
<style>
  .profile-follower-text-container {
  max-width: 150px;
  overflow: hidden;
  }

  .my-followed div > .profile-follower-user-1 {
  max-width: 400px !important;
  overflow: hidden !important;
  min-height: unset !important;
  }
</style>
