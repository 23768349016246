<template>
  <div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapGetters } from "vuex";

export default {
  name: 'ItemList',
  apiItems: null,
  props: ['urlp', 'rparams', 'apiurl'],
  components: {
  },
  data() {
    return {
      items: [],
      numberOfPages:0,
      numberOfItems:0,
      loading: null,
      nextPageURL:'',
      previousPageURL:'',
      intersectionOptions: {
        root: null,
        //rootMargin: '0px 0px 0px 0px',
        threshold: .25 
      },
      url: null,
      params: {},
      count: 0,
    };
  },
  created() {
    this.setupURL();
    this.$watch(
      () => this.$route.params,
      () => {
        this.setupURL();
        this.getInitial();
      },
      { immediate: true,
        deep: true
      }
    )
  },
  methods: {
    ...mapGetters(["view_context"]),
    addURLParams() {
    },
    setupURL() {
      let ctx = this.view_context();
      if (this.apiurl)
      {
        this.url = `/api/${[this.apiurl].join('/')}/`;
      }
      else {
        if (this.ignoreCategory)
        {
          if (ctx == 'search')
          {
            this.url = `/api/${[this.urlp.app, ctx].join('/')}/`;
          }
          else
          {
            this.url = `/api/${[this.urlp.app, ctx, this.urlp.view].join('/')}/`;
          }
        }
        else
          this.url = `/api/${[this.urlp.app, ctx, this.urlp.view, this.category].join('/')}/`;
      }
      //if (this.rparams) {
      this.params = {
        ...(true) && this.$route.query,
        ...(true) && this.rparams
      }
      //}
      this.addURLParams();
    },
    postUp() {
    },
    postDown() {
    },
    onWaypointUp ({ direction }) {
      if (!this.loading) { 
        if (direction === this.$waypointMap.DIRECTION_BOTTOM &&
            this.prevPageURL != '') {
          this.loading = 'L';
          //this.getPreviousPage();
        }
      }
    },
    onWaypointDown ({ going }) {
      if (!this.loading) {
        if (going == this.$waypointMap.GOING_IN && 
            this.nextPageURL != '') {
          this.loading = 'L';
          this.getNextPage();
        }
      }
    },
    postGetItems() { 
      this.loading = null;
    },
    getInitial(){
      this.loading = 'L';
      var that = this;
      this.api.get(this.url, this.params).then((page) => {
        console.log ("Items setting page " + page.count + " and " + page.numpages);
        that.count = page.count;
        that.items = page.data;
        that.nextPageURL = page.nextlink;
        that.previousPageURL = page.prevlink;
        that.numberOfItems = page.count;
        that.numberOfPages = page.numpages;
        that.postGetItems();
      }).catch (this.pageErr.bind(this));
    },
    pageErr(err) {
      console.log ("getPage " + err.code + " " + err.message);
      this.loading = 'E';
    },
    getPreviousPage(){
      this.loading = 'L';
      var that = this;
      this.api.get(this.previousPageURL).then((page) => {
        that.items = page.data.concat(that.items);
        that.previousPageURL = page.prevlink;
        that.loading = null;
        that.postUp();
      }).catch (this.pageErr.bind(this));
    },
    getNextPage(){
      this.loading = 'L';
      var that = this;
      this.api.get(this.nextPageURL).then((page) => {
        that.items = that.items.concat(page.data);
        that.nextPageURL = page.nextlink;
        that.loading = null;
        that.postDown();
      }).catch (this.pageErr.bind(this));
    },
  },
}
</script>
<style scoped>
</style>
