<template>
<keep-alive>
  <youtube v-if="hasPlayed" :video-id="videoId" ref="youtube" 
           :width="width" :height="height"
           :player-vars="playerVars" :fitParent="true">
  </youtube>
</keep-alive>
</template>
<script>
const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });

export default {
  name: 'PreVideo',
  props: [
    'post',
    'width',
    'height',
  ],
  data() {
    return {
      'isPlaying': false,
      'hasPlayed': false,
      'videoId': this.post.video_id,
      'playerVars': {
        'autoplay': 1,
        'rel': 0,
        'enablejsapi': 1,
        'controls': 0,
        'disablekb': 1,
        'fs': 0,
        'modestbranding': 1,
        'cc_load_policy': 0,
        'origin': document.location.origin
      },
      'rates': [],
      'duration': 0,
      'count': 1,
      'userState': 0,  // Not prepared, not playing
    };
  },
  updated() {
    log.info ("Updating prevideo " + this.width + " x " + this.height);
    this.$nextTick(function() {
      var that = this;
      this.$refs.youtube.player.mute().then(() => {
        that.$refs.youtube.player.getAvailablePlaybackRates().then (data => {
          let rate = Math.max.apply(null, data);
          that.$refs.youtube.player.setPlaybackRate(rate).then(() => {
            that.playPreview();
          })/*.catch(err => {
            //that.$refs.youtube.player.playVideo();
          });*/
        })/*.catch (err => {
          //that.$refs.youtube.player.playVideo();
        });*/
      })
    });
  },
  mounted() {
    log.info ("Mounting prevideo " + this.width + " x " + this.height);
  },
  methods: {
    playPreview() {
      var that = this;
      if (this.hasPlayed) {
        this.$refs.youtube.player.playVideo().then(() => {
          that.isPlaying = true;
        });
      }
      else if (!this.isPlaying) {
        this.hasPlayed = true;
      }
    },
    stopPreview() {
      if (this.hasPlayed) {
        var that = this;
        this.$refs.youtube.player.stopVideo().then(() => {
          that.isPlaying = false;
        })
      }
    },
    reset() {
      var that = this;
      that.$refs.youtube.player.seekTo(0, true).then(function(){
        that.playPreview();
      });
    }
  }
}
</script>
<style>
</style>
