<template>
<span>
  <div class="profile-second_sidebar mobile-hide">
    <div class="profile-frame-427319003 frame">
      <!-- read them all just to get tab_cat 
           members, 
        -->
      <div v-for="choice in choices" :key="choice.tab">
        <SubMenu v-if="choice.summary"
                 :summary="choice.summary" :title="choice.title"
                 :img="choice.img" 
                 :which="choice.which"
                 :routeName="choice.routeName"
                 :tab="choice.tab">
        </SubMenu>
        <a v-else-if="choice.hideTab" ></a>
        <a v-else :class="'profile-overlap-group1 ' + active_class(choice.tab)"
           @click="tab_cat=choice.tab + '/any'">
          <img :src="static_fn(choice.img)">
          <div class="profile-text-10 valign-profile-text-middle inter-semi-bold-eerie-black-17px">
            <span><span class="inter-normal-eerie-black-17px">
                {{choice.title}}
            </span></span>
          </div>
        </a>        
      </div>
    </div>
  </div>
  <div class="profile-overlap-group11 mobile-show">
    <select class="profile-tabs" v-model="tab_cat">
      <template v-for="choice in choices">
        <optgroup v-if="choice.summary" :label="choice.title"
                  :key="choice.tab">
          <option v-for="entry in getEntries(choice)" :key="entry.value"
                  :value="entry.value"> {{entry.title}} ({{entry.count}})
          </option>
        </optgroup>
        <option v-else :value="choice.tab + '/any'" :key="choice.tab">{{choice.title}}
        </option>
      </template>
    </select>        
  </div>
</span>
</template>
<script>
import SubMenu from './SubMenu';

/* eslint-disable no-console */
export default {
  name: 'RouteMenu',
  props: [
    'tab_cat',
    'choices',
  ],
  components: {
    SubMenu
  },
  computed: {
  },
  created() {
    console.log ("Tab cat on routemenu creation " + this.tab_cat + " Tabs " + this.choices.length);
    this.$watch(
      () => this.tab_cat,
      () => { 
        let arr = this.tab_cat.split('/');
        this.tab = arr[0];
        this.cat = arr[1];
        if (this.tab) { 
          const params = {...this.$router.params,
                          tab: this.tab,
                          cat: this.cat
                         }
          this.$router.push({ name: this.$router.name,
                              params: params}).catch((err) => {
                                if (err.name !== 'NavigationDuplicated' &&
                                    !err.message.includes('Avoided redundant navigation to current location')
                                   ) {
                                  console.log(err);
                                }
                                else {
                                  console.log("Redundant: " + this.$route.path);
                                }
                              });
        }
      },
      {immediate: false}
    );
  },
  methods: {
    active_class(kls) {
      return `${kls}/any` == this.tab_cat ? 'active' : '';
    },
    getEntries(choice) { 
      return [
        {
          'value': `${choice.tab}/photo`,
          'count': choice.summary.num_photos,
          'title': 'Photo Recipes',
        },
        {
          'value': `${choice.tab}/video`,
          'count': choice.summary.num_videos,
          'title': 'YouTube Recipes',
        },
        {
          'value':`${choice.tab}/quickie`,
          'count': choice.summary.num_quickies,
          'title': 'Quickies',
        },
      ]
    },
  }
}
</script>
