<template>
<div class="frame-427318981-item frame-427318981" >
  <div class="overlap-group-9" :style="thumbnail"
      @mouseover="playPreview" @mouseleave="stopPreview" ref="me">
    <div class="rectangle-34624102 rectangle"></div>
    <img class="g7433 g7433-2" :src="static_fn('img/g7433-1@2x.png')" alt="g7433"/>
    <div :class="videoStyle" :key="show">
      <youtube v-if="show" :video-id="videoId" ref="youtube" video-title=""
               :width="width" :height="height"
               :player-vars="playerVars" :fitParent="true"
               :events="playerEvents" >
      </youtube>
    </div>
  </div>
  <div class="text-2 valign-text-middle inter-bold-eerie-black-20px">
    <span><span class="inter-bold-eerie-black-20px preview-title">{{post.title}}
    </span></span>
  </div>
  <p class="text-3 valign-text-middle inter-normal-eerie-black-17px">
    <span>
      <span class="inter-normal-eerie-black-17px">
        <KTruncate :inhtml="post.message" :stripped="post.stripped" :nomore="true" length="60">
        </KTruncate>
      </span>
    </span>
  </p>
  <div class="flex-row-4">
    <div @click="showProfile" style="display: contents;">
      <div class="avatar-10" :style="avatar"></div>
      <div class="text-4 inter-normal-eerie-black-17px-2">
        <span class="inter-normal-eerie-black-17px-2">
          {{post.user.full_name}}
          <img v-if="post.user.gov_id_proof_status == 'Approved'" class="image-checkmark" src="../assets/mdi_check-decagram.png" />
        </span>
      </div>
    </div>
  </div>
</div>
</template>
<script>
const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });

import KTruncate from './KTruncate';

export default {
  name: 'PreYoutube',
  props: ['post'],
  components: {
    KTruncate,
  },
  computed: {
    videoStyle: function() {
      return {
        'preview': true,
        'preview-playing': this.show, 
        'preview-off': !this.show
      }
    },
  },
  data() {
    return {
      thumbnail: "background-image: url(\"" + this.post.post_image.thumbnail + "\")",
      show: false,
      playing: false,
      ready: false,
      width: 0,
      height: 0,
      videoId: this.post.video_id,
      playerVars: {
        'autoplay': 0,
        'rel': 0,
        'enablejsapi': 1,
        'controls': 0,
        'disablekb': 1,
        'fs': 0,
        'modestbranding': 1,
        'showinfo': 0,
        'cc_load_policy': 0,
        'origin': window.location.origin
      },
      rates: [],
      avatar: "background-image: url(\"" + this.post.user.thumbnail + "\"), url(\"static/img/default-avatar.png\")",
      timeout: null
    }
  },
  updated() {
    log.info ("Updating prevideo " + this.width + " x " + this.height);
    var that = this;
    this.$nextTick(function() {
      log.info ("UNT " + this.show + " and " + this.ready );
      if (that.show) {
        if (!that.ready) {
          log.info ("Muting " + that.$refs.youtube.player.mute);
          that.ready = true;
          that.$refs.youtube.player.mute().then(() => {
            log.info ("Muted");
            that.$refs.youtube.player.getAvailablePlaybackRates().then (data => {
              let rate = Math.max.apply(null, data);
              that.$refs.youtube.player.setPlaybackRate(rate).then(() => {
                that.ready = true;
                log.info ("Set playback rate " + that.ready); 
                that.playPreview();
              })
            })
          }).catch(err => {
            log.info ("Error on mute " + err);
          });
        }
      }
    });
  },
  mounted() {
    log.info ("Mounting preyoutube " + this.width + " x " + this.height + " origin " + this.playerVars.origin);
    var that = this;
    this.api.map_prom.then(() => {
      that.width = that.$refs.me.clientWidth;
      that.height = that.$refs.me.clientHeight;
      log.info ("Width is " + that.width);
    });

    // $('iframe')[0].on('load', function(){
    //   $(this).contents().find("body").on('click', function(event) { alert('test'); });
    // });
  },
  methods: {
    __playPreview() {
      return this.$refs.youtube.player.playVideo();
    },
    
    showProfile(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$router.push({name: `profile-detail`, params: {username: this.post.user.username, fullname: this.post.user.full_name} });
    },
    playPreview() {
      var that = this;

      log.info ("Ready " + that.ready + " Show: " + that.show);
      this.show = true;
      if (this.ready) {
        this.__playPreview().then(() => {
          if (that.timeout)
            clearTimeout(that.timeout);
          that.playing = true;
          that.timeout = setTimeout (that.__reset.bind(that), 10000);          
        });
      }
      else {
        log.info(" If we're not ready, what do we do?");
      }
    },
    __reset() {
      var that = this;
      that.$refs.youtube.player.seekTo(0, true).then(function(){
        log.info ("Reset calling play preview");
        that.playPreview();
      });
    },
    __stopPreview() {
      log.info ("__Stopped preview");
      return this.$refs.youtube.player.stopVideo();
    },
    stopPreview() {
      if (this.timeout) 
        clearTimeout(this.timeout);
      if (this.playing) { 
        var that = this;
        this.__stopPreview().then(() => {
          log.info ("Stopped preview");
          that.playing = false;
        })
      }
      this.show = false;
      this.ready = false;
    },
    showDetailView() {
      // $('.frame-427318981-item').click();
    },
  }
}
</script>

<style scoped>
.rectangle {
    z-index: 10;
}
.frame-427318981-item{
  margin: 0px !important;
}
</style>
