<template>
  <div class="sidemenu" :key="bReload">
    <div class="overlap-group6">
      <div class="group-427318973">
        <img class="mobile-show" style="width: 24px; height: 24px; align-self: center; margin-left: 8px;" :src="static_fn('img/noun-cross-1707162-1-1@2x.png')" @click="hideSideMenu"/>
      </div>
      <img class="line-205" :src="static_fn('img/line-204-7@2x.png')" />
      <div class="x1-overlap-group6">
        <div v-if="!user" @click="showLogin" class="group-427318968-x1">
          <img class="home-2" :src="static_fn('img/ic_login.png')" />
          <div class="text-18 valign-text-middle inter-normal-black-17px">
            <span>Login</span>
          </div>
        </div>
        <a v-if="!user" href="/accounts/signup" class="group-427318968-x1">
          <img class="home-2" :src="static_fn('img/ic_signup.png')" />
          <div class="text-18 valign-text-middle inter-normal-black-17px">
            <span>Sign Up</span>
          </div>
        </a>
        <div class="group-427318968-x1 comment-action" @click="aboutus">
          <img class="home-2" :src="static_fn('img/ic_about.png')" />
          <div class="text-18 valign-text-middle inter-normal-black-17px">
            <span>About us</span>
          </div>
        </div>
        <div class="group-427318968-x1 comment-action" @click="howto">
          <img class="home-2" :src="static_fn('img/ic_howto.png')" />
          <div class="text-18 valign-text-middle inter-normal-black-17px">
            <span>How it works</span>
          </div>
        </div>
        <a v-if="user" :href="contact" class="group-427318968-x1">
          <img class="home-2" :src="static_fn('img/ic_contact.png')" />
          <div class="text-18 valign-text-middle inter-normal-black-17px">
            <span>Contact us</span>
          </div>
        </a>
        <a v-if="user" href="/accounts/logout" class="group-427318968-x1">
          <img class="home-2" :src="static_fn('img/ic_signup.png')" />
          <div class="text-18 valign-text-middle inter-normal-black-17px">
            <span>Sign Out</span>
          </div>
        </a>
      </div>
    </div>
  </div>        
</template>

<script>
/* eslint-disable no-console*/
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'SideMenu',
  components: {
  },
  props: ['hideSideMenu'],
  computed: {
    user () {
      return this.getUserInfo()
    },
    badge: function () {
      return this.static_fn(this.user.full_badge_image || 'img/image-2-2@2x.png');
    },
    contact () {
      if (this.user)
        return `/contact/${this.user.username}/help_request`;
      else
        return `/contact/`;
    }
  },
  methods: {
    ...mapGetters(['getUserInfo']),
    ...mapMutations(['showLogin']),
    goHome() {
      this.$router.push("/");
    },
    howto() {
      this.$router.push({name: 'howto', params: {lang: 'en'}});
    },
    aboutus() {
      this.$router.push({name: 'aboutus'});
    },
  }
}

</script>
