<template>
  <div class="VueCarousel2-navigation">
    <button
      type="button"
      aria-label="Previous page"
      :tabindex="canAdvanceBackward ? 0 : -1"
      class="VueCarousel2-navigation-button VueCarousel2-navigation-prev"
      v-on:click.prevent="triggerPageAdvance('backward')"
      v-bind:class="{ 'VueCarousel2-navigation--disabled': !canAdvanceBackward }"
      v-bind:style="`margin-right: -${clickTargetSize}px;`"
      v-html="prevLabel"></button>
    <button
      type="button"
      aria-label="Next page"
      :tabindex="canAdvanceForward ? 0 : -1"
      class="VueCarousel2-navigation-button VueCarousel2-navigation-next"
      v-on:click.prevent="triggerPageAdvance('forward')"
      v-bind:class="{ 'VueCarousel2-navigation--disabled': !canAdvanceForward }"
      v-bind:style="`margin-left: -${clickTargetSize}px;`"
      v-html="nextLabel"></button>
  </div>
</template>

<script>
export default {
  name: "inset-navigation",
  inject: ["carousel"],
  props: {
    /**
     * Amount of padding to apply around the label in pixels
     */
    clickTargetSize: {
      type: Number,
      default: 8
    },
    lrSize :{
      type: Number,
      default: 20
    },
    /**
     * Text content of the navigation next button
     */
    nextLabel: {
      type: String,
      default: "&#8250" //"&#9654"
    },
    /**
     * Text content of the navigation prev button
     */
    prevLabel: {
      type: String,
      default: "&#8249" //"&#9664"
    },
    'forceBack': {
      type: Boolean,
      default: false
    },
    'forceFront': {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * @return {Boolean} Can the slider move forward?
     */
    canAdvanceForward() {
      return this.carousel.canAdvanceForward || this.forceFront;
    },
    /**
     * @return {Boolean} Can the slider move backward?
     */
    canAdvanceBackward() {
      return this.carousel.canAdvanceBackward || this.forceBack;
    }
  },
  methods: {
    /**
     * Trigger page change on +/- 1 depending on the direction
     * @param {"backward"} [direction]
     * @return {void}
     */
    triggerPageAdvance(direction) {
      /**
       * @event paginationclick
       * @type {string}
       */
      
      //this.$emit("navigationclick", direction);
      this.carousel.handleNavigation(direction);
    }
  }
};
</script>

<style scoped>
  #videoModal .VueCarousel2-navigation-next {
    right: -20px !important;
}
.VueCarousel2-navigation-next {
  right: 40px !important;
  transform: translateY(-50%) translateX(100%);
  font-family: "system";
}
.VueCarousel2-navigation-prev {
  left: 40px;
  transform: translateY(-50%) translateX(-100%);
  font-family: "system";
}
#videoModal .VueCarousel2-navigation-prev {
    left: -20px !important;
}
</style>
