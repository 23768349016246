<template>
<div class="login modal show">
  <div class="modal-dialog global" role="document" ref="reference">
    <div class="modal-content global">
      <div class="modal-header d-flex align-center">
        <h5 class="modal-title inter-bold-black-25px">Share this recipe</h5>
        <div class="overlap-group1-5" @click="handleClose">
          <div class="ellipse-54"></div>
          <img class="noun-cross-1707162-1" :src="static_fn('img/noun-cross-1707162-1-1@2x.png')" alt="noun-cross-1707162 1" />
        </div>
      </div>
      <div class="share_content">
        <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(shareUrl)" target="_blank">
          <img :src="static_fn('img/ic_share_facebook.png')" alt="facebook"/>
        </a>
        <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent(shareUrl)" target="_blank">
          <img :src="static_fn('img/ic_share_whatsapp.png')" alt="whatsapp"/>
        </a>
        <div @click="copyClipboard" class="comment-action">
          <img :src="static_fn(copyState ? 'img/ic_clipboard_selected.png' : 'img/ic_clipboard.png')" alt="clipboard"/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
/* eslint-disable no-console */
import { mapGetters } from 'vuex';
import BaseModal from './BaseModal.vue';

export default {
  name: "Share",
  extends: BaseModal,
  props: [
    'post',
    'category'
  ],
  data: () => {
    return {
      copyState: false,
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
    shareUrl() {
      console.log(this.category);
        let path = window.location.origin + window.location.pathname + this.$router.resolve({name: 'recipe', params: {username: this.post.user.username, slug: this.post.slug}}).href;
        /*if(path.search('youtube') > 0)
        {
          path = path.split('httpsyoutubecomshorts')[1];
          path = path.replace('featureshare', '?feature=share');
          path = 'https://youtube.com/shorts/' + path;
        } */       
        return path;//encodeURIComponent(path);
    },
  },
  methods: {
    ...mapGetters(["view_context"]),

    copyClipboard() {
      const storage = document.createElement('textarea');
      storage.value = this.shareUrl;
      this.$refs.reference.appendChild(storage);
      storage.select();
      storage.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.$refs.reference.removeChild(storage);
      this.copyState = true;
    },
    handleClose() {
      this.$emit("closed");
    }
  }
}
</script>
<style scoped>
.share_content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 36px;
}

.share_content img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  padding: 4px;
}
</style>
