<template>
<div>
  <div ref="me" :class="postListClass" :style="`top: -${scrollOff}px`">
    <Loading :loading="loading == 'L'" :loaderror="loading =='E'"></Loading>
    <table class="table">
      <tbody>
        <tr v-for="post in posts" :key="post">
          <td> 
            <div class="post-grid proof-grid" v-if="post.post_image != null"  ref="postgrid">
              <div class="proof-orig">
                <PreVideo v-if="post.video_id" :post="post" :sizes="sizes" @clicked="selectPost(post.pk)">
                </PreVideo>
                <PreVideoFile v-else-if="post.video_file" :post="post" :sizes="sizes" @clicked="selectPost(post.pk)">
                </PreVideoFile>
                <PreImage v-else :post="post" :sizes="sizes" @clicked="selectPost(post.pk)">
                </PreImage>
              </div>
              <div class="proof-imgs">
                <img v-if="post.proof_img" :src="post.proof_img.image" 
                     :srcset="post.proof_img.thumbs"
                     :sizes="sizes"
                     style="object-fit: cover;"
                     />
                <img v-if="post.proof_dish" :src="post.proof_dish.image" 
                     :srcset="post.proof_dish.thumbs"
                     :sizes="sizes"
                     style="object-fit: cover;"
                     />
              </div>
              <div class="post-title" @click="selectPost(post.pk)"> 
                <KTruncate :inhtml="post.title" :stripped="post.title" :nomore="true" length="30"></KTruncate>
                </div>
                <div class="post-user"> 
                  by <a :href="post.user.url">{{post.user.full_name}}</a> 
              </div>
              <div class="post-synopsis"> 
                <KTruncate :inhtml="post.message" :stripped="post.stripped" :nomore="true" length="60">
                </KTruncate>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="way-container" v-if="nextPageURL !== '' || loading == 'E'">
      <Loading :loading="'L'" :key="loading" :loaderror="loading == 'E'" class="way-div" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" id="More">
      </Loading>
    </div>
  </div>
  <PostModal v-if="selectedPost > 0" ref="modal" :pk="selectedPost" 
             @closed="onHideDetail" @modalOut="onModalOut" :key="selectedPost"></PostModal>
</div>
</template>

<script>
/* eslint-disable no-console */
import Loading from './Loading';
import PostModal from './PostModal';
import KTruncate from './KTruncate';
import PreVideo from  './PreVideo';
import PreVideoFile from  './PreVideoFile';
import PreImage from  './PreImage';

const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });

const getOffsetTop = element => {
  let offsetTop = 0;
  while(element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}


export default {
  name: 'MadeList',
  components: {
    Loading,
    PostModal,
    KTruncate,
    PreVideo,
    PreVideoFile,
    PreImage
  },
  data() {
    return {
      scrollOff: 0,
      origTop: null,
      postListClass: "post-scroll",
      selectedPost:0,
      posts: [],
      numberOfPages:0,
      pages : [],
      numberOfPosts:0,
      loading: null, //false,

      nextPageURL:'',
      previousPageURL:'',
      size:'',
      winWidth: window.innerWidth,
      intersectionOptions: {
        root: null,
        //rootMargin: '0px 0px 0px 0px',
        threshold: [0.25, 0.75] // [0.25, 0.75] if you want a 25% offset!
      },
      divisor: 4,
      modalOpen: false,
    };
  },
  watch: {
    pk: function (newpk, oldpk) {
      log.info ("Change from " + oldpk + " to " + newpk);
    }
  },
  methods: {
    onWaypoint ({ going, direction }) {
      if (!this.loading) { 
        // going: in, out
        // direction: top, right, bottom, left
        if (going === this.$waypointMap.GOING_IN &&
            direction === this.$waypointMap.DIRECTION_TOP &&
            this.nextPageURL != '') {
          log.info ("Waypoint " + this.nextPageURL + " ??");
          this.loading = 'L'; //true;
          this.api.getItemsByURL(this.nextPageURL).then((page) => {
            this.posts = this.posts.concat(page.data);//	this.posts = page.data;
            this.nextPageURL = page.nextlink;
            this.previousPageURL = page.prevlink;
            this.loading = null; //false;
          }).catch (err => {
            log.info ("Waypoint err " + err.code + " " + err.message);
            this.loading = 'E';
          });
        }
      }
    },
    
    breakupPosts(data) {
      let posts = [];
      for (let i = 0; i < data.length; i++) {
        let row = parseInt(i / this.divisor);
        if (row >= posts.length)
          posts.push([]);
        posts[parseInt(i / this.divisor)].push(data[i]);
      }
      return posts;
      /*this.posts = page.data.reduce((acc, k) => {
          acc[parseInt(k/3)].push(page.data[k])
          return acc;
          }, {});*/
    },
    
    getPosts(){
      if (this.origTop == null) {
        this.origTop = getOffsetTop(this.$refs.me);
      }
      this.loading = 'L'; //true;    
      this.api.getFeedPosts().then((page) => {
        this.setPage(page);
        this.numberOfPosts = page.count;
        this.numberOfPages = page.numpages;
        if(this.numberOfPages) {
          for(var i = 1 ; i <= this.numberOfPages ; i++) {
            const link = `/api/posts/?page=${i}`;
            this.pages.push({pageNumber: i , link: link})
          }
        }
      }).catch (err => {
        log.info ("Final status* " + err.code + " " + err.message);
        this.loading = 'E';
      });
    },
    getPage(link){
      this.loading = 'L'; //true;  
      this.api.getItemsByURL(link).then((page) => {
        this.setPage(page);
      }).catch (err => {
        log.info ("GetPage err " + err.code + " " + err.message);
        this.loading = 'E';
      });
    },
    getNextPage(){
      this.loading = 'L'; // true;  
      this.api.getItemsByURL(this.nextPageURL).then((page) => {
        this.setPage(page);
      }).catch (err => {
        log.info ("GetNextPage err " + err.code + " " + err.message);
        this.loading = 'E';
      });
    },
    setPage(page) {
	this.posts = page.data; //this.breakupPosts(page.data);//this.posts = page.data;
	this.nextPageURL = page.nextlink;
	this.previousPageURL = page.prevlink;
        this.sizes = page.sizes;
	this.loading = null; //false;
    },
    getPreviousPage(){
      this.loading = 'L'; // true;  
      this.api.getItemsByURL(this.previousPageURL).then((page) => {
        this.setPage(page);
      }).catch (err => {
        log.info ("Get Prev err " + err.code + " " + err.message);
        this.loading = 'E';
      });
      
    },
    onHideDetail() {
      this.postListClass = "post-scroll";
      let sides = document.getElementsByClassName ("tofix");
      for (let i = 0; i < sides.length; i++) {
        sides[i].classList.remove ("postbg-fixed");        
      }
      log.info ("On hide detail " + this.selectedPost);
      this.selectedPost = 0;
    },
    onModalOut() { 
      setTimeout(this.scroller.bind(this), 100);
    },
    
    scroller() {
      document.documentElement.scrollTop = document.body.scrollTop = this.scrollOff + this.origTop; //document.body.scrollTop = this.scrollOff;
      this.scrollOff = 0;
    },
    selectPost(post) {
      log.info ("Select post called " + post );
      this.selectedPost = post;
      this.modalOpen = true;
      this.scrollOff = (window.pageYOffset || document.documentElement.scrollTop) - this.origTop;
      this.postListClass = "post-fixed";
      let sides = document.querySelectorAll ("tofix");
      for (let i = 0; i < sides.length; i++) {
        sides[i].classList.add ("postbg-fixed");
      }
      log.info ("Select post done " + this.selectedPost);
    },
    getDivisor() {
      let u600 = window.matchMedia('(min-width: 600px)');
      let u900 = window.matchMedia('(min-width: 900px)');
      this.divisor = u600.matches ? (u900.matches ? 4 : 3) : 2;
    },
    handleResize() {
      if (window.innerWidth != this.winWidth) {
        this.winWidth = window.innerWidth;
        this.getDivisor();
        //this.posts = this.breakupPosts(this.posts.flat());
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  updated() {
  },
  mounted() {
    this.getPosts();
    this.getDivisor();
    window.addEventListener('resize', this.handleResize);
  },
}
</script>
<style scoped>
.table>tbody>tr>td {
    border-top: transparent;
}

.post-fixed {
    position: fixed;
    z-index: -1;
    opacity: 0; 
}

#More {
    -height: 100px;
}
</style>
