<template>
  <div class="vid-cont">
    <div class="thumb-place">
      <PreImage :post="post" :sizes="sizes"></PreImage>
    </div>
    <div v-if="isTouch == false" @mouseover="runVideo" @click="emitClick" v-bind:class="thumbZ">
      <img ref="pimage" :src="post.post_image.image" 
           :srcset="post.post_image.thumbs"
           :sizes="sizes" style="object-fit: cover;"/>
      <img v-if="playImg" class="pre-play-button" :src="playImg" :key="playImg"/>
    </div>
    <div v-else @click="emitClick" v-bind:class="thumbZ">
      <img ref="pimage" :src="post.post_image.image" 
           :srcset="post.post_image.thumbs"
           :sizes="sizes" style="object-fit: cover;"/>
      <img v-if="playImg" class="pre-play-button" :src="playImg" :key="playImg"/>
    </div>
    <div v-if="iReady" class="player-cont" :key="iReady" @click="emitClick">
      <video ref="video"
             class="vjs-default-skin vjs-fill"
             :width=width :height=height
             style="background: black"
             :options="options">
      </video>
    </div>
    <div v-if="isTouch == false" class="player-cont player-trans" @mouseout="pausePreview" @click="emitClick">
    </div>
    <div v-else class="player-cont player-trans" @click="emitClick">
    </div>
  </div>
</template>
<script>

import PreImage from './PreImage';
import videojs from 'video.js';
const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });

export default {
  name: 'Video',
  props: [
    'post',
    'sizes',
  ],
  components: {
    PreImage
  },
  data() {
    return {
      'image': this.post.post_image, 
      'videoId': this.post.video_id,
      'iReady': false,
      'player': null,
      'options': { 
        sources: [{
          type: "video/mp4",
          'src': this.post.video_file + "#t=0,5",
        }],
        controls: 0,
        preload: 'metadata',
        muted: true,
        loop: 0,
        loadingSpinner: false,
        errorDisplay: false,
        'poster': this.post.post_image,
        /*autoplay: 0,
        controls: 0,*/
      },
      'playImg': null,
      'rates': [],
      'duration': 0,
      'count': 1,
      'userState': 0,
      'numSkips': 3,
      width: 0,
      height: 0,
      start: 1,
      timeout: 10,
      timeoutID: null,
      isTouch: typeof window !== "undefined" && "ontouchstart" in window,
    };
  },
  beforeDestroy() {
    if (this.timeoutID)
      clearTimeout(this.timeoutID);
    if (this.player) {
      this.player.dispose()
    }
  },
  computed: {
    thumbZ: function() {
      log.info ("Thumbz " + this.userState);
      return {
        'thumb-cont': true,
        'thumb-top': (this.userState < 1)
      };
    },
  },
  mounted() {
    var that = this;
    this.api.map_prom.then(() => {
      that.playImg = that.api.mkstatic("vplayer.png");
    });
  },
  
  updated() {
    var that = this;
    this.$nextTick(function() {
      if (that.iReady) {
        log.info ("Updated* " + that.post.pk + " : " + that.post.title + " ::: " + that.userState);
        switch (that.userState) {
          // Still needs setup
        case 0:
          log.info ("Is this the cause ? " + that.post.pk)
          that.startVideo();
          break;
          // Ready to play
        case 1:
        case 3:
          that.preview();
          break;
          // Stopped re-rendering
        case -1:
          that.$refs.video.pause();
          break;
        }
      }
    });
  },
  methods: {
    preview() {
      var that = this;
      log.info ("Preview called " + that.post.pk + " : " + that.userState);
      if (that.userState == 1 || that.userState == 3) { 
        that.$refs.video.currentTime = that.start;
        that.$refs.video.play();
        this.timeoutID = setTimeout(that.reset.bind(that), 5000);
      }
    },
    emitClick() {
      this.pausePreview();
      this.$emit("clicked");
    },
    pausePreview() {
      log.info ("Pause preview" + this.post.pk);
      this.toState(-2);
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }
    },
    reset() {
      log.info ("Reset " + this.post.pk + " : " + this.userState);
      if (this.userState > 0) {
        this.$refs.video.pause();
        //this.toState(this.userState == 1 ? 3 : 1);
        /*this.player.dispose();
        this.toState(0);*/
        this.toState(-2);
        this.timeoutID = null;
        //this.player.dispose();
      }
    },
    toState(state) {
      log.info ("From " + this.userState + " to " + state);
      this.userState = state;
    }, 
    startVideo() {
      var that = this;
      log.info ("Start video pre * " + that.post.pk);
      that.options.sources[0].src = that.post.video_file + "#t=0,5";
      that.player = videojs(that.$refs.video, that.options, function onPlayerReady() {
        //log.info ("Data is " + data);
        //that.rates = data;
        //let rate = 2.0; //Math.max.apply(null, that.rates);
        //that.$refs.video.setDefaultPlaybackRate(rate);
        log.info ("Start video pre js * " + that.post.pk);
        if (that.timeout == 10) {
          this.onloadedmetadata = function() {
            log.info ("Duration " + this.duration);
            that.timeout = Math.max.apply(null, [that.timeout, this.duration - that.start])*1000;
          };
        }
        that.$refs.video.width = that.width;
        that.$refs.video.height = that.height;
        this.playbackRate(2.0);
        if (that.userState != -2) { 
          log.info ("Start video **pre  " + that.userState);
          that.toState(1);
        }          
      });
    },
    runVideo() {
      log.info ("Run video, ready:  " + this.iReady + " User state: " + this.userState);
      if (!this.iReady) { 
        this.height = this.$refs.pimage.clientHeight;
        this.width = this.$refs.pimage.clientWidth;
        this.iReady = true;
        log.info ("height is " + this.height);
      }
      else {
        this.toState(1);
      }
    },
    ended() {
      log.info ("ENDED indeed");
      this.count = 1;
      this.toState(0);
      //this.player.play();
    },
  }
}
</script>
<style>
</style>
