<template>
  <div class="top_bar">
    <div class="mobile-show comment-action">
      <div style="display: flex">
        <img :src="static_fn('img/ic_sidebar.png')" @click="toggleSideBar()" style="margin-right: 8px; width: 48px;"/>
        <a v-if="!userData" href="/"><img :src="static_fn('img/clear-logo-1-6@2x.png')" style="width: 48px;"/></a>
      </div>
    </div>
    <div v-on:focus="openSearch()" v-on:focusout="closeSearch()" tabindex="0" v-bind:class="{'overlap-group2-1 mobile-show':true,  'overlap-group2-1-click':isSearchOpen}">
      <img class="search-1" :src="static_fn('img/search-1-1@2x.png')" />
      <div class="text-35 valign-text-middle inter-normal-eerie-black-17px">
        <input v-on:focus.prevent="openSearch()" @input="event => searchText = event.target.value" type="text" placeholder="Search" class="search-input inter-normal-eerie-black-17px" @keypress.enter="trigger()"/>
      </div>
    </div>
    <div class="overlap-group2-1 mobile-hide">
      <img class="search-1" :src="static_fn('img/search-1-1@2x.png')" />
      <div class="text-35 valign-text-middle inter-normal-eerie-black-17px">
        <input @input="event => searchText = event.target.value" type="text" placeholder="Search" class="search-input inter-normal-eerie-black-17px" @keypress.enter="trigger()"/>
      </div>
    </div>
    
    <div class="overlap-group5">
      <div class="user" v-if="userData">
        <!-- https://app.clickup.com/t/8669rvy0n turn off notification bell temporarily -->
        <!--div class="overlap-group1-1">
          <img class="notification-1" :src="static_fn('img/notification-1-2@2x.png')" />
          <div class="overlap-group-7">
            <div class="text-36 inter-normal-white-10-1px"><span class="inter-normal-white-10-1px">12</span></div>
          </div>
        </div-->
        <div @click="getverified" v-if="userData.gov_id_proof_status !== 'Approved'" class="overlap-group3"
        :style="{ 'background-color': `${getColorChip(userData.gov_id_proof_status)}` }">
          <img
            class="icon-check_mark-1"
            src="../assets/mdi-check-decagram-8@2x.png"
            alt="icon-check_mark"
          />
          <div class="text-verification valign-text-middle inter-normal-white-17px">
            <span v-if="userData.gov_id_proof_status === 'Not Started'" class="inter-normal-white-17px">Get Verified Now</span>
            <span v-if="userData.gov_id_proof_status === 'In Progress'" class="inter-normal-white-17px">Payment Pending</span>
            <span v-if="userData.gov_id_proof_status === 'Pending'" class="inter-normal-white-17px">Verification Pending</span>
            <!-- <span v-if="userData.gov_id_proof_status === 'Approved'" class="inter-normal-white-17px">Approved</span> -->
            <span v-if="userData.gov_id_proof_status === 'Declined'" class="inter-normal-white-17px">More Info required</span>
          </div>
        </div>
        
        <img class="mobile-hide line-249" :src="static_fn('img/line-249-1@2x.png')" />
        <div class="group-427318975 mobile-hide">
          <img class="recommend-1" :src="static_fn('img/recommend-1-8@2x.png')" />
          <div class="text-37 inter-normal-eerie-black-17px">
            <span class="inter-normal-eerie-black-17px">{{userData.reputation}}</span>
          </div>
        </div>
        <div class="group-427318974 mobile-hide">
          <img class="user-multiple-1-group" :src="static_fn('img/user--multiple-1-25@2x.png')" />
          <div class="text-38 inter-normal-eerie-black-17px">
            <span class="inter-normal-eerie-black-17px">{{userData.followers}}</span>
          </div>
        </div>
        <div class="group-427318981" @click="showMenu">
          <div class="photo-container">
            <img class="photo" :src="userData.avatar" />
            <img class="image-3 image" :src="badge" />
          </div>
          <div class="text-39 inter-normal-eerie-black-17px">
            <span class="inter-normal-eerie-black-17px">{{userData.first_name}}</span>           
            <img v-if="userData.gov_id_proof_status == 'Approved'" class="image-checkmark" src="../assets/mdi_check-decagram.png" />
          </div>
          <img class="vector-group-427318981 vector-group" :src="static_fn('img/vector-18@2x.png')" />
          <ul v-if="menu" class="dropdown-menu dropdown-vue">
            <li class="dropdown-sub"><div @click="showProfile">My Profile</div></li>
            <li v-if="userData.gov_id_proof_status == 'Approved'" class="dropdown-sub"><div @click="showReferal">Refer a Friend</div></li>
            <li class="dropdown-sub"><div @click="mypurchases">My Purchases</div></li>
            <li class="dropdown-sub"><div @click="myearnings">My Earnings</div></li>
            <li class="dropdown-sub"><a href="/accounts/notifyprefs">Notification Preferences</a></li>
            <li class="dropdown-sub"><div @click="aboutus">About Us</div></li>
            <li class="dropdown-sub"><div @click="howto">How It Works</div></li>
            <li class="dropdown-sub"><a href="/privacy-policy">Privacy Policy</a></li>
            <li class="dropdown-sub"><a href="/terms">Terms of Use</a></li>
            <li class="dropdown-sub"><a href="/refund-policy">Refund Policy</a></li>

            <li class="dropdown-sub"><a :href="contact">Contact Us</a></li>
            <li class="dropdown-sub"><a href="/accounts/logout/">Logout</a></li>
          </ul>
        </div>
      </div>
      <div v-else>
        <div class="buttons">
          <div @click="getverified" if class="overlap-group3 mobile-hide"
          :style="{ 'background-color': `#2887f3` }">
            <img
              class="icon-check_mark-1"
              src="../assets/mdi-check-decagram-8@2x.png"
              alt="icon-check_mark"
            />
            <div class="text-verification valign-text-middle inter-normal-white-17px">
              <span class="inter-normal-white-17px">Get Verified Now</span>
            </div>
          </div>
          <div class="user user-login">
            <!-- For now, we'll do it Legacy -->
            <!--div class="group-427318981" @click="signup"-->
            <div>
              <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <b-icon icon="list" size="30" aria-hidden="true"></b-icon>
                </template>
                <!-- <b-icon icon="trash-fill" aria-hidden="true"></b-icon> -->
                <b-dropdown-item href="/accounts/signup/">
                  <div class="group-427318981">
                    <div class="text-39 inter-normal-eerie-black-17px">
                      <a href="/accounts/signup/"><span class="inter-bold-eerie-black-17px">Sign up</span></a>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item >
                  <div class="group-427318981" @click="showLogin">
                    <div class="text-39 inter-normal-eerie-black-17px">
                      <span class="inter-bold-eerie-black-17px">Login</span>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item class="mobile-show" >
                  <div class="group-427318981">
                    <div class="text-39 inter-normal-eerie-black-17px">
                      <div class="comment-action"><span class="inter-bold-eerie-black-17px">Get Verified</span></div>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item >
                  <div class="group-427318981">
                    <div class="text-39 inter-normal-eerie-black-17px">
                      <div @click="aboutus"><span class="inter-bold-eerie-black-17px">About Us</span></div>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item >
                  <div class="group-427318981">
                    <div class="text-39 inter-normal-eerie-black-17px">
                      <div @click="howto" class="comment-action"><span class="inter-bold-eerie-black-17px">How It Works</span></div>
                    </div>
                  </div>
                </b-dropdown-item>
                
                
               
                <b-dropdown-item href="/privacy-policy">
                  <div class="group-427318981">
                    <div class="text-39 inter-normal-eerie-black-17px">
                      <div class="comment-action"><span class="inter-bold-eerie-black-17px">Privacy Policy</span></div>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item href="/terms">
                  <div class="group-427318981">
                    <div class="text-39 inter-normal-eerie-black-17px">
                      <div class="comment-action"><span class="inter-bold-eerie-black-17px">Terms of Use</span></div>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item href="/refund-policy">
                  <div class="group-427318981">
                    <div class="text-39 inter-normal-eerie-black-17px">
                      <div class="comment-action"><span class="inter-bold-eerie-black-17px">Refund Policy</span></div>
                    </div>
                  </div>
                </b-dropdown-item>
               
                <b-dropdown-item href="/contact/contact_us">
                  <div class="group-427318981">
                    <div class="text-39 inter-normal-eerie-black-17px">
                      <div class="comment-action"><span class="inter-bold-eerie-black-17px">Contact Us</span></div>
                    </div>
                  </div>
                </b-dropdown-item>
                
               
              </b-dropdown>
            </div>
           
            
            
            
          </div>
        </div>
        <!-- <div class="mobile-show comment-action">
          <div style="display: flex">
            <img :src="static_fn('img/ic_hamburger.png')" @click="toggleSideMenu()" style="margin-right: 8px; width: 48px;"/>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console*/

import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {
  },
  props: {
    toggleSideBar: { type: Function },
    toggleSideMenu: { type: Function },
  },
  computed: {
    user () {
      return this.getUserInfo()
    },
    badge: function () {
      return this.static_fn(this.userData.full_badge_image || 'img/image-2-2@2x.png');
    },
    contact () {
      return `/contact/${this.userData.username}/help_request`;
    },

  },
  mounted() {
    this.userData = this.user
    this.$root.$on('updateNavUserData', (userData) => { // here you need to use the arrow function
      this.userData = userData;
    })
  },
  data () {
    return {
      menu: false,
      userData: {},
      searchText:'',
      isSearchOpen:false
    }
  },
  watch: {
    userData: function(newVal,oldVal){
      console.log('oldVal',oldVal);
      console.log('newVal',newVal);
    }
  },
  methods: {
    ...mapMutations(["showLogin"]),
    ...mapGetters(['getUserInfo']),
    signup () {
    },

    openSearch(){
      this.isSearchOpen = true;
    },
    closeSearch(){
      console.log('called1');
      this.isSearchOpen = false
    },
    getColorChip(status){
      switch(status) {
        case 'Not Started':
          return '#2887f3'
        case 'In Progress':
          return '#FF7F50'
        case 'Pending':
          return '#FF7F50'
        case 'Declined':
          return '#DC143C'
        case 'Approved':
          return '#2E8B57'
        default:
          return '#2887f3'
      }
    },
    showMenu() {
      this.menu = !this.menu;
    },
    trigger () {
      console.log(this.searchText);
      window.location.href="/#/posts?q=" + this.searchText;
      /*this.$router.push({name: `search`, params: {query: this.$refs.search.value} });*/
    },
    goHome() {
      this.$router.push({name: 'home'});
    },
    howto() {
      this.$router.push({name: 'howto', params: {lang: 'en'}});
    },
    aboutus() {
      this.$router.push({name: 'aboutus'});
    },
    mypurchases() {
      this.$router.push({name: 'mypurchases'});
    },
    myearnings() {
      this.$router.push({name: 'myearnings'});
    },
    getverified() {
      this.$router.push({name: 'getverified'});
    },
    showProfile() {
      this.$router.push({name: `profile-detail`, params: {username: this.user.username} });
    },

    showReferal() {
      this.$router.push({name: `profile-detail`, params: {username: this.user.username,tab:'referrals'} });
    },

    // http://127.0.0.1:8080/#/accounts/profile/ankitshah2057/referrals/any
    
  },
}
</script>
<style scoped>
.group-427318981 {
  cursor: pointer;
}

.overlap-group2-1{
    z-index: 2;
    overflow:hidden;
    transition: width 450ms;
    -moz-transition: width 450ms;
    -webkit-transition: width 450ms;  
}

.text-39{
  min-height: auto;
}
.overlap-group2-1-click {
  position: absolute;
  width: 100%;
}
.overlap-group3 {
  align-items: center;
  border-radius: 50px;
  gap: 8px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 8px 8px;
  margin-left:5px;
  cursor: pointer;
}

.icon-check_mark-1 {
  align-self: flex-end;
  height: 24px;
  width: 24px;
}


.text-verification {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  /* min-width: 95px; */
  white-space: nowrap;
}

@media (max-width: 600px)
{
  .overlap-group2-1{
    margin-left: 0px;
  }
  .icon-check_mark-1 {
    align-self:center;
    height: 18px;
    width: 18px;
  }
  .text-verification {
    white-space: normal;
  }
}


.search-input {
  background: none;
  border: none;
  outline: none;
}

.photo-container {
    position: relative;
}

.image {
  height: 15px;
  object-fit: cover;
  position: absolute;
  width: 15px;
}

.dropdown-menu>li>div {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}
.image-checkmark{
  height: 25px;
  widows: 25px;
  margin-left: 10px;
}
.dropdown-menu>li>div:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
</style>
