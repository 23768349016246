<template>
  <div class="recipe-inter">
    <div class="recipe-inter-cell recipe-inter-sym like-section" id="like-section-208217" @click.prevent="handleAction">
      <div class="recipe-inter-cell recipe-inter-num">
        <button type="button" class="btn btn-link">
          <img class="icon" :src="static_fn(image)" alt="" />
        </button>
      </div>
    </div>
    <div v-if="count > 0" class="recipe-inter-cell recipe-inter-num" @click.prevent="handleShow">
      <button type="button" class="btn btn-link">
        <small>{{count}}</small>
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
export default {
  name: 'AsyncButton',
  props: {
    'image': String,
    'count': {
      type: Number,
      default: function() {
        return 0;
      }
    }
  },
  methods: {
    handleAction(){
      this.$emit("toggle");
    },
    handleShow() {
      this.$emit("show");
    }
  },
}
</script>
