/* eslint-disable no-console */
import VueWaypoint from 'vue-waypoint';
import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import store from './store';
import router from './router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import VueYoutube from 'vue-youtube';

import 'video.js/dist/video-js.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '../assets/style.css';

import { APIService } from './http/APIService';

Vue.config.productionTip = false;

var entry = App;
//var args = window.api_args ? window.api_args : {};
var divn = 'app';

Vue.use(VueRouter);
Vue.use(VueWaypoint);
Vue.use(VueYoutube);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

var gAPI = new APIService();
Vue.mixin({
  props: {
    api: {
      default: function() {
        return gAPI;
      },
    },
  },
  methods: {
    static_fn(img) {
      return this.$store.state.static_map[img];
    },
  },
});

Vue.prototype.og_route = window.og_route;
Vue.prototype.isLegacy = window.isLegacy;

if (window.isLegacy) {
  router.beforeEach ((to) => {
    if (to.name != "home") {
      window.location.href = `/#${to.path}`;
    }
  });
}
if (window.api_user) {
  store.commit('setUser', window.api_user);
}
if (window.needLogin) {
  store.commit('showLogin');
}
new Vue({
  router,
  render: (h) => h(entry),
  store: store,
}).$mount('#' + divn);
