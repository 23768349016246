<template>
<div>
  <div :class="framekls">
    <div v-for="user in items" :key="user.username" >
      <UserLine :user="user">
      </UserLine>
    </div>
  </div>
  <div v-if="viewMore && count && (nextPageURL != undefined && nextPageURL != '')" class="profile-text-22 inter-normal-eerie-black-17px-2" @click="getNextPage">
    <span class="inter-normal-eerie-black-17px-2">View More</span>
  </div>
  <div class="way-container" v-else-if="nextPageURL !== '' || loading == 'E'">
    <Loading :loading="'L'" :key="loading" :loaderror="loading == 'E'" class="way-div" v-waypoint="{ active: true, callback: onWaypointDown, options: intersectionOptions }" id="More">
    </Loading>
  </div>
</div>
</template>

<script>
/* eslint-disable no-console */
import Loading from './Loading';
import ItemList from './ItemList';
import UserLine from './UserLine';

export default {
  extends: ItemList,
  name: 'UserList',
  components: {
    Loading,
    UserLine
  },
  props: {
    'category': String,
    'numrows': Number,
    'rowlen': Number,
    'mrowlen': Number,
    'comp': Object,
    'framekls': String,
    'arrowMore': Boolean,
    'viewMore': {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    'isMobile': Boolean,
    'touchHandler': Function,
    'rparams': {
      type: Object,
      default: function() {
        return {per_page: this.numrows*this.rowlen};
      }
    },
    'urlp': {
      type: Object,
      default: function() {
        return {'app': 'accounts',
                'view': 'list'};
      }
    }
  },
  data() {
    return {
      rowlength: this.rowlen,
      postLisClass: "post-scroll",
      selectedPost:0,
      selectedVideo:0,
      loading: null, //false,
      intersectionOptions: {
        root: null,
        //rootMargin: '0px 0px 0px 0px',
        threshold: [0.25, 0.75] // [0.25, 0.75] if you want a 25% offset!
      },
    };
  },
  watch: {
    isMobile: function () {
      console.log("name change"); // print out when the name changes
      this.rowlength = this.isMobile ? this.mrowlen : this.rowlen;
      console.log(this.rowlength);
    },
  },
  methods: {
    addURLParams() { 
      this.params.per_page = this.numrows*this.rowlen;
    },
    handleScroll: function(e) {
      let newScrollLeft = e.currentTarget.scrollLeft;
      let width = e.currentTarget.clientWidth;
      let sWidth = e.currentTarget.scrollWidth;
      if ((sWidth - newScrollLeft - width) == 0) {
        console.log("right end");
        this.getNextPage();
      }
    },
    setPage(data) {
      let len = Math.ceil(data.length / this.rowlength);
      for (let i = 0; i < len ; i++) {
        let temp = data.slice(i, i+this.rowlength);
        temp.map((e) => {
          this.rows.push(e);
        })
      }
    },
    selectPost(index, post) {
      let route_cat = (this.category == "quickie") ? 'quickie' : 'recipe';
      this.$router.push({name: `${route_cat}-detail`, params: {pk: post.pk, 
                                                         category: this.category,
                                                         idx: index} 
                        });
    },
    selectVideo(post) {
      console.log ("Select video is " + post.pk);
      this.selectedVideo = post.pk;
      this.modalOpen = true;
      this.postListClass = "post-fixed";
      let sides = document.querySelectorAll ("tofix");
      for (let i = 0; i < sides.length; i++) {
        sides[i].classList.add ("postbg-fixed");
      }
      console.log ("Select post done * " + this.selectedPost);
    },
  },
  updated() {
  },
}
</script>
