<template>
  <a class="comment-action" @click="$router.push('/groups/'+group.slug)">
    <div class="profile-group-group-3 mobile-hide">
      <div class="profile-group-overlap-group profile-group-overlap"> <!--v-bind:style="{ 'backgroundImage': 'url(' + group.thumbnail + ')' }"-->
        <div class="profile-group-text-3 valign-text-middle inter-bold-eerie-black-27-8px">
          <span><span class="inter-bold-eerie-black-27-8px">{{ group.name[0] }}</span> </span>
        </div>
      </div>
      <div class="profile-group-flex-col profile-group-flex">
        <div class="profile-group-text valign-text-middle profile-group-inter-bold-eerie-black-25px">
          <span><span class="inter-bold-eerie-black-25px">{{group.name}}</span> </span>
        </div>
        <div class="profile-group-flex-row profile-group-flex inter-normal-eerie-black-17px-2">
          <img class="profile-group-icon-user" :src="static_fn('img/user--multiple-1-41@2x.png')" alt="icon-user" />
          <div class="profile-group-text-4"><span class="inter-normal-eerie-black-17px-2">{{group.member_count}} Members</span></div>
          <img
            class="profile-group-fluentdocument-one-page-24-regular"
            :src="static_fn('img/fluent-document-one-page-24-regular-5@2x.png')"
            alt="fluent:document-one-page-24-regular"
          />
          <div class="profile-group-text-5"><span class="inter-normal-eerie-black-17px-2">{{group.recipe_count}} Posts</span></div>
        </div>
      </div>
    </div>
    <div class="profile-group-group-3 mobile-show">
      <div class="profile-group-flex-col profile-group-flex">
        <div class="profile-flex-row">
          <div class="profile-group-overlap-group profile-group-overlap">
            <div class="profile-group-text-3 valign-text-middle inter-bold-eerie-black-27-8px">
              <span><span class="inter-bold-eerie-black-27-8px">A</span> </span>
            </div>
          </div>
          <div class="profile-group-text valign-text-middle profile-group-inter-bold-eerie-black-25px">
            <span><span class="inter-bold-eerie-black-25px">{{group.name}}</span> </span>
          </div>
        </div>
        <div class="profile-group-flex-row profile-group-flex inter-normal-eerie-black-17px-2">
          <img class="profile-group-icon-user" :src="static_fn('img/user--multiple-1-41@2x.png')" alt="icon-user" />
          <div class="profile-group-text-4"><span class="inter-normal-eerie-black-17px-2">{{group.member_count}} Members</span></div>
          <img
            class="profile-group-fluentdocument-one-page-24-regular"
            :src="static_fn('img/fluent-document-one-page-24-regular-5@2x.png')"
            alt="fluent:document-one-page-24-regular"
          />
          <div class="profile-group-text-5"><span class="inter-normal-eerie-black-17px-2">{{group.recipe_count}} Posts</span></div>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
export default {
  name: 'groupLine', 
  props: [
    'group',
  ],
  computed: {
  },
}
</script>
