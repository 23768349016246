<template>
  <div class="profile-body">
    <Back/>
    <div class="profile-overlap-group6">
      <slot name="header">
      </slot>
      <div class="profile-flex-row-1">
        <RouteMenu :choices="choices" :tab_cat="tab_cat">
        </RouteMenu>
        <div class="tab-content profile-flex-col-2 profile-flex-col-4">
          <div class="profile-overlap-group7">
            <div class="profile-achievements-3">
              <div v-for="choice in choices" :key="choice.tab">
                <component v-if="tab==choice.tab" :is="choice.comp"
                           v-bind="choice.props"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

/* eslint-disable no-console */
import { mapMutations } from "vuex";
import Back from './Back.vue';
import RouteMenu from './RouteMenu.vue';

export default {
  name: 'SideMenuPage',
  props: {
    tab: { type: String },
    ctx: { type: String },
    cat: {
      type: String,
      default: function() {
        return 'any'
      }
    },
    choices: { type: Array},
  },
  data () {
    return {
    };
  },
  components: {
    Back,
    RouteMenu
  },
  computed : {
    tab_cat: function() {
      return `${this.tab}/${this.cat ? this.cat: "any"}`;
    },
  },
  created() {
    this.setViewContext(this.ctx);
  },
  updated() {
    console.log ("Side menu page update called");
  },
  methods:{
    ...mapMutations(["setViewContext"]),
  },
}
</script>

<style>
a {
    cursor: pointer;
}

.hide {
  display: none !important; 
}

.comment-action {
  position: relative;
}

.image {
  height: 26px;
  object-fit: cover;
  position: absolute;
  width: 26px;
}
.image-3 {
  right: 0px;
  bottom: 0px;
}
</style>
