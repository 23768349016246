<template>
  <div class="profile-bio">
    <div class="profile-text-13 valign-profile-text-middle inter-bold-eerie-black-30px">
      <span><span class="inter-bold-eerie-black-30px">Bio</span> </span>
    </div>
    <p class="profile-name valign-profile-text-middle inter-normal-black-17px">
      <span
        ><span class="inter-normal-black-17px">{{ user.bio }}</span>
      </span>
    </p>
    <div class="profile-group-427319010">
      <!-- <div class="profile-group-427319008">
        <div class="profile-flex-col-3 profile-flex-col-4">
          <p class="profile-top-5-dishes-i-love-to-eat inter-bold-black-17px">
            <span class="inter-bold-black-17px">My Badges:</span>
          </p>
        </div>
        <div class="profile-frame-427319032 frame">
          <img class="image-3 image" :src="badge" />
        </div>
      </div> -->
      <div class="profile-group-427319008">
        <div class="profile-flex-col-3 profile-flex-col-4">
          <p class="profile-top-5-dishes-i-love-to-eat inter-bold-black-17px">
            <span class="inter-bold-black-17px">Top 5 Dishes I love to eat</span>
          </p>
        </div>
        <div class="profile-frame-427319032 frame">
          <div v-for="dish in user.dishes_i_love_most" :key="dish" class="profile-frame-427318972 frame">
            <div class="valign-profile-text-middle inter-normal-eerie-black-17px-2">
              <span><span class="inter-normal-eerie-black-17px-2">{{dish}}</span> </span>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-group-427319009">
        <p class="profile-top-5-dishes-i-cook-best inter-bold-black-17px">
          <span class="inter-bold-black-17px">Top 5 Dishes I cook best</span>
        </p>
        <div class="profile-frame-427319031-1">
          <div v-for="dish in user.dishes_i_cook_best" :key="dish" class="profile-frame-427318972-2 profile-frame-427318972-3">
            <div class="valign-profile-text-middle inter-normal-eerie-black-17px-2">
              <span><span class="inter-normal-eerie-black-17px-2">{{dish}}</span> </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="profile-overlap-group7">
      <div class="profile-achievements-3">
        <p class="profile-text-21 valign-profile-text-middle inter-bold-eerie-black-30px">
          <span
            ><span class="inter-bold-eerie-black-30px">My profile-achievements, Recognitions, and Cooking stories</span>
          </span>
        </p>
        <StoryList :username="username" :numrows="1" :rowlen="4" :mrowlen="4" :urlp="{'app': 'stories', 'view': username}"
                framekls="profile-x1" :arrowMore="true" :isMobile="isMobile" :viewMore="true" :ignoreCategory="true">
        </StoryList>
      </div>
    </div>
  </div>
</template>

<script>
import StoryList from './StoryList.vue';
import { mapMutations } from "vuex";

export default {
  name: 'ProfileBio',
  components: {
    StoryList,
  },
  props: {
    'user': Object,
    'isMobile': Boolean,
    'username': String,
  },
  computed:{
    badge: function () {
      return this.static_fn(this.user.full_badge_image || 'img/image-2-2@2x.png');
    },
  },
  data() {
    return {
      totalCount: 0,
    };
  },
  watch: {
    
  },
  methods:{
    ...mapMutations(["setViewContext"]),
  },
  mounted(){
    this.setViewContext("user");
  }
}
</script>
<style scoped>
</style>
