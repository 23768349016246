<template>
  <div class="profile-bio">
    <loading-overlay v-if="overlayLoading"/>

    <div class="container mt-4">
    <div class="row">
      <div class="profile-text">
        <h2 class="profile-title">My Referral Code:</h2>
      </div>
      <div class="profile-code">
        <template v-if="!is_editing">
          <div class="d-flex align-items-center code-container">
            <span class="code">{{ referal_code }}</span>
            <div>
              <b-button size="sm" variant="success" class="m-2" @click="copyCode">
                <b-icon icon="clipboard" aria-label="Help"></b-icon>
              </b-button>
              <b-button size="sm" variant="primary" class="" @click="allowEdit">
                <b-icon icon="pencil-square" aria-label="Help"></b-icon>
              </b-button>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="input-group input-container">
            <span class="input-group-text">Referral Code</span>
            <input type="text" class="form-control referal-input" v-model="referal_code">
            <div class="input-group-append">
              <b-button variant="outline-success" @click="editReferal"><b-icon icon="check2"></b-icon></b-button>
            </div>
          </div>
          <span class="field-error" v-if="referral_error !== ''">{{ referral_error }}</span>
        </template>
      </div>
      <div class="yt-div row mt-4">
        <iframe class="col-lg-5 col-md-12" width="800" height="315" src="https://www.youtube.com/embed/Xb4aO4MXa7Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

      </div>
  </div>
</div>
    
    
  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapMutations } from "vuex";
import LoadingOverlay from './LoadingOverlay.vue';
/*import Clipboard from 'clipboard';*/

export default {
  name: 'MyReferals',
  components: {
    LoadingOverlay
  },
  props: {
    'user': Object,
    
  },
  computed:{
   
  },
  data() {
    return {
      is_editing:false,
      referal_code: this.user.referal_code,
      referral_error:'',
      overlayLoading:false,

    };
  },
  watch: {
    
  },
  methods:{
    ...mapMutations(["setViewContext"]),
    allowEdit(){
      this.is_editing=true;
    },
    copyCode(){
      const tempInput = document.createElement('input');
      tempInput.value = this.referal_code;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
    },

    async editReferal(){
      try {
        this.overlayLoading = true

        await this.api.put(`accounts/profile/referal-code/${this.user.id}`,{"referal_code":this.referal_code}).then((data) => {
        if(data.success){
          this.is_editing = false;
          this.referal_code = data.referal_code
          this.referral_error = '';
          this.$bvToast.toast(`Hey, share this code to invite your friends to the panel`, {
            title: `Referal code updated`,
            variant: 'success',
            autoHideDelay: 5000,
          })
        }else{
          this.referalChecked = false;
          this.referral_error = 'Referral code already in use';
          this.$bvToast.toast(`Hey, this referral code is already in use. Try some other ones`, {
            title: `Invalid referral code`,
            variant: 'danger',
            autoHideDelay: 5000,
          })
        }
      })
      } catch (error) {
        console.log(error);
      } finally{
        this.overlayLoading = false;
      }
    },
  },
  mounted(){
    this.setViewContext("user");
  }
}
</script>
<style scoped>

.profile-title {
  font-size: 24px;
  font-weight: bold;
  color: #222222;
  margin-bottom: 1rem;
}

.profile-code {
  margin-top: 1rem;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
}

.code-container {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
}

.input-container {
  background-color: #ffffff;
  border-radius: 8px;
}

.code {
  font-size: 20px;
  font-weight: bold;
  color: #222222;
}

.btn-edit {
  margin-left: 1rem;
}

.btn-save {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.field-error {
  color: red;
  font-size: 14px;
  margin-top: 0.5rem;
}
.referal-input{
  height: auto !important;
}

.yt-div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yt-div iframe{
  width: 100%;
  
}
</style>
