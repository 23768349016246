/* eslint-disable no-console */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    static_map: null,
    initializing: false,
    initialized: false,
    loading: false,
    user_info: null,
    loginShow: false,
    api: null,
    view_context: 'feeds',
  },
  getters: {
    getStatic: (state) => (fn) => {
      return state.static_map ? state.static_map[fn] : '';
    },
    loggedIn: (state) => !!state.user_info,
    isInitialized: (state) => state.initialized,
    getLoginShow: (state) => state.loginShow,
    view_context(state) {
      return state.view_context;
    },
    getUserInfo(state) {
      console.log('state.user_info', state.user_info);
      return state.user_info;
    },
  },
  mutations: {
    changeInitialized(state, data) {
      state.api = data.api;
      state.static_map = data.static_map;
      state.user_info = data.user_info;
      state.initialized = true;
      state.initializing = false;
    },
    changeLoading(state, data) {
      state.loading = data;
    },
    showLogin(state) {
      state.loginShow = true;
    },
    hideLogin(state) {
      state.loginShow = false;
    },
    setViewContext(state, vc) {
      state.view_context = vc;
    },
    setUserInfo(state, info) {
      state.user_info = info;
    },
  },
  actions: {
    postOrLogin(context, url) {
      if (!context.state.user_info) {
        context.commit('showLogin');
      } else {
        var api = context.state.api;
        return api.post(url).then(function(post_rsp) {
          api.get('/api/accounts/mystats/').then((data) => {
            context.commit('setUserInfo', data);
          });
          return post_rsp;
        });
      }
    },

    initialize(context, api) {
      if (context.state.initialized && !context.state.initializing) {
        return;
      }
      context.state.initializing = true;
      api.map_prom.then(() => {
        api
          .get('/api/accounts/mystats/')
          .then((data) => {
            context.commit('changeInitialized', {
              api: api,
              static_map: api.static_map,
              user_info: data,
            });
          })
          .catch(() => {
            context.commit('changeInitialized', {
              api: api,
              static_map: api.static_map,
              user_info: null,
            });
          });
      });
    },
  },
});
