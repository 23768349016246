<template>
  <div v-if="items" class="body">
    <div class="way-container" v-if="previousPageURL !== '' || loading == 'E'">
        <Loading :loading="true" :loaderror="loading =='E'" ref="waytop" class="way-div way-top" v-waypoint="{ active: true, callback: onWaypointUp }" id="way-topper"></Loading>
    </div>
    <div v-for="post in items" class="post_details" :key="post" ref="detail" :id="`PD-${post.pk}`">
      <div class="post-flex-col">
        <component :is="detailComp" :post="post" category="photo">
        </component>
      </div>
    </div>
    <div class="way-container" v-if="nextPageURL !== '' || loading == 'E'">
        <Loading :loading="true" :loaderror="loading == 'E'" ref="waybottom" class="way-div way-bottom" v-waypoint="{ active: true, callback: onWaypointDown }"></Loading>
    </div>
  </div>
  </template>
  <script>
  /* eslint-disable no-console */
  import StoryDetail from './StoryDetail';
  import ItemList from './ItemList';
  import BaseModal from './BaseModal';
  import Loading from './Loading';
  import { mapMutations } from "vuex";
  
  export default {
    mixins: [ItemList, BaseModal],
    name: 'PostModal',
    components: {
      StoryDetail,
      Loading,
    },
    computed: {
       urlp() {
        return {'app': 'stories',
                  'view': this.username};
      }
    },
    props: {
      'username': String,
      // 'pk': Number,
      // 'idx': Number,
      'ignoreCategory': {
        type: Boolean,
        default: function() {
          return true;
        }
      },
      "isMobile": Boolean,
    },
    data() {
      return {
        detailComp: StoryDetail,
        scrollDest: null,
      };
    },
    watch: {
      isMobile: function () {
        this.detailComp = StoryDetail;
      },
    },
    created() {
      
    },
    methods: {
      ...mapMutations(["setViewContext"]),
      addURLParams() {
        this.params.per_page = 3;
      },
      postGetItems() { 
        this.scrollDest = 0;//this.idx % this.params.per_page;
        console.log ("postGetItems " + this.idx + " i.e. " + this.scrollDest + " previous " + this.previousPageURL + " Next: " + this.nextPageURL);
        // for boundaries, get previous or next so we don't land on a waypoint
        if (!this.scrollDest && this.previousPageURL) {
          this.getPreviousPage();
        }
        else if (this.scrollDest == this.params.per_page - 1 &&
                 this.nextPageURL) {
          this.getNextPage();
        }
        else {
          this.loading = null;
          this.scrollToElem();
        }
      },
      scrollToElem() {
        
        if (this.scrollDest != null) {
          var that = this;
          this.$nextTick(function() {
            const arr = that.$refs.detail.map( e => e.getAttribute("id"));
            console.log ("Scroll to elem " + that.scrollDest + " of " + that.$refs.detail.length +
                         " ID " + that.$refs.detail[that.scrollDest].getAttribute("id") + " ARR " + arr);
            that.$refs.detail[that.scrollDest].scrollIntoView();
            that.scrollDest = null;
          });
        }
      },
      postUp() {
        this.scrollToElem();
      },
      postDown() {
        this.scrollToElem();
      },
    },
    mounted(){
      
    }
  }
  </script>
    