import { render, staticRenderFns } from "./UserListModal.vue?vue&type=template&id=5379150b&scoped=true"
import script from "./UserListModal.vue?vue&type=script&lang=js"
export * from "./UserListModal.vue?vue&type=script&lang=js"
import style0 from "./UserListModal.vue?vue&type=style&index=0&id=5379150b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5379150b",
  null
  
)

export default component.exports