<template>
 <a :class="'avatar-name menu-entry ' + active_class" @click="gotoUser">
   <div class="avatar-4" v-bind:style="{'backgroundImage': 'url(' + item.thumbnail + ')' }"></div>
   <div class="text-28 inter-normal-eerie-black-17px">
     <span class="inter-normal-eerie-black-17px">{{item.name}} </span>
   </div>
 </a>
</template>
<script>
/* eslint-disable no-console */
export default {
  name: 'sidebar-followed',
  components: {
  },
  props: [
    'item'
  ],
  computed: {
    active_class() {
      return this.item.is_active ? "active": "";
    }
  },
  methods: {
    gotoUser() {
      this.$router.push({name: 'profile-detail',
                         params: {'username': this.item.username}}).catch((err) => {
                           if (err.name !== 'NavigationDuplicated' &&
                               !err.message.includes('Avoided redundant navigation to current location')
                              ) {
                             console.log(err);
                           }
                           else {
                             console.log("Redundant: " + this.$router.path);
                           }
                         });
    },
  }
  
}
</script>
