<template>
  
  <div class="body">
    <LoadingOverlay v-if="overlayLoading"/>

    <!-- <div class="way-container" v-if="previousPageURL !== '' || loading == 'E'">
        <Loading :loading="true" :loaderror="loading =='E'" ref="waytop" class="way-div way-top" v-waypoint="{ active: true, callback: onWaypointUp }" id="way-topper"></Loading>
    </div> -->
    <div class="login modal show">
      <div class="modal-dialog global" role="document" ref="reference">
        <div class="modal-content global">
          <div class="modal-header d-flex align-center">
            <h5 class="modal-title">Withdraw Balance</h5>
            <div class="overlap-group1-5" @click="handleClose">
              <div class="ellipse-54"></div>
              <img class="noun-cross-1707162-1" :src="static_fn('img/noun-cross-1707162-1-1@2x.png')" alt="noun-cross-1707162 1" />
            </div>
          </div>
          <div class="withdraw_content">
            <div class="send-withdraw" >
              <div class="group-427319047">
                <div class="text-29 inter-semi-bold-eerie-black-17px">
                    <span class="inter-semi-bold-eerie-black-17px"
                      >Withdrawal Amount</span
                    >
                  </div>
                <input type="number" min="20" max="99999" class="text-form-control" placeholder="Enter Custom Amount" id="withdraw_amount" v-model="withdraw.request_amount">
                <span class="field-error" v-if="withdraw.amountError !=''"> {{withdraw.amountError}} </span>
              </div>

              <div class="next-btn">
                <div class="overlap-group6" @click="checkForm">
                  <div class="valign-text-middle inter-semi-bold-white-17px center-text">
                    <span class="payText" >Send Request</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  <script>
  /* eslint-disable no-console */

  
  import LoadingOverlay from "./LoadingOverlay.vue";
  import BaseModal from './BaseModal';

  export default {
    mixins: [BaseModal],

    name: 'WithdrawRequestModal',
    props: [
      'balance',
    ],
    components: {
      LoadingOverlay
    },
    computed: {
       
    },
    data() {
      return {
        overlayLoading:false,
        withdraw:{
          request_amount: 20,
          note: '',
          preferred_method: '',
          amountError:''
        },
      };
    },
    watch: {
    },
    created() {
      
    },

    
    methods: {
      handleClose() {
        this.$emit("closed");
      },
      checkForm(){
        if(this.withdraw.request_amount >= 20 && this.withdraw.request_amount <= this.balance){
          this.submitForm()
        }else{
          this.withdraw.amountError = `Please enter amount greater than 20 and less than ${this.balance}`;
          this.$root.$bvToast.toast(`Please enter amount greater than 20 and less than ${this.balance}`, {
            title: `Something went wrong`,
            variant: 'danger',
            autoHideDelay: 5000,
          })
        }
      },

      submitForm(){
        this.overlayLoading = true
        let formData = new FormData();
        formData.append("amount", this.withdraw.request_amount);
        this.api.formDataPost(`monetization/withdraw-request`, formData,true).then((data) => {
          if (data.success){
            this.$root.$bvToast.toast(`We have successfully sent request to khal team to verify your request. Please wait for next 48 hours.`, {
              title: `Request sent successfully`,
              variant: 'success',
              autoHideDelay: 5000,
            })
            this.$emit("refetch");
          }else{
            this.$root.$bvToast.toast(`We could not submit your withdrawal request`, {
              title: data.msg,
              variant: 'danger',
              autoHideDelay: 5000,
            })
          }
        }).catch(()=>{
          this.$root.$bvToast.toast(`Hey, we could not complete your withdraw request`, {
            title: `Something went wrong`,
            variant: 'danger',
            autoHideDelay: 5000,
          })
        }).finally(() => {
          this.overlayLoading = false
          this.handleClose()
        });
      },

     
    },
    mounted(){
    }
  }
  </script>
    
<style scoped>
.modal-content {
  padding: 10px;
  overflow:scroll;
}

@media (min-width: 1200px){
  .modal-dialog {
    width: 900px;
    margin: 30px auto;
  }
}
.field-error{
    color: tomato;
  }
.user_photo{
  position: relative;
  /* align-self: center; */
}

.user_photo .photo{
  position: relative;
}

.group-427319047 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
  padding: 10px 50px;
}
.group-427319048{
  width: 100%;
  padding: 10px;
}

.amountRow{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.center-text{
  margin: auto;
}
.amountRow h1{
  font-size: 20px;
  color:#000000;
  font-weight: 500;
}

.amountRow h2{
  font-size: 22px;
  color:#000000;
  font-weight: 700;
}

hr.dotted {
  width: 100%;
  border-top: 3px dotted #bbb;
}

.text-form-control{
  align-items: flex-start;
  background-color: var(--zumthor);
  border-radius: 50px;
  display: flex;
  height: 49px;
  padding: 13px 29px;
  width: -webkit-fill-available;
  border-width: 0px;
}

.payText{
  color: #FFFFFF;
}
.withdraw_content{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  justify-content: space-around;
  align-items: center;
}

.or-text{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
/* identical to box height, or 148% */

display: flex;
align-items: center;

color: #000000;
}

.withdraw_content .username{
  margin-top: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 39px;
  /* identical to box height, or 155% */


  /* Black/100% */

  color: #1C1C1C;
}

.next-btn{
  display: flex;
  width: 100%;
  align-items: flex-end;
  margin: 20px 10px;
}

.overlap-group6 {
  align-items: center;
  margin-left: auto;
  background-color: var(--eerie-black);
  border-radius: 50px;
  display: flex;
  margin-right: 14px;
  padding: 15px 30px;
  cursor: pointer;
  width: auto;
  overflow: hidden !important;
}



.pre-amount-button{
  display: flex;
  justify-items: center;
  flex-flow: row wrap;
  justify-content: center;
}

.button-container{
  background: #EDF4FC;
  /* stroke_dark */
  width: 50px;
  height: 50px;
  border: 1px solid #CCDBE2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  justify-content: center;
}

.selected{
  background: #1C1C1C;
  border: 1px solid #000000;
}

.button-container span{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  /* identical to box height, or 118% */
  display: flex;
  /* heading */
  color: #1C1C1C;
}

.selected span{
  color: #FFFFFF;
}

.login .modal-title{
  text-align: start;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 20px;
  /* identical to box height, or 80% */

  display: flex;
  align-items: center;

  color: #000000;
}

.send-withdraw{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.send-withdraw p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 39px;
  /* identical to box height, or 194% */
  /* Black/100% */
  color: #1C1C1C;
}
</style>
