<template>
  <div class="buttons">
    <a @click="$router.push('/groups')" class="overlap-group1-2">
      <img class="user-multiple-1-overlap-group2" :src="static_fn('img/user--multiple-1-25@2x.png')" />
      <div class="text-16 valign-text-middle inter-normal-eerie-black-17px">
        <span><span class="inter-normal-eerie-black-17px">Groups</span> </span>
      </div>
    </a>
    <a class="overlap-group1-2" href="/posts/new">
      <img class="add-filled-1" :src="static_fn('img/add--filled-1-2@2x.png')" />
      <div class="text-41 valign-text-middle inter-normal-eerie-black-17px">
        <span><span class="inter-normal-eerie-black-17px">Add Recipe</span> </span>
      </div>
    </a>
    <!-- https://app.clickup.com/t/8669nrwtt turn off "Who made it button" temporarily -->
    <!--div class="overlap-group1-2">
      <img class="network-1-1" :src="static_fn('img/network--1-1-2@2x.png')" />
      <div class="text-42 valign-text-middle inter-normal-eerie-black-17px">
        <span><span class="inter-normal-eerie-black-17px">Who Made it Better</span> </span>
      </div>
    </div-->
  </div>
</template>
<script>

export default {
  name: 'TopButtons',
  components: {
  },
  props: [],
  methods: {
    addRecipe() {
      this.$router.push("/posts/new");
    },
  }
}

</script>

<style scoped>
.overlap-group1-2
{
  cursor: pointer;
}
</style>
