<template>
  <div class="body">
    <loading-overlay v-if="overlayLoading"/>
    <div id="" class="x02-get-verified screen">
      <div class="back" @click="$router.back()">
        <div class="overlap-group-8">
          <div class="page-disabled">
            <div class="ic-carat-left"></div>
          </div>
          <img class="vector-3" src="../assets/vector-20@2x.png" alt="Vector" />
        </div>
        <div class="place inter-medium-log-cabin-17px"><span class="inter-medium-log-cabin-17px">Back</span></div>
      </div>
      
      <div v-if="status" class="overlap-group71">
        <h1 class="text-27 valign-text-middle inter-bold-black-25px">
          <span><span class="inter-bold-black-25px">Payment Completed</span> </span>
        </h1>
        <p class="text-28 inter-normal-eerie-black-17px">
          <span class="inter-normal-eerie-black-17px"
            >Your payment has been confirmed. Our verification team will soon verify your request.</span
          >
        </p>
      </div>
      
      <div v-if="!status" class="overlap-group71">
        <h1 class="text-27 valign-text-middle inter-bold-black-25px">
          <span><span class="inter-bold-black-25px">Payment Failed</span> </span>
        </h1>
        <p class="text-28 inter-normal-eerie-black-17px">
          <span class="inter-normal-eerie-black-17px"
            >Something went wrong with your payment. Incase you have any queries or want to retry the payment, please contact us on info@khal.com</span
          >
        </p>
      </div>
      </div>
    <footer class="footer">
      <h4 class="text-center">&copy; Khal: All rights reserved </h4>
    </footer>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapGetters } from 'vuex';
import LoadingOverlay from './LoadingOverlay.vue';

export default {
  name: 'PaymentStatVerification',
  components: {
    LoadingOverlay
  },
  computed: {
    user () {
      return this.getUserInfo()
    },

  },
  props: {
    
  },
  data() {
    return {
      overlayLoading:true,
      status:this.$route.query.st === 't'?true:false,
      ref:this.$route.query.rf,
    };
  },
  mounted() {
    
  },
  updated() {
  
  },
  created: function(){
    this.confirmPayment()
  },
  methods: {
    ...mapGetters(['getUserInfo']),

    confirmPayment() {
      let formData = new FormData();
      formData.append("id", this.ref);
      
      this.api.formDataPost(`verification/payment-confirmation`, formData,true).then((data) => {
          this.status = data.result.payment_status == 3? true:false
          this.onUpdate()
          // this.$router.go();
      })
    },

    onUpdate () {
      this.api.get("/api/accounts/mystats/").then((userData) => {
        this.overlayLoading = false;
        this.$root.$emit('updateNavUserData', userData);
        this.context.commit("setUserInfo", userData);
      });
    },
  }
}
</script>
<style scoped>
.body {
}
.wrap-static p,
  .wrap-static h2 {
  text-align: center;
  }
  .iframe-wrapper {
  position: relative;
  padding-bottom: 55%;
  margin: 50px auto 0px auto;
  max-width: 730px;
  }

  .field-error{
    color: tomato;
  }
  .iframe-wrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  }
  .wrap-static h2 {
  max-width: 730px;
  margin: 10px auto 0 auto;
  font-size: 25px;
  font-size: 7vmin;
  }
  
  .wrap-static p {
  padding-top: 50px;
  margin-bottom: 0px;
  }
  
  .wrap-static#wrap::after {
  opacity: .3;
  }

  .wrap-static #main {
  padding-top: 25px;
  padding-top: 12vmin;
  }

  .x02-get-verified {
  align-items: flex-start;
  background-color: var(--zumthor-2);
  border: 1px none;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width:100%;
}

.x02-get-verified .back {
  align-items: center;
  display: flex;
  gap: 17px;
  min-width: 94px;
  cursor: pointer;
  width: -webkit-fill-available;
}


.x02-get-verified .overlap-group-8 {
  border-radius: 115.62px;
  height: 37px;
  position: relative;
  width: 37px;
}

.x02-get-verified .page-disabled {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 115.62px;
  display: flex;
  height: 37px;
  left: 0;
  overflow: hidden;
  padding: 4.6px 4.6px;
  position: absolute;
  top: 0;
  width: 37px;
}

.x02-get-verified .ic-carat-left {
  
  background-image: url('../assets/ic---carat---down-13@2x.png');
  background-size: 100% 100%;
  height: 28px;
  width: 28px;
}

.x02-get-verified .vector-3 {
  height: 12px;
  left: 14px;
  position: absolute;
  top: 12px;
  width: 7px;
}

.x02-get-verified .place {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2.0px;
  min-height: 21px;
  min-width: 40px;
}

.x02-get-verified .overlap-group71 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-right: 4.0px;
  margin-top: 25px;
  min-height: 80vh;
  padding: 44px 36px;
  width: 100%;
}


.x02-get-verified .text-27 {
  align-self: flex-start;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.x02-get-verified .text-28 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 15px;
  white-space: inherit;
  width: 100%;
}

.x02-get-verified .group-427319040 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-right: 6.0px;
  margin-top: 40px;
  min-height: 967px;
  width: 100%;
}

.x02-get-verified .group-427319046 {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(40%, 1fr));
  grid-gap: 10%;


}

.x02-get-verified .flex-col-1 {
  align-self: flex-start;
  margin-top: -1px;
  min-height: 143px;
  width: 260px;
}

.x02-get-verified .text-2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 20px;
  margin-top: 10px;
}

.x02-get-verified .group-427319033 {
  align-items: center;
  display: flex;
  gap: 11px;
  margin-left: 3px;
  margin-top: 31px;
  min-width: 226px;
}

.x02-get-verified .overlap-group {
  align-items: flex-start;
  background-color: #23bea2;
  border-radius: 15px;
  display: flex;
  height: 30px;
  min-width: 30px;
  padding: 9.5px 7.5px;
}

.x02-get-verified .icon-check_mark {
  height: 11px;
  width: 15px;
}

.x02-get-verified .this-will-be-the-benefit {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 3.0px;
  min-height: 21px;
  min-width: 183px;
}

.x02-get-verified .group-427319036 {
  align-items: center;
  display: flex;
  gap: 11px;
  margin-left: 3px;
  margin-top: 28px;
  min-width: 226px;
}


.x02-get-verified .group-42731903 {
  align-items: center;
  display: flex;
  flex: 1 1 160px;
  gap: 11px;
  min-width: 226px;
  margin: 0px 10px 10px 0px;
}

.x02-get-verified .group-427319047 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 100%;
  gap: 11px;
  margin: 0px 10px 10px 0px;
}


.x02-get-verified .text-form-control{
  align-items: flex-start;
  background-color: var(--zumthor);
  border-radius: 50px;
  display: flex;
  height: 49px;
  padding: 13px 29px;
  width: -webkit-fill-available;
  border-width: 0px;
}



.x02-get-verified .file-form-label{
  background: #EDF4FC;
  border: 1.5px dashed #AECBF7;
  border-radius: 2px;
  align-items: flex-start;
  background-color: var(--zumthor);
  display: flex;
  height: 121px;
  background-size: cover;
  background-position: center center;
  width: -webkit-fill-available;
  
}
.x02-get-verified .file-form-label-profile{
  background: #EDF4FC;
  border: 1.5px dashed #AECBF7;
  border-radius: 2px;
  align-items: flex-start;
  background-color: var(--zumthor);
  display: flex;
  height: 220px;
  background-size: cover;
  background-position: center center;
  width: 150px;
  
}


.placeholder{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover{
  background: #E0E0E0
}
.x02-get-verified .file-form-control{
  opacity: 0;
   position: absolute;
   z-index: -1;
}


.x02-get-verified .flex-row-1 {
  align-items: center;
  display: flex;
  gap: 84px;
  height: 274px;
  margin-left: 2px;
  margin-top: -1px;
  min-width: 1024px;
}

.x02-get-verified .flex-col-2 {
  min-height: 274px;
  width: 470px;
}

.x02-get-verified .group-427319039 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 46px;
  min-height: 82px;
  width: 470px;
}

.x02-get-verified .text-29 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .overlap-group-1 {
  align-items: flex-start;
  background-color: var(--zumthor);
  border-radius: 50px;
  display: flex;
  height: 49px;
  min-width: 466px;
  padding: 13px 29px;
}

.x02-get-verified .group-427319041 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 40px;
  min-height: 82px;
  width: 470px;
}

.x02-get-verified .text-30 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .group-427319040-1 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 60.0px;
  min-height: 82px;
  width: 470px;
}

.x02-get-verified .text-31 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .group-427319042 {
  align-items: flex-start;
  border-radius: 7.3px;
  display: flex;
  gap: 84px;
  height: 213px;
  margin-left: 2px;
  min-width: 1022px;
}

.x02-get-verified .flex-col-3 {
  gap: 29px;
  margin-top: -1px;
  min-height: 214px;
  width: 470px;
}

.x02-get-verified .text-32 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 21px;
  margin-top: 15px;
}

.x02-get-verified .group-427319043 {
  align-items: flex-start;
  border-radius: 7.3px;
  display: flex;
  flex-direction: column;
  gap: 23px;
  min-height: 164px;
  width: 470px;
}

.x02-get-verified .overlap-group-2 {
  align-items: flex-end;
  background-color: var(--zumthor);
  border-radius: 7.3px;
  display: flex;
  height: 121px;
  min-width: 466px;
  padding: 48px 86px;
}

.x02-get-verified .first-name {
  letter-spacing: 0;
  line-height: normal;
  min-height: 21px;
  min-width: 289px;
}

.x02-get-verified .group-427319044 {
  align-items: flex-start;
  align-self: flex-end;
  border-radius: 7.3px;
  display: flex;
  flex-direction: column;
  gap: 23px;
  min-height: 164px;
  width: 470px;
}


.x02-get-verified .group-427319043-1 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  min-height: 82px;
  width: 470px;
}

.x02-get-verified .text-33 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .group-427319044-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13px;
  min-height: 82px;
  width: 470px;
}

.x02-get-verified .text-34 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .overlap-group3-1 {
  align-items: flex-start;
  background-color: var(--zumthor);
  border-radius: 50px;
  display: flex;
  gap: 13px;
  height: 49px;
  min-width: 466px;
  padding: 12px 20px;
}

.x02-get-verified .icoutline-credit-card {
  height: 24px;
  width: 24px;
}

.x02-get-verified .text-35 {
  align-self: center;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 1.0px;
  min-width: 185px;
  white-space: nowrap;
}

.x02-get-verified .flex-row-2 {
  align-items: flex-end;
  display: flex;
  height: 82px;
  min-width: 876px;
}

.x02-get-verified .group-427319045 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  min-height: 82px;
  width: 199px;
}

.x02-get-verified .text-36 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .overlap-group-3 {
  align-items: flex-start;
  background-color: var(--zumthor);
  border-radius: 50px;
  display: flex;
  height: 49px;
  min-width: 197px;
  padding: 13px 29px;
}

.x02-get-verified .group-427319046-1 {
  align-items: flex-start;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-left: 66px;
  min-height: 82px;
  width: 201px;
}

.x02-get-verified .text-37 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .text-38 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 14px;
  margin-left: 91px;
  min-height: 24px;
  min-width: 319px;
}

.x02-get-verified .span-1 {
  color: var(--eerie-black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-weight: 400;
}

.x02-get-verified .submit-btn{
  display: flex;
  width: 100%;
  align-items: flex-end;
}

.x02-get-verified .overlap-group6 {
  align-items: center;
  margin-left: auto;
  background-color: var(--eerie-black);
  border-radius: 50px;
  display: flex;
  height: 49px;
  margin-right: 14px;
  padding: 13px 53px;
  cursor: pointer;
  width: auto;
  overflow: hidden !important;
}

.x02-get-verified .first-name-3 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 21px;
}

.x02-get-verified .flex-col-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}


</style>
