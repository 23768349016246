var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.bReload,staticClass:"sidemenu"},[_c('div',{staticClass:"overlap-group6"},[_c('div',{staticClass:"group-427318973"},[_c('img',{staticClass:"mobile-show",staticStyle:{"width":"24px","height":"24px","align-self":"center","margin-left":"8px"},attrs:{"src":_vm.static_fn('img/noun-cross-1707162-1-1@2x.png')},on:{"click":_vm.hideSideMenu}})]),_c('img',{staticClass:"line-205",attrs:{"src":_vm.static_fn('img/line-204-7@2x.png')}}),_c('div',{staticClass:"x1-overlap-group6"},[(!_vm.user)?_c('div',{staticClass:"group-427318968-x1",on:{"click":_vm.showLogin}},[_c('img',{staticClass:"home-2",attrs:{"src":_vm.static_fn('img/ic_login.png')}}),_vm._m(0)]):_vm._e(),(!_vm.user)?_c('a',{staticClass:"group-427318968-x1",attrs:{"href":"/accounts/signup"}},[_c('img',{staticClass:"home-2",attrs:{"src":_vm.static_fn('img/ic_signup.png')}}),_vm._m(1)]):_vm._e(),_c('div',{staticClass:"group-427318968-x1 comment-action",on:{"click":_vm.aboutus}},[_c('img',{staticClass:"home-2",attrs:{"src":_vm.static_fn('img/ic_about.png')}}),_vm._m(2)]),_c('div',{staticClass:"group-427318968-x1 comment-action",on:{"click":_vm.howto}},[_c('img',{staticClass:"home-2",attrs:{"src":_vm.static_fn('img/ic_howto.png')}}),_vm._m(3)]),(_vm.user)?_c('a',{staticClass:"group-427318968-x1",attrs:{"href":_vm.contact}},[_c('img',{staticClass:"home-2",attrs:{"src":_vm.static_fn('img/ic_contact.png')}}),_vm._m(4)]):_vm._e(),(_vm.user)?_c('a',{staticClass:"group-427318968-x1",attrs:{"href":"/accounts/logout"}},[_c('img',{staticClass:"home-2",attrs:{"src":_vm.static_fn('img/ic_signup.png')}}),_vm._m(5)]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-18 valign-text-middle inter-normal-black-17px"},[_c('span',[_vm._v("Login")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-18 valign-text-middle inter-normal-black-17px"},[_c('span',[_vm._v("Sign Up")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-18 valign-text-middle inter-normal-black-17px"},[_c('span',[_vm._v("About us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-18 valign-text-middle inter-normal-black-17px"},[_c('span',[_vm._v("How it works")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-18 valign-text-middle inter-normal-black-17px"},[_c('span',[_vm._v("Contact us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-18 valign-text-middle inter-normal-black-17px"},[_c('span',[_vm._v("Sign Out")])])
}]

export { render, staticRenderFns }