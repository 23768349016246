<template>
  <div class="body" @click="touchHandler">
    <p class="profile-text-21 valign-profile-text-middle inter-bold-eerie-black-30px">
      <span
        ><span class="inter-bold-eerie-black-30px">Your search for {{search}} returned {{ totalCount }} results</span>
      </span>
    </p>
    <PostList category="video" :comp="comp"
              :numrows="1" :rowlen="4" :mrowlen="4" :ignoreCategory="true"
              framekls="profile-x1" :arrowMore="true" :isMobile="isMobile" 
              :urlp="{'app': 'posts', 'view': query}" :rparams="{'q': query}"
              :viewMore="true">
    </PostList>
  </div>
</template>

<script>
import PostList from './PostList.vue';
import PreYoutube from './PreYoutube.vue';
import { mapMutations } from "vuex";

export default {
  name: 'ProfileRecipe',
  components: {
    PostList,
  },
  props: {
    'isMobile': Boolean,
    'touchHandler': { type: Function },
    'query': String,
  },
  data() {
    return {
      comp: PreYoutube,
      totalCount: 0,
    };
  },
  watch: {
    
  },
  methods:{
    ...mapMutations(["setViewContext"]),
  },
  created(){
    this.setViewContext("search");
  }
}
</script>
<style scoped>

</style>
