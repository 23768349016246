<template>
  <div class="body wrap-static">
    <div class="container clear-top">
      <div class="col-md-12 main_div user-stories">
        <div class="how-banner">
          <img :src="static_fn('img/howto.png')"/>
          <select name="lang-sel">
            <option value="jn" selected>日本</option>

            <option value="ru">русский</option>

            <option value="cn">普通话</option>

            <option value="ur">اردو</option>

            <option value="hi">हिन्दी</option>

            <option value="sp">Español</option>

            <option value="fr">Français</option>

            <option value="bn">বাংলা</option>

            <option value="ar">العربية</option>

            <option value="de">Deutsch</option>

            <option value="it">italiano</option>

            <option value="en">English</option>

            <option value="fa">فارسی</option>

            <option value="KO">한국인</option>

            <option value="in">bahasa Indonesia</option>

            <option value="MS">Malaysia</option>

            <option value="sw">kiswahili</option>

            <option value="ta">தமிழ்</option>

            <option value="PT">Português</option>

            <option value="te">తెలుగు</option>

            <option value="tr">Türkçe</option>

            <option value="vi">Tiếng Việt</option>

          </select>
        </div>
        <div id="appaboutus">
          <div class="carousel-cont" v-for="post in items" :key="post" ref="cars">
            <AboutUsVideo :post="post" :commentimg="commentimg" />
          </div>
          <div class="way-container" v-if="nextPageURL !== '' || loading == 'E'">
            <Loading :loading="true" :loaderror="loading == 'E'" ref="waybottom" class="way-div way-bottom" v-waypoint="{ active: true, callback: onWaypointDown }"></Loading>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <h4 class="text-center">&copy; Khal: All rights reserved </h4>
    </footer>
  </div>
</template>

<script>
/* eslint-disable no-console */
import Loading from './Loading';
import AboutUsVideo from './AboutUsVideo';
import ItemList from './ItemList';

const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });

export default {
  name: 'Howto',
  extends: ItemList,
  components: {
    Loading,
    AboutUsVideo
  },
  computed: {
    apiurl() {
      return `aboutus-video-list/${this.lang}`;
    },
  },
  props: {
    'urlp': {
      type: Object,
      default: function() {
        return {'app': 'posts',
                'view': 'list'};
      }
    },
    lang: {
      type: String,
      default: function() {
        return "en";
      }
    },
  },
  data() {
    return {
      waytopper: null,
      showcomment: "false",
      commentimg: '',
    };
  },
  mounted() {
    //this.apiItems = this.api.getGeneric.bind(this.api);
    $('select[name="lang-sel"]').val(this.lang);
    var that = this;
    $('select[name="lang-sel"]').change(function() {
      that.lang = $(this).val();
      that.refresh();
    });
  },
  updated() {
    this.api.map_prom.then(() => {
      log.info ("Comment img");
      this.commentimg = this.api.mkstatic("comment.png");
      log.info ("Comment img done");
    });
  },
  methods: {
    refresh() {
      let r = this.$router.resolve({
        name: this.$route.name, // put your route information in
        params: {lang: this.lang}, // put your route information in
      });
      window.location.href = r.href;
      window.location.reload();
    }
  }
}
</script>
<style scoped>
</style>
