<template>
<div v-if="getLoginShow" class="login modal show">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex align-center">
        <h5 class="modal-title inter-bold-black-25px">Login</h5>
        <div class="overlap-group1-5" @click="hideLogin">
          <div class="ellipse-54"></div>
          <img class="noun-cross-1707162-1" :src="static_fn('img/noun-cross-1707162-1-1@2x.png')" alt="noun-cross-1707162 1" />
        </div>
      </div>
      <div v-html="html">
      </div>
    </div>
  </div>
</div>
</template>
<script>
/* eslint-disable no-console */

import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
  name: "Login",
  data: () => {
    return {
      username: "",
      password: "",
      html: "",
    };
  },
  created() {
    console.log ("Current window " + window.location.path + " Routes " + this.$route.path);
    this.api.get("/api/accounts/login-form", {'next': "/#" + this.$route.path}).then((data) => this.html = data);
  },
  mounted() {
    this.setTimer();
  },
  computed: {
    ...mapGetters(["getLoginShow"]),
  },
  methods: {
    ...mapMutations(["setUser", "setToken", "hideLogin", "showLogin"]),
    ...mapActions(["loginUser"]),
    setTimer() {
      setTimeout(() => this.showLogin(), 120000);
    },
    login(e) {
      e.preventDefault();
      this.loginUser({username: this.username,
                      password: this.password});
    },
  }
}
</script>
