<template>
  <div class="body wrap-static">
    <div class="container clear-top">
      <div class="col-md-12 main_div user-stories">
        <div class="how-banner">
          <img src="https://i.imgur.com/XbduNJB.jpg" />
        </div>
        <div>
          <div class="carousel-cont">
            <div class="recipe-detail-cont">
              <div class="iframe-wrapper">
                <iframe title="YouTube video player" src="https://www.youtube.com/embed/Xs6c5cvvq84" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
              </div>
              <h2>Interview of Founder/CEO Samir Tendulkar</h2>
              <hr/>
            </div>
          </div>
          <div class="carousel-cont">
            <div class="recipe-detail-cont">
              <div class="iframe-wrapper">
                <iframe title="YouTube video player" src="https://www.youtube.com/embed/4uvjdv7_bNg" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
              </div>
              <h2>Khal for professional chefs and home chefs</h2>
              <hr/>
            </div>
          </div>
          <div class="carousel-cont">
            <div class="recipe-detail-cont">
              <div class="iframe-wrapper">
                <iframe title="YouTube video player" src="https://www.youtube.com/embed/dEkbnD76NTU" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
              </div>
              <h2>How to Add your recipes to Khal</h2>
              <hr/>
            </div>
          </div>
          <div class="carousel-cont">
            <div class="recipe-detail-cont">
              <div class="iframe-wrapper">
                <iframe title="YouTube video player" src="https://www.youtube.com/embed/WZu53lVr_uE" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
              </div>
              <h2>Khal: A few questions answered + Request to explain Khal in your native language by Samir Tendulkar</h2>
              <hr/>
            </div>
          </div>
          <div class="carousel-cont">
            <div class="recipe-detail-cont">
              <div class="iframe-wrapper">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Vcy7IcyIyuU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <h2>Khal Testimonials</h2>
              <hr/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <h4 class="text-center">&copy; Khal: All rights reserved </h4>
    </footer>
  </div>
</template>

<script>
/* eslint-disable no-console */
export default {
  name: 'AboutUs',
  components: {
  },
  computed: {
  },
  props: {
    
  },
  data() {
    return {
     
    };
  },
  mounted() {
   
  },
  updated() {
  
  },
  methods: {
  }
}
</script>
<style scoped>
  .how-banner {
  max-width: unset;
  }
</style>
