<template>
<div class="post-overlap-group6">
  <header class="header story-detail">
    <div class="recipe-cont">
      <div class="video recipe-imgs" ref="poster">
        <div style="width: 100%; position: absolute; min-height: 100%;">
          <component :is="subcomp" :post="post" :width="postImg.width" :height="postImg.height">
          </component>
        </div>
      </div>
    </div>
    <div class="recipe-msgs-cont">
      <div class="post-flex-col-1 post-flex-col-3 recipe-msgs">
        <div class="txt">
          <p class="dalle-antiche-ricett valign-post-text-middle inter-normal-black-17px">
            <span class="inter-normal-black-17px"><div v-html="post.message"></div></span>
          </p>
          <div class="posted-3-hours-ago valign-post-text-middle inter-normal-black-17px">
            <span><span class="inter-normal-black-17px">Posted: {{post.created_at}}</span> </span>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>
</template>

<script>
/* eslint-disable no-console */

import LazyCarousel from './LazyCarousel';
import YoutubeDetail from './YoutubeDetail';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'PostDetail',
  components: {
    LazyCarousel,
    YoutubeDetail,
},
  props: [
    'post',
    'category',
  ],
  computed: {
    user () {
      return this.getUserInfo()
    },
    mainImg: function() {
      return this.post.preps[0].thumbnail;
    },
  },
  data() {
    return {
      scrollOff: 0,
      origTop: null,
      selectedPost:0,
      expand: false,
      feature_html: this.post.feature_html,
      feature: false,
      collapsed: true,
      reload: true,
      modalOpen: false,
      textHeight: (this.post.comment_form) ? "70%" : "90%",
      commentHeight: "300px",
      showcomment: "false",
      showShare: false,
      winWidth: window.innerWidth,
      postImg: {
        width: 0,
        height: 0
      },
      users: [],
      subcomp: {
        'video': YoutubeDetail,
        'photo': LazyCarousel
      }[this.category],
      userContext: 'users-like',
      userTitle: 'People who like this',
    };
  },
  mounted() {
    var that = this;
    this.$nextTick(function() {
      console.log ("Post img mounted");
      that.postImg = {
        width: that.$refs.poster.clientWidth,
        height: that.$refs.poster.clientHeight
      }
      console.log ("Post img mounted " + that.$refs.poster.clientWidth);
    });    
  },
  beforeDestroy() {
  },
  updated() {
  },
  methods: {
    ...mapGetters(['getUserInfo']),
    ...mapMutations(['showLogin']),
    toggleCollapsed(){
      this.collapsed = !this.collapsed;      
    },
    onHideDetail() {
      this.selectedPost = 0;
      this.showShare = false;
    },
    onModalOut() { 
      setTimeout(this.scroller.bind(this), 100);
    },
    scroller() {
      document.documentElement.scrollTop = document.body.scrollTop = this.scrollOff + this.origTop;
      this.scrollOff = 0;
    },
  }
}
</script>
<style scoped>
.left > div, .right > div {
  cursor: pointer;
}

.recipe-msgs-cont {
  overflow: scroll;
}
</style>
