<template>
  <div>
    <a class="group-427318968-x1 menu-entry with-subs" @click="toggleSubs">
      <img class="book-svgrepo-com-3-1"
           :src="static_fn(img)" />
      <div class="text-14 valign-text-middle inter-normal-eerie-black-17px">
        <span><span class="inter-normal-eerie-black-17px">{{title}} ({{recipe_count}})</span> </span>
      </div>
    </a>
    <div class="submenu" v-if="open">
      <SubMenuEntry v-for="entry in entries" :key="entry.name" :entry="entry"
                    :which="which" :routeName="routeName"
                    :tab="tab">
      </SubMenuEntry>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-console */
import SubMenuEntry from './SubMenuEntry';

import { mapMutations, mapGetters } from 'vuex';
export default {
  name: 'SubMenu',
  components: {
    SubMenuEntry
  },
  data() {
    return {
      //open: this.summary && this.$route.params.ctx == this.which,
      open: this.summary && this.view_context() == this.which,
      entries: [
        {
          name: 'photo',
          num: this.summary ? this.summary.num_photos : 0,
          title: 'Photo Recipes',
          img: 'img/image-1-1@2x.png',
        },
        {
          name: 'video',
          num: this.summary ? this.summary.num_videos : 0,
          title: 'Video Recipes',
          img: 'img/g7433-1@2x.png', //img/play-svgrepo-com-1-7@2x.png',
        },
        {
          name: 'quickie',
          num: this.summary ? this.summary.num_quickies : 0,
          title: 'Quickies',
          img: 'img/play-svgrepo-com-1-7@2x.png',
        }
      ]                  
    }
  },
  props: {
    'summary': {type: Object},
    'img': {type: String},
    'title': {type: String},
    'which': {type: String},
    'routeName': {
      type: String,
      default: function() {
        return 'user-posts';
      }
    },
    'tab': {type: String},
  },
  computed: {
    recipe_count() {
      return this.summary ? this.summary.num_videos + this.summary.num_photos + this.summary.num_quickies : 0;
    },
  },
  methods: {
    ...mapMutations(["showLogin"]),    
    ...mapGetters(["view_context"]),
    toggleSubs() {
      if (this.recipe_count) { 
        this.open = !this.open
      }
      else {
        this.showLogin();
      }
    },
  }
}

</script>
