<template>
  <div class="sidebar">
    <div class="overlap-group6">
      <div class="group-427318973">
        <img class="clear-logo-1" :src="static_fn('img/clear-logo-1-6@2x.png')" @click="goHome"/>
        <div class="text-container" @click="goHome">
          <div class="text-13 valign-text-middle inter-bold-black-17px">
            <span><span class="inter-bold-black-17px">Make a Cooking Profile</span> </span>
          </div>
          <p class="text-17 valign-text-middle inter-normal-black-15px">
            <span><span class="inter-normal-black-15px">Show the world your cooking skills</span> </span>
          </p>
        </div>
        <img class="mobile-show" style="width: 24px; height: 24px; align-self: center; margin-left: 8px;" :src="static_fn('img/noun-cross-1707162-1-1@2x.png')" @click="hideSideBar"/>
      </div>
      <img class="line-205" :src="static_fn('img/line-204-7@2x.png')" />
      <div class="x1-overlap-group6">
        <a href="/" :class="'overlap-group-3 menu-home menu-entry ' + ifactive('home')">
          <img class="home-2" :src="static_fn('img/home-2-1@2x.png')" />
          <div class="text-18 valign-text-middle inter-normal-eerie-black-17px">
            <span><span class="inter-normal-eerie-black-17px">Home</span> </span>
          </div>
        </a>
        <SubMenu title="My Recipes" :summary="my" img="img/book-svgrepo-com--3--1-2@2x.png" which="user">
        </SubMenu>
        <SubMenu title="I Want to Make" :summary="want" img="img/star-svgrepo-com--2--1-4@2x.png" which="make">
        </SubMenu>
        <!-- <div class="group-427318971 menu-entry">
          <img
            class="kitchen-pack-cooking-svgrepo-com-1"
            :src="static_fn('img/kitchen-pack-cooking-svgrepo-com-1-2@2x.png')"
            />
          <div class="text-20 valign-text-middle inter-normal-eerie-black-17px">
            <span><span class="inter-normal-eerie-black-17px">Others Recipes<br/>I Made ({{num_made}})</span> </span>
          </div>
        </div> -->
        <img class="line-204-1" :src="static_fn('img/line-204-7@2x.png')" />
      </div>
      <div class="x2-overlap-group6" v-if="!user">
        <div class="flex-row-1">
          <div class="flex-col">
            <div class="inter-normal-eerie-black-17px-2">
              <span class="inter-normal-eerie-black-17px-2">Sign in to see your content, to like, comment and personalize Khal for yourself</span>
            </div>
            <a @click="showLogin" class="btn-signin">
              <img :src="static_fn('img/line-204-7@2x.png')" class="user-multiple-1-overlap-group2">
              <div class="text-16 valign-text-middle inter-normal-eerie-black-17px">
                <span class="inter-normal-eerie-black-17px">Sign in</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      
      <div class="x2-overlap-group6">
        <div>
          <div :class="'overlap-group-3 menu-home menu-entry '  + ifactive('my-groups')" >
            <div class="'chef-i-follow valign-text-middle inter-semi-bold-eerie-black-20px">
              <span><span class="inter-semi-bold-eerie-black-20px">MyGroups</span> </span>
            </div>
          </div>
          <SidebarBlock title="My Groups" numDefault="3" comp="group"
                        :items="groups" :total="num_groups" all="/mygroups"
                        zero_text="You are the member of 0 cooking groups. Join groups to see new recipes in your favorite groups"
                        signin_text="Sign in to see your recipe groups and join new groups" :key="$route.params.slug">
          </SidebarBlock>
        </div>
        <img class="line-204-2" :src="static_fn('img/line-204-8@2x.png')" />
        <div>
          <div :class="'overlap-group-3 menu-home menu-entry '  + ifactive('followedbyme')" >
                      
            <div :class="'chef-i-follow valign-text-middle inter-semi-bold-eerie-black-20px menu-entry ' + ifactive('followedbyme')" >
              <span><span class="inter-semi-bold-eerie-black-20px">Chefs I Follow</span> </span>
            </div>
          </div>
          <SidebarBlock title="Chefs I follow" numDefault="2" comp="follow"
                        :items="following" :total="num_following" all="/followedbyme"
                        zero_text="You are following 0 chefs. When you follow chefs you get a notification when they add new recipes, plus it makes your chef happy"
                        signin_text="Sign in to follow chefs you know and chefs you like" :key="$route.params.username">
          </SidebarBlock>
        </div>
      </div>
    </div>
  </div>        
</template>

<script>
/* eslint-disable no-console*/
import SubMenu from './SubMenu';
import { mapGetters, mapMutations } from 'vuex';
import SidebarBlock from './SidebarBlock';

export default {
  name: 'sidebar',
  components: {
    SubMenu,
    SidebarBlock
  },
  props: ['hideSideBar'],
  data() {
    return {
    };
  },
  computed: {
    user () {
      return this.getUserInfo()
    },
    following() {
      let retval = !this.user ? [] : this.user.following.map(item => ({...item,
                                                                 is_active: (["profile-detail", "profile"].includes(this.$route.name) && this.$route.params.username== item.username)}));
      return retval;
    },
    groups() {
      let retval = !this.user ? [] : this.user.groups.map(item => ({...item,
                                                              is_active: (["group-members", "group-posts"].includes(this.$route.name) && this.$route.params.slug== item.slug)}));
      return retval;

    },
    my() {
      return this.user ? this.user.my : null
    },
    want() {
      return this.user ? this.user.want : null
    },
    num_made() {
      return this.user ? this.user.num_made : null
    },
    num_groups() {
      return this.user ? this.user.num_groups : null
    },
    num_following() {
      return this.user ? this.user.num_following : null
    },
    badge: function () {
      return this.static_fn(this.user.full_badge_image || 'img/image-2-2@2x.png');
    },
    contact () {
      return `/contact/${this.user.username}/help_request`;
    },
    active_tab() {
      if (this.$route.path == "/" || this.$route.params.ctx == 'feeds') {
        return "home"
      }
      console.log ("Active tab is " + this.$route.name);
      return this.$route.name
    },
  },
  created() {
  },
  mounted() {
  },
  updated() {
  },
  methods: {
    ...mapMutations(["showLogin"]),
    ...mapGetters(['getUserInfo', 'view_context']),
    goHome() {
      this.$router.push("/");
    },
    ifactive(tab) {
      return (tab == this.active_tab) ? "active" : "";
    },
  }
}

</script>

<style scoped>

.flex-col
{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.menu-entry{
  cursor: pointer;
}

.flex-row
{
  display: flex;
  gap: 4px;
  flex-direction: row;
}

.menu-entry.active .inter-semi-bold-eerie-black-20px {
    color: var(--white);
}

.x2-overlap-group6 {
    margin-top:10px;
}

</style>
