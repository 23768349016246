<template>
<div v-if="pk > 0" class="modal" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalTitle" aria-hidden="true"
     ref="modal">
  <div ref="nbody" class="nbody" tabindex="0">
    <div class="container">
      <div class="quickie-mobile-close">
        <button type="button"
                class="VueCarousel2-navigation-button modal-close" @click="hideModal">
          <img :src="closeImg"/>
        </button>
      </div>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body detail-body">
            <div class="detail-cont" :key="reloadAll">
              
              <div v-if="ready" class="recipe-detail-cont" :id="`rec-detail-${pk}`" :style="isMobile ? { display: 'flex', flexDirection: 'column'} : {}">
                <div class="recipe-cont">
                  <div class="recipe-imgs recipe-cell">
                    <Quickie :key="videoHeight"
                              :posts="curritems" :sizes="sizes"
                              :index="curridx"
                              :height="videoHeight"
                              :width="videoWidth"
                              :first="firstpost"
                              :category="category"
                              @closed="hideModal"
                              ref="videoFile"
                              @vidloaded="vidLoadDone"
                              @failedload="failedLoadDone"
                              @loaded="imgLoadDone">
                    </Quickie>
                  </div>
                </div>
                <VideoDetail v-if="!isMobile" :sizes="sizes"
                              v-bind:index="curridx"
                              :posts="curritems"
                              :category="category"
                              ref="detail" @imgload="imgLoadDone"
                              @vidload="vidLoadDone"
                              @failedload="failedLoadDone"
                              v-bind:height="videoHeight"
                              v-bind:width="videoWidth"
                              @closed="hideModal"
                              :id="`PD-${pk}`">
                </VideoDetail>
              </div>
              <button type="button"
                      class="VueCarousel2-navigation-button modal-close" @click="hideModal"
                      >x</button>
              <inset-navigation v-if="navigable" slot="navigation" clickTargetSize="20" lrSize="-20">
              </inset-navigation>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

/* eslint-disable no-console */

import Quickie from './Quickie';
import VideoDetail from './VideoDetail.vue';
import ItemList from './ItemList';
import BaseModal from './BaseModal';
import InsetNavigation from './InsetNavigation';

export default {
  mixins: [ItemList, BaseModal],
  name: 'PostModal',
  components: {
    Quickie,
    VideoDetail,
    InsetNavigation
  },
  props: {
    'category': {
      type: String,
      default: function() {
        return 'quickie';
      }
    },
    'pk': Number,
    'idx': Number,
    'urlp': {
      type: Object,
      default: function() {
        return {'app': 'posts',
                'view': 'detail-list'};
      }
    },
    "isMobile": Boolean
  },
  computed: {
    width: { 
      get: () => {
        var win = window,
            doc = document,
            docElem = doc.documentElement,
            body = doc.getElementsByTagName('body')[0],
            y = win.innerWidth|| docElem.clientWidth || body.clientWidth;
        return y;
      }
    },
    height: { 
      get: () => {
        var win = window,
            doc = document,
            docElem = doc.documentElement,
            body = doc.getElementsByTagName('body')[0],
            y = win.innerHeight|| docElem.clientHeight || body.clientHeight;
        return y;
      },
    },
    curritems: {
      get: function() {
        
        //var ret = (this.videoHeight || !this.items.length || this.width < this.windowCutoff) ? this.items.filter(x => x) : [this.items[0]];
        var ret = this.items.filter(x => x);
        console.log ("curritems returning " + ret.length);
        return ret;
        //return (this.videoHeight || !this.items.length) ? this.items.filter(x => x) : [this.items[0]];
      }
    },
    firstpost: {
      get: function() {
        console.log ("Get first " + this.curridx + " from " + this.curritems.length);
        
        return this.curritems[this.curridx]; //this.curritems[0];
      }
    },
    canAdvanceForward: {
      get: function() { 
        return this.curridx < this.curritems.length - 1 || this.nextPageURL != '';
      }
    },
    canAdvanceBackward: {
      get: function() {
        return this.curridx > 0 || this.previousPageURL != '';
      }
    },
  },
  provide() {
    return { 
      carousel: this
    };
  },
  data() {
    return {
      sizes:'',
      first: false,
      playingVideo: null,
      curridx: 0,
      isTouch: typeof window !== "undefined" && "ontouchstart" in window,
      dragStartX: 0,
      dragging: false,
      startTime: null,
      ready: false,
      autoLoad: true, //false
      videoWidth: 0, 
      videoHeight: 0,
      navigable: false,
      windowCutoff: 600,
      dire: 'forward',
      reloadAll: true,
      trueidx: -1,
      runID: null,
      loadingNext: false,
      autoNext: false,
      username: this.$route.query.username
    };
  },
  updated() {
    this.$nextTick(function() {
      if (this.ready && this.first) {
        this.first = false;
        console.log ("Play from modal ");
        //this.$refs.detail[this.curridx].play();
        //this.$refs.detail.play();
      }
    });
  },
  mounted() {
    console.log ("This width ** " + this.width + " cutofff " + this.windowCutoff + " FS NUA " + navigator.userAgent)
    if (this.isMobile) { 
      this.videoWidth = this.width;
      this.videoHeight = this.height;
    //   this.reloadAll = !this.reloadAll;
    }
    this.apiItems = this.api.getPostDetailList.bind(this.api, this.pk);
    window.addEventListener(this.isTouch ? "touchstart": "mousedown", this.onDrag, true);
    window.addEventListener(this.isTouch ? "touchend" : "mouseup", this.onEnd,true);
    //this.getItems();
    this.closeImg = this.api.mkstatic("quick_mob_close.png");
  },
  beforeDestroy() {
    /* Close all the modals */
    let buttons = this.$el.querySelectorAll(".btn-intermodal");
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].click();
    }
    
    window.removeEventListener(this.isTouch ? 'touchstart': 'mousedown', this.onDrag);
    window.removeEventListener(this.isTouch ? 'touchend' : 'mouseup', this.onEnd);
    window.removeEventListener('resize', this.handleResize);

  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  methods: {
    addURLParams() {
      this.params.pk = this.pk;
      this.params.per_page = 4;
      this.params.username = this.username;
    },
    postDown () {
      this.loadingNext = false;
      if (this.autoNext) {
        this.handleNavigation('forward');
      }
      this.autoNext = false;
    },
    postUp () {
      console.log ("Post up for " + this.pk);
      for (let i = 0; i < this.items.length; i++) {
        if (this.pk == this.items[i].pk) {
          this.curridx = i - 1;
          break;
        }
      }
      this.first = true;
      this.reloadAll = !this.reloadAll;
    },
    postGetItems() {
      this.checkPage();
      for (let i = 0; i < this.items.length; i++) {
        if (this.pk == this.items[i].pk) {
          if (this.isMobile) {
            this.curridx = i;
          }
          else { 
            this.trueidx = i;
            this.curridx = 0;
          }
          break;
        }
      }
      console.log ("Nav index is " + this.curridx + " OF " + this.items.length);
      if (!this.first) {
        this.first = true;
        this.ready = true;
      }
    },
    preUp () {
    },
    preDown() {
    },
    myEventHandler() {
      console.log ("Handle resize ");
      if (!this.isMobile && this.width < this.windowCutoff){
        this.videoWidth = this.width;
        this.videoHeight = this.height;
        this.reloadAll = !this.reloadAll;
      }
      else if (this.isMobile && this.width > this.windowCutoff) {
        this.videoWidth = 0;
        this.videoHeight = 0;
        this.reloadAll = !this.reloadAll;
      }
    },
    checkPage() {
    },
    imgLoadDone (height, width) {
      console.log ("Img load done " + height + " x " + width + " TRUE: " + this.trueidx);
      this.videoHeight = height;
      this.videoWidth = width;
      if (this.trueidx > 0) { 
        this.curridx = this.trueidx;
        this.trueidx = -1;
        this.first = true;
      }
    },
    vidLoadDone () {
      console.log ("Vid load done" + this.curridx);
      this.navigable = true;
    },
    failedLoadDone () {
      console.log ("Failed load ");
      this.hideModal();
      //this.curritems[this.curridx] = null;
      if (this.dire == 'forward' && this.curridx == this.curritems.length)
        this.dire = 'backward'
      else if (this.dire == 'backward' && this.curridx == 0)
        this.dire = 'forward'
      this.handleNavigation(this.dire); //advancePage(dire);
    },
    handleNavigation(dire) {
      var idx = this.curridx;
      if (dire == 'forward' && this.curridx < this.curritems.length - 1) {
        idx += 1;
      }
      else if (dire == 'backward' && this.curridx > 0) {
        idx -= 1;
      }
      if (idx >= this.curritems.length - 2 && this.nextPageURL && !this.loadingNext) {
        this.loadingNext = true;
        this.getNextPage();
      }
      if (this.loadingNext && idx == this.curridx) {
        this.autoNext = true;
      }
      if (idx < 0 && this.previousPageURL) {
        console.log ("Getting previous page  index " + this.pk)
        this.pk = this.curritems[this.curridx].pk
        console.log ("Getting previous page " + this.pk)
        this.getPreviousPage();
      }
      console.log ("Handle navigation from " + this.curridx + " to " + idx);
      if (idx >= 0 && idx != this.curridx) {
        this.navigable = false;
        this.dire = dire;
        this.curridx = idx;
      }
    },
    onDrag(e) {
      this.startTime = e.timeStamp;
      this.dragging = true;
      this.dragStartX = this.isTouch ? e.touches[0].clientX : e.clientX;
      this.dragStartY = this.isTouch ? e.touches[0].clientY : e.clientY;
    },
    onEnd(e) {
      if (!this.navigable)
        return;
      const eventPosY =
        this.isTouch && e.changedTouches && e.changedTouches.length > 0
          ? e.changedTouches[0].clientY
          : e.clientY;
      const eventPosX =
        this.isTouch && e.changedTouches && e.changedTouches.length > 0
          ? e.changedTouches[0].clientX
          : e.clientX;
      const deltaX = this.dragStartX - eventPosX;
      const deltaY = this.dragStartY - eventPosY;
      let idx = this.curridx;
      let dire = null;
      if (deltaX > 50 || deltaY > 50) {
        dire = "forward";
        idx = Math.min(this.curridx + 1, this.curritems.length - 1);
      }
      else if (deltaX < -50 || deltaY < -50) {
        dire = "backward";
        idx = Math.max(this.curridx - 1, 0);
      }
      this.dragging = false
      this.startTime = null;
      if (idx != this.curridx) {
        this.handleNavigation(dire); //advancePage(dire);
      }
    },
  }
}
</script>
<style scoped>
#videoModal .container
{
  width: 100%;
}
.modal-dialog{
  max-width: max-content;
}
#videoModal .VueCarousel2-navigation-button.modal-close{
  right: -50px;
}
.detail-cont{
  overflow: overlay;
}

</style>

