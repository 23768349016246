<template>
  <div :key="reload" class="pre-image-div">
    <Loading :loading="error == true"></Loading>
    <img :src="image" :alt="`Image of-${post.title}`"
         :srcset="thumbs" class="pre-image-img"
         :sizes="sizes" style="object-fit: cover;"
         @click="emitClick" @error="loadError"
         @load="emitLoad">
  </div>
</template>
<script>
import Loading from './Loading';
const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });
  
export default{
  name: 'PreImage',
  mounted() {
    log.info ("Mounting image " + this.post.image + " Comp " + this.image);
  },
  components: {
    Loading
  },
  computed: {
    thumbs() {
      return this.post.thumbs || this.post.post_image.thumbs;
    },
    image() {
      return this.post.image || this.post.post_image.image;
    }
  },
  data() {
    return {
      reload: false,
      error: false,
      retries: 0
    };
  },
  props: [
    'post',
    'sizes'
  ],
  methods: {
    emitClick() {
      this.$emit("clicked");
    },
    emitLoad(ev) {
      if (!this.error)  {
        this.$emit("loaded", ev, this.post);
      }
    },
    loadError () {
      if (!this.error && this.retries < 1) { 
        this.error = true;
        this.reload = !this.reload;
        this.retries++;
        log.info ("Load error for " + this.post.pk);
        this.api.rePost(this.post.pk).then((data) => {
          log.info ("Redoing image for " + this.post.pk);
          this.post = data.data;
          this.error = false;
          this.reload = !this.reload;
        });
      }
      else if (this.retries >= 1) {
        this.$emit("loaderr");
      }
    },
  }
}
</script>
