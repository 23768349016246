<template>
<SideMenuPage v-if="user" :choices="tabs" :tab="tab"
              :cat="cat" :ctx="myctx"
              :key="user.follow_state">
  <template v-slot:header>
    <header class="profile-header mobile-hide">
      <div class="comment-action">
        <img class="profile-image" :src="user.profile_image" alt="avatar" style="background-image: url(/static/img/default-avatar.png)" onload="style.backgroundImage=''"/>
        <a href="/badges"><img class="image-3 image" :src="badge" /></a>
      </div>
      <div class="profile-flex-col">
        <div class="profile-text-4 valign-profile-text-middle inter-bold-eerie-black-40px">
          <span><span class="inter-bold-eerie-black-40px">{{user.full_name}}</span> <img v-if="user.gov_id_proof_status === 'Approved'" class="image-checkmark" src="../assets/mdi_check-decagram.png" /></span>
        </div>
        <div class="profile-group-427318998 inter-normal-eerie-black-17px-2">
          <img class="recommend-1" :src="static_fn('img/recommend-1-25@2x.png')" alt="recommend 1" />
          <div class="profile-text-5"><span class="inter-normal-eerie-black-17px-2">{{user.reputation}}</span></div>
          <img class="profile-line-249" :src="static_fn('img/line-249-22@2x.png')" alt="profile-line 249" />
          <img class="profile-line-250" :src="static_fn('img/line-250-8@2x.png')" alt="profile-line 250" />
          <img class="profile-icon-user" :src="static_fn('img/user--multiple-1-41@2x.png')" alt="profile-icon-user" />
          <div class="profile-text-7"><span class="inter-normal-eerie-black-17px-2">{{user.total_followers}}</span></div>
        </div>
      </div>
      <div v-if="meUser && meUser.username != username" @click="handleFollow" class="profile-overlap-group-1 comment-action" :key="follow_text">
        <div class="profile-text valign-profile-text-middle inter-normal-white-17px-2">
          <span><span class="inter-normal-white-17px-2">{{follow_text}}</span> </span>
        </div>
      </div>
      <a v-else href="/accounts/edit_profile" class="profile-overlap-group-1 comment-action" >
        <div class="profile-text valign-profile-text-middle inter-normal-white-17px-2">
          <span><span class="inter-normal-white-17px-2">Edit Profile</span> </span>
        </div>
      </a>
      <div class="profile-flex-col-1 profile-flex-col-4">
        <div class="profile-group-6879">
          <a v-if="user.facebook" :href="user.facebook"><img class="profile-facebook" :src="static_fn('img/facebook-f-19@2x.png')" alt="facebook" /></a>
          <a v-if="user.twitter" :href="user.twitter"><img class="profile-facebook" :src="static_fn('img/facebook-f-20@2x.png')" alt="twitter" /></a>
          <a v-if="user.youtube" :href="user.youtube"><img class="profile-facebook-f" :src="static_fn('img/facebook-f-21@2x.png')" alt="youtube" /></a>
          <a v-if="user.instagram" :href="user.instagram"><img class="profile-facebook" :src="static_fn('img/ic_instagram.png')" alt="instagram" /></a>
          <a v-if="user.tiktok" :href="user.tiktok"><img class="profile-facebook" :src="static_fn('img/ic_tiktok.png')" alt="tiktok" /></a>
          <a v-if="user.linkedin" :href="user.linkedin"><img class="profile-facebook-f" :src="static_fn('img/ic_linkedin.png')" alt="linkedin" /></a>
        </div>
        <div class="profile-joined-24-years-ago valign-profile-text-middle inter-normal-black-17px">
          <span><span class="inter-normal-black-17px">Joined: {{user.member_since}}</span> </span>
        </div>
      </div>
    </header>
    <div class="profile-nav_2 mobile-hide">
      <div class="profile-flex-row">
        <div class="profile-group-427319004">
          <img class="profile-phgender-male-fill" :src="static_fn('img/ph-gender-male-fill-7@2x.png')" alt="ph:gender-male-fill" />
          <div class="profile-text-8 inter-normal-eerie-black-17px-2">
            <span class="inter-bold-eerie-black-17px">Gender:</span
                                                               ><span class="inter-normal-eerie-black-17px-2">{{choice[user.sex]}}</span>
          </div>
        </div>
        <img class="profile-line-249-1" :src="static_fn('img/line-249-21@2x.png')" alt="profile-line 249" />
        <div class="profile-group-427319005">
          <img class="profile-icon-location_pin" :src="static_fn('img/fluent-location-28-regular-7@2x.png')" alt="profile-icon-location_pin" />
          <div class="profile-text-1 inter-normal-eerie-black-17px-2">
            <span class="inter-bold-eerie-black-17px">Location:</span
                                                                 ><span class="inter-normal-eerie-black-17px-2"> {{user.city}}, {{user.country}}</span>
          </div>
        </div>
        <img class="profile-line-250-1" :src="static_fn('img/line-250-7@2x.png')" alt="profile-line 250" />
        <div class="profile-group-427319006">
          <img class="profile-icon-heart" :src="static_fn('img/ph-heart-light-7@2x.png')" alt="profile-icon-heart" />
          <div class="profile-text-1 inter-normal-eerie-black-17px-2">
            <span class="inter-bold-eerie-black-17px">Cuisines I love:</span>
            <span class="inter-normal-eerie-black-17px-2"> {{ user.cuisines_i_love }}</span>
          </div>
        </div>
        <img class="profile-line-251" :src="static_fn('img/line-251-1@2x.png')" alt="profile-line 251" />
        <div class="profile-group-427319007">
          <img class="profile-phfork-knife" :src="static_fn('img/ph-fork-knife-7@2x.png')" alt="ph:fork-knife" />
          <div class="profile-text-9 inter-bold-eerie-black-17px">
            <span class="inter-bold-eerie-black-17px">Cuisines I Cook:</span>
            <span class="inter-normal-eerie-black-17px-2"> {{ user.cuisines_i_cook }}</span>
          </div>
        </div>
      </div>
      <img class="profile-line-204" :src="static_fn('img/line-204-22@2x.png')" alt="profile-line 204" />
    </div>
    <header class="profile-header mobile-show">
      <div class="comment-action">
        <img class="profile-image" :src="user.profile_image" alt="avatar" style="background-image: url(/static/img/default-avatar.png)" onload="style.backgroundImage=''"/>
        <a href="/badges"><img class="image-3 image" :src="badge" /></a>
      </div>
      <h1 class="profile-text-4 valign-text-middle inter-bold-eerie-black-25px" style="margin: 12px;">
        <span class="inter-bold-eerie-black-25px"> {{user.full_name}} {{user.last_name}}</span>
      </h1>
      <div class="group-6879">
        <a v-if="user.facebook" :href="user.facebook"><img class="profile-facebook" :src="static_fn('img/facebook-f-19@2x.png')" alt="facebook" /></a>
        <a v-if="user.twitter" :href="user.twitter"><img class="profile-facebook" :src="static_fn('img/facebook-f-20@2x.png')" alt="twitter" /></a>
        <a v-if="user.youtube" :href="user.youtube"><img class="profile-facebook-f" :src="static_fn('img/facebook-f-21@2x.png')" alt="youtube" /></a>
        <a v-if="user.instagram" :href="user.instagram"><img class="profile-facebook" :src="static_fn('img/ic_instagram.png')" alt="instagram" /></a>
        <a v-if="user.tiktok" :href="user.tiktok"><img class="profile-facebook" :src="static_fn('img/ic_tiktok.png')" alt="tiktok" /></a>
        <a v-if="user.linkedin" :href="user.linkedin"><img class="profile-facebook-f" :src="static_fn('img/ic_linkedin.png')" alt="linkedin" /></a>
      </div>
      <div v-if="meUser && meUser.username != username" @click="handleFollow" class="profile-overlap-group-1 comment-action" :key="follow_text">
        <div class="profile-text valign-profile-text-middle inter-normal-white-17px-2">
          <span><span class="inter-normal-white-17px-2">{{follow_text}}</span> </span>
        </div>
      </div>
      <a v-else href="/accounts/edit_profile" class="profile-overlap-group-1 comment-action" >
        <div class="profile-text valign-profile-text-middle inter-normal-white-17px-2">
          <span><span class="inter-normal-white-17px-2">Edit Profile</span> </span>
        </div>
      </a>
      
      <div class="profile-group-427319015 inter-normal-eerie-black-17px-2">
        <img class="profile-recommend-1" :src="static_fn('img/recommend-1-25@2x.png')" alt="recommend 1" />
        <div class="profile-text-5"><span class="inter-normal-eerie-black-17px-2">{{user.reputation}}</span></div>
        <img class="profile-line-249" :src="static_fn('img/line-249-22@2x.png')" alt="Line 249" />
        <img class="profile-line-250" :src="static_fn('img/line-250-8@2x.png')" alt="Line 250" />
        <img class="profile-icon-user" :src="static_fn('img/user--multiple-1-41@2x.png')" alt="icon-user" />
        <div class="profile-text-7"><span class="inter-normal-eerie-black-17px-2">{{user.total_followers}}</span></div>
      </div>
    </header>
    <div class="profile-more mobile-show">
      <img class="profile-line-206" :src="static_fn('img/line-204-22@2x.png')" alt="Line 206" />
      <div class="profile-joined-24-years-ago valign-text-middle inter-normal-black-17px">
        <span><span class="inter-normal-black-17px">Joined: {{user.member_since}}</span> </span>
      </div>
      <img class="profile-line-205" :src="static_fn('img/line-204-22@2x.png')" alt="Line 205" />
      <div class="profile-nav_2">
        <div class="profile-group-427319004">
          <img class="profile-phgender-male-fill" :src="static_fn('img/ph-gender-male-fill-7@2x.png')" alt="ph:gender-male-fill" />
          <div class="profile-text-8 inter-normal-eerie-black-17px-2">
            <span class="inter-bold-eerie-black-17px">Gender:</span>
            <span class="inter-normal-eerie-black-17px-2"> {{choice[user.sex]}}</span>
          </div>
        </div>
        <div class="profile-group-427319005">
          <img class="profile-icon-location_pin" :src="static_fn('img/fluent-location-28-regular-7@2x.png')" alt="icon-location_pin" />
          <div class="profile-text inter-normal-eerie-black-17px-2">
            <span class="inter-bold-eerie-black-17px">Location:</span>
            <span class="inter-normal-eerie-black-17px-2">{{ user.city }}, {{ user.country }}</span>
          </div>
        </div>
        <div class="profile-group-427319006">
          <img class="profile-icon-heart" :src="static_fn('img/ph-heart-light-7@2x.png')" alt="icon-heart" />
          <div class="profile-text inter-normal-eerie-black-17px-2">
            <span class="inter-bold-eerie-black-17px">Cuisines I love:</span
                                                                        ><span class="inter-normal-eerie-black-17px-2">{{ user.cuisines_i_love }}</span>
          </div>
        </div>
        <div class="profile-group-427319007">
          <img class="profile-phfork-knife" :src="static_fn('img/ph-fork-knife-7@2x.png')" alt="ph:fork-knife" />
          <div class="profile-text-9 inter-bold-eerie-black-17px">
            <span class="inter-bold-eerie-black-17px">Cuisines I Cook:</span
                                                                        ><span class="inter-normal-eerie-black-17px-2">{{ user.cuisines_i_cook }}</span>
          </div>
        </div>
      </div>
      <img class="profile-line-204" :src="static_fn('img/line-204-22@2x.png')" alt="Line 204" />
    </div>
  <TipsModal v-if="user && user.gov_id_proof_status === 'Approved' && showTip" tipType="profile" :user="user" @closed="onHideDetail"/>
<!-- user.gov_id_proof_status === 'Approved' && -->
  </template>
</SideMenuPage>
</template>

<script>

import { mapMutations, mapActions, mapGetters } from "vuex";
import ProfileBio from './ProfileBio.vue';
import GroupList from './GroupList.vue';
import GroupLine from './GroupLine.vue';
import PostListContainer from './PostListContainer.vue';
import PostList from './PostList.vue';
import SideMenuPage from './SideMenuPage.vue';
import UserList from './UserList.vue';
import TipsModal from "./TipsModal.vue";
import MyReferals from "./MyReferals.vue";

/* eslint-disable */
export default {
  name: 'Profile',
  mixins: [PostListContainer],
  props: {
    fullname: {type: String},
    username: { type: String },
    isMobile: { type: Boolean },
      touchHandler: { type: Function },
      showSideBar: { type: Function },
    hideSideBar: { type: Function },
    tab: {
      type: String,
      default: function() {
        return 'bio';
      }
    },
    cat: {
      type: String,
      default: function() {
        return 'any';
      }
    },
  },
  components: {
    ProfileBio,
    GroupList,
    SideMenuPage,
    TipsModal
},
  computed : {
    badge: function () {
      return this.static_fn(this.user.full_badge_image || 'img/image-2-2@2x.png');
    },
    follow_text: function() {
      return this.user.follow_state ? "Unfollow" : "Follow";
    },
    tab_cat: function() {
      return `${this.tab}/${this.cat ? this.cat: "any"}`;
    },
    category: function() {
      return this.categories.find(obj => {return obj.name == this.cat})
    },
    myctx: function () {
      return !this.tab ? 'user' : {
        'bio': 'stories',
        'member_of_groups': 'groups',
        'my_recipe': 'user',
        'want_recipe': 'make',
        'followers': 'followers'
      }[this.tab];
    },
    tabs: function() {
      return [
        {
          title: 'Bio',
          routeName: 'profile',
          tab: 'bio',
          comp: ProfileBio,
          img: 'img/carbon-document-1@2x.png',
          props: {
            user: this.user,
            username: this.username,
          }
        },
        {
          title: `My Groups (${this.user ? this.user.num_groups : 0})`,
          tab: 'member_of_groups',
          routeName: 'profile',
          img: 'img/fluent-people-team-16-regular-1@2x.png',
          comp: GroupList,
          props: {
            category: this.username,
            comp: GroupLine,
            numrows: 1,
            rowlen: 4,
            mrowlen: 4,
            framekls: 'profile-x1',
            arrowMore: false,
            viewMore: false,
            isMobile: this.isMobile,
            urlp: {
              'app': 'accounts',
              'view': 'list',
            }
          },
        },
        {
          title: 'My Recipes',
          tab: 'my_recipe',
          img: 'img/group-20@2x.png',
          which: 'user',
          summary: this.user.my,
          routeName: 'profile',
          comp: PostList,
          props: !this.category ? {} : {
            category: this.category.name,
            comp: this.category.comp,
            numrows: 3,
            rowlen: this.category.rowlen,
            mrowlen: this.category.mrowlen,
            framekls: this.category.framekls,
            arrowMore: false,
            viewMore: false,
            isMobile: this.isMobile,
            rparams: {
              'username': this.username
            }
          }
        },
        {
          title: 'Recipes I Want to Make',
          tab: 'want_recipe',
          img: 'img/star-svgrepo-com--2--1-8@2x.png',
          which: 'make',
          summary: this.user.want,
          routeName: 'profile',
          comp: PostList,
          props: !this.category ? {} : {
            category: this.category.name,
            comp: this.category.comp,
            numrows: 3,
            rowlen: this.category.rowlen,
            mrowlen: this.category.mrowlen,
            framekls: this.category.framekls,
            arrowMore: false,
            viewMore: false,
            isMobile: this.isMobile,
            rparams: {
              'username': this.username
            }
          }
        },
        {
          title: `Followers (${this.user ? this.user.total_followers : 0})`,
          tab: 'followers',
          img: 'img/carbon-user-favorite-alt-1@2x.png',
          routeName: 'profile',
          comp: UserList,
          props: {
            category: this.username,
            comp: 'UserLine',
            numrows: 10,
            rowlen: 4,
            mrowlen: 4,
            framekls: "profile-x1",
            arrowMore: false,
            isMobile: this.isMobile,
            viewMore: false,
          }          
        },
        {
          title: `Referral`,
          tab: 'referrals',
          img: 'img/fluent-people-team-16-regular-1@2x.png',
          routeName: 'referals',
          comp: MyReferals,
          hideTab: this.user.gov_id_proof_status != 'Approved',
          props: {
            user: this.user,
            username: this.username,
          }          
        },
      ];
    }
  },
  data(){
    return {
      // checking: true,
      meUser:{},
      choice: {
        '1': 'Male',
        '2': 'Female',
        '3': "I'd rather not say"
      },
      showTip: false,
      user: {},
      timeout: null

    }
  },
  created() {
    this.getInitial();
      //window.addEventListener("resize", this.myEventHandler);
      this.$watch(
          () => this.$route.params,
          (newval, oldval) => {
              let usernew = newval ? newval.username : '';
              let userold = oldval ? oldval.username : '';
              if (usernew && userold && usernew != userold) {
                  this.getInitial();
              }
          },
          { immediate: true }
      )
  },
  destroyed() {
      //window.removeEventListener("resize", this.myEventHandler);
  },
  mounted(){
      this.timeout = setTimeout(()=>{
          this.showTip = true
      }, 15000);
  },
  methods:{
    ...mapMutations(["setViewContext"]),
    ...mapActions(["postOrLogin"]),
    ...mapGetters(["getUserInfo"]),

    
    onHideDetail() {
      this.showTip = false;
    },

    splitString(instr) {
      if (!instr)
        return []
      let splt = instr.split(/[ .,]+/).filter(Boolean);
      return (splt.length < 10 && splt[0].split(' ').length < 3) ? splt : [instr]
    },
    getInitial(){
      // this.timeout = setTimeout(()=>{
      //   this.showTip = true
      // }, 5000);
      this.meUser = this.getUserInfo();
      this.api.get(`/api/accounts/profile/detail/${this.username}/`).then((profile) => {
        profile.dishes_i_love_most = this.splitString(profile.dishes_i_love_most);
        profile.dishes_i_cook_best = this.splitString(profile.dishes_i_cook_best);
        this.user = { ... profile,name:profile.full_name };



        let date = new Date(this.user.member_since);
        var months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
        this.user.member_since = months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
      }).catch (this.pageErr.bind(this));
    },
    pageErr(err) {
      console.log ("getPage " + err.code + " " + err.message);
    },
    handleFollow() {
      this.checking = true
      this.postOrLogin(`accounts/follow/toggle/${this.user.pk}`).then((data) => {
        console.log('data',data);
        this.user.follow_state = data.state;
        this.user.total_followers = data.count;
        for (let i = 0; i < this.tabs.length; i++) {
          if (this.tabs[i].name == "followers") {
            this.tabs[i].count = data.count;
          }
        }
      }).finally(()=>{
        this.checking = false;
      });
    },
    // updateMe(){
    //   let u = this.getUserInfo();
    //   return (u && u.username == this.username);
    //   // return true;
    // }
  },
  
}
</script>

<style>
a {
    cursor: pointer;
}
.image-checkmark{
  height: 30px;
  widows: 30px;
}
.hide {
  display: none !important; 
}

.comment-action {
  position: relative;
}

.image {
  height: 26px;
  object-fit: cover;
  position: absolute;
  width: 26px;
}
.image-3 {
  right: 0px;
  bottom: 0px;
}

.profile-body .body {
    margin-top: 0;
    padding: 0;
}
</style>
