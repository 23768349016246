<template>
  <div class="vid-cont">
    <div v-if="index == 0 && height == 0" class="thumb-place">
      <img :src="ifirst.image" ref="pimage" 
           :srcset="ifirst.thumbs" class="quickie-thumb-img"
           sizes="(min-width: 598px) and (min-height: 900px) 598px, (min-width: 480px) and (min-height: 768px) 480px, (min-width: 420px) and (min-height: 720px) 420px, (min-width: 360px) and (min-height: 660px) 360px,  (min-width: 300px) and (min-height: 598px) 200px, (min-width: 240px) and (min-height: 598px) 160px, 160px" style="object-fit: cover;" @load="handleLoad" />
    </div>
    <div v-else-if="height > 0" class="player-cont">
      <video ref="videoPlayer"
             :width="width" :height="height" playsinline 
             v-bind:style="{ 'width': width, 'height': height }"
             :options="options">
        <source :src="src">
      </video>
      <div class="play-button-screen" @click="pauseVideo">
        <img v-if="state == 6" v-bind:class="thumbZ" :src="static_fn('vpause.png')" />
        <img v-else-if="state == 3" v-bind:class="playZ" :src="static_fn('vplayer.png')"/>
        <img v-if="congrats == true" v-bind:class="congratZ" :src="static_fn('well_done.png')" :key="congrats"/>
        <div v-if="caption == true && state == 4" v-bind:class="captionZ">
          <p> {{post.title}}
          </p>
        </div>
      </div>
      <VideoDetail v-if="isMobile"
                   :sizes="sizes"
                   v-bind:index="index"
                   :posts="posts"
                   :category="category"
                   v-bind:height="height"
                   v-bind:width="width">
      </VideoDetail>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-console */

//import UserLine from './UserLine';
// import AsyncButton from './AsyncButton';
//window.VIDEOJS_NO_DYNAMIC_STYLE = true;
import VideoDetail from './VideoDetail';
import videojs from 'video.js';
const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
  return typeof obj;
} : function (obj) {
  return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

function isObject(value) {
  return !!value && (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object';
}

function isEl(value) {
  return isObject(value) && value.nodeType === 1;
}
export default {
  name: 'Quickie',
  components: {
    // AsyncButton,
    //UserLine,
    VideoDetail
  },
  props: [
    'first',
    'posts',
    'sizes',
    'index',
    'width',
    'height',
    'category'
  ],
  data() {
    return {
      ifirst: (this.first.preps) ? this.first.preps[0] : this.first,      
      'adjSizes':  "(min-width: 598px) and (min-height: 900px) 598px, (min-width: 480px) and (min-height: 768px) 480px, (min-width: 420px) and (min-height: 720px) 420px, (min-width: 360px) and (min-height: 660px) 360px,  (min-width: 300px) and (min-height: 598px) 200px, (min-width: 240px) and (min-height: 598px) 160px, 160px",
      state: 0,
      hasPlayed: false,
      'src': this.first.video_file,
      options: {
        sources: [{
          //type: "video/mp4",
          'src': this.first.video_file,
          }],
        //'poster': (this.post.post_image || this.post.image),
        controls: 0,
        autoplay: 1,
        normalizeAutoplay: true,
        playsinline: true,
        //muted: true,
        loop: 1,
        loadingSpinner: false,
        errorDisplay: false,
        preload: 'auto',
        width: this.width,
        height: this.height,
        html5: {
          hls: {
            enableLowInitialPlaylist: true, 
            bandwidth: 200000  // to target 480 height
          }
        }
      },
      'congrats': false,
      'player': null,
      og_duration: 0,
      timeout: 60,
      caption: false,
      windowCutoff: 600,
      timeoutID: null,
      visible: true,
      extras: false
    };
  },
  computed: {
    thumbZ: function() {
      return {
        'play-button': true, //thumb-cont': true,
        'thumb-top': this.state == 6
      };
    },
    playZ: function() {
      return {
        'play-button': true, //thumb-cont': true,
        'smaller': true, //thumb-cont': true,
        'thumb-top': this.state == 3 //|| this.state == 2
      };
    },
    congratZ: function() {
      return {
        'congrat-img': true,
        'fade-in': this.congrats,
        'fade-out': !this.congrats
      };
    },
    captionZ: function() {
      return {
        'caption-mobile-recipe': true,
        'fade-in': this.caption,
        'fade-out': !this.caption
      };
    },
    winwidth: { 
      get: () => {
        var win = window,
            doc = document,
            docElem = doc.documentElement,
            body = doc.getElementsByTagName('body')[0],
            y = win.innerWidth|| docElem.clientWidth || body.clientWidth;
        return y;
      },
    },
    winheight: { 
      get: () => {
        var win = window,
            doc = document,
            docElem = doc.documentElement,
            body = doc.getElementsByTagName('body')[0],
            y = win.innerHeight|| docElem.clientHeight || body.clientHeight;
        return y;
      },
    },
    isMobile: function() {
      console.log ("Winwidth is " + this.winwidth);
      return this.winwidth <= 600;
    },
    post: function() {
      return this.posts[this.index];
    },
  },
  /* States:
0 : not prepped, no size
1 : got size
2 : got videojs obj
3 : got duration 
4 : playing
5 : stopped
6 : paused
*/
  updated() {
    this.src = this.post ? this.post.video_file : null;
    this.options.sources = [{src: this.src}];
    log.info ("Updating video * " + this.height + " index " + this.index + " file " + this.post.video_file + " state: " + this.state + " og " + this.og_duration + " src " + this.src);
    
    this.$nextTick(function() {
      this.buildPlayer();
      if (this.state == 1 && !this.hasPlayed) {
        this.startVideo();
        //this.state = 2;
      }
    });
  },
  beforeDestroy() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
    if (this.player) {
      this.player.dispose()
    }
    document.removeEventListener("visibilitychange", this.changeVisibility);
  },
  mounted() {
    this.src = this.post ? this.post.video_file : null;
    this.options.sources = [{src: this.src}];
    log.info ("Mounted video file winwidth " + this.winwidth + " first " + this.ifirst.image + " height " + this.height + " source " + this.post + " index " + this.index + " LEN " + this.posts.length + " video file " + this.post.video_file  + " SRC " + this.src + " Mobile " + this.isMobile);
    this.$nextTick(function() {
      this.buildPlayer();
      document.addEventListener("visibilitychange", this.changeVisibility);
    });
  },
  watch: {
    index: {
      handler(val) {
        log.info ("Index change from " + this.index + " to " + val + " state: " + this.state);
        this.src = this.post ? this.post.video_file : null;
        this.state = 0;
        this.caption = false;
        this.congrats = false;
        this.player.src({'src': this.src,
                         'type': 'video/mp4'});
        this.player.load();
        /* The player has played in the past, so set off play */
        if (this.hasPlayed) { 
          this.player.play();
        }
      }
    },
  },
  methods: {
    changeVisibility() {
      if (this.visible && this.state == 4) {
        this.visible = false;
        this.pauseVideo();
      }
      else if (!this.visible) {
        this.visible = true;
        this.pauseVideo();
      }
    },
    buildPlayer() {
      var that = this
      log.info ("Building " + that.height + " and " + that.player + " Is el " + that.post.pk + " obj: " + isEl(that.$refs.videoPlayer) + " document: " +
                document.body.contains(that.$refs.videoPlayer))
      if (that.height && !that.player) {
        if (isEl(that.$refs.videoPlayer) && document.body.contains(that.$refs.videoPlayer)) { 
          log.info ("Video js con for  " + that.post.pk + " Refs is " + that.options.height);
          try { 
            that.player = videojs(that.$refs.videoPlayer, that.options, function onPlayerReady() {
              this.on ("play", function() {
                that.onPlay();
              });
              
              that.$refs.videoPlayer.width = that.width;
              that.$refs.videoPlayer.height = that.height;
              this.width(that.width);
              this.height(that.height);
              this.on('loadedmetadata' , function() { //canplay', function() {
                if (that.state == 0) {
                  log.info ("Loaded meta data");
                  that.state = 2;
                  that.$emit ("vidloaded");
                }
              });
              this.on('canplay' , function() { //canplay', function() {
                if (that.state == 0 || that.state == 2) {
                  log.info ("Can play through, emitting " + that.state + " : " + that.hasPlayed);
                  //alert ("Can play through, emitting " + that.state + " : " + that.hasPlayed);
                  that.state = 1;
                  if (!that.hasPlayed) {
                    this.muted(false);
                    this.autoplay(false);
                  }
                }
              });
            });
          }
          catch(error)  {
            log.error ("Error rendering player " + error)
            if (that.player) {
              that.player.dispose();
              that.player = null;
            }
            log.info ("Can't construct with dims " + that.width + " x " + that.height + " dom: " + error);
          }
        }
        else {
          log.error ("Error on document " + that.post.pk + " obj: " + isEl(that.$refs.videoPlayer) + " document: " + document.body.contains(that.$refs.videoPlayer) + " height " + that.height);
          that.player = null;
        }
      }
    },
    pauseVideo() {
      //alert ("Pause video called " + this.post.pk + " state: " + this.state + " ;aused " + this.$refs.videoPlayer.paused + " vs " + this.player.paused());
      
      if (this.state == 4) { 
        this.player.pause();
        this.state = 6;
      }
      else if (this.state >= 1) {
        this.startVideo();
      }
    },
    __stopVideo() {
      log.info ("Stop video ** " + this.post.pk + " state: " + this.state + " ;aused " + this.player.paused() + " playing ");
      this.player.currentTime(0);
      //this.$refs.videoPlayer.currentTime = 0;
      if (this.state == 4) {
        this.player.pause();
        //this.$refs.videoPlayer.pause();
      }
      this.state = 1;
    },
    stopVideo() {
      this.__stopVideo();
    },
    playFail() {
      // We will pause the video and give them a play button and ability to navigate 
      this.player.pause();
      if (this.state == 1)
        this.state = 2;
      log.info ("Timeout playfail " + this.post.pk);
      //alert ("Tiemout playing");
    },
    startVideo() {
      var that = this;
      log.info ("Start video called " + this.post.pk + " state: " + this.state + " paused " +  this.$refs.videoPlayer.paused + " vs " + this.player.paused());
      /* If we are prepared, i.e. 1 or more, and not already playing, i.e. not 4 */
      if (this.state >= 1 && this.state != 4) {
        /* If this has never played, we don't actually know that it will work, as it may require user action */
        if (!this.hasPlayed) {
          this.timeoutID = setTimeout (function() {
            if (that.timeoutID)
              clearTimeout (that.timeoutID);
            that.timeoutID = null;
            that.playFail();
          }, 3000);
        }
        that.player.play().then(() => {
          //We already have an onplay handler, we're really only doing this for error logging
          //that.onPlay();
        }).
          catch( (error) => {
            log.error ("Start interrupted " + that.post.pk + " Error " + error);
            this.state = 3;
            //alert ("Error playing " + error);
          });
      }
    },
    onPlay() {
      log.info ("Really playing? " + this.post.pk)
      if (this.timeoutID)
        clearTimeout (this.timeoutID);
      this.timeoutID = null;
      log.info ("Play causing Vid loaded emitting " + this.post.pk);
      if (this.state == 0){ 
        this.$emit ("vidloaded");
      }

      //alert ("Playing " + this.hasPlayed + " state " + this.state);
      /* If we got here from state 2, then there was no canplay event, i.e. an iphone, so we pause and give the play button and wait for next one */
      if (this.state != 1 && !this.hasPlayed) { //this.state == 2) {
        this.state = 1;
        this.player.pause();
      }
      else {
        if (this.player.muted() == true)
          this.player.muted(false);
        
        this.hasPlayed = true;
        this.state = 4;
        if (this.winwidth < this.windowCutoff) {
          var that = this;
          that.caption = true;
          setTimeout (function() {
            that.caption = false;
          }, 5000);
        }
      }
    },
    handleLoad() {
      let img = this.$refs.pimage;

      if (!this.height) {
        log.info ("Load handle**" + this.height + " for " + this.post.pk + " : " + img.clientWidth + " x " + img.clientHeight + " state " + this.state);
        this.$emit("loaded", img.height, img.width);
      }
    },
    runVideo() {
      log.info ("Run video called " + this.post.pk + " state: " + this.state);
      switch (this.state) {
        case 3:
        case 5:
        //this.startVideo();
        break;
      }
    },
    handleClose() {
      this.$emit("closed");
    },
    toggleExtras() {
      this.extras = !this.extras;
    },
  }
}
</script>


