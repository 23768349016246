<template>
<div class="given_comments" v-if="post.num_comments">
  <img class="line-206" :src="static_fn('img/line-206-7@2x.png')" alt="Line 206" />
  <div class="post-text-12 valign-post-text-middle inter-semi-bold-eerie-black-20px">
    <span><span class="inter-semi-bold-eerie-black-20px">{{post.num_comments}} Comments</span> </span>
  </div>
  <PostComment class="comment-item" v-for="comment in show_items" :key="comment.pk" :comment="comment" :post="post" :user="user" :onDelete="()=>{onCommentDelete(comment)}">
  </PostComment>
  <div class="post-text-16" v-if="!expand && post.num_comments > 2">
    <a  class="post-text-16 valign-post-text-bottom inter-normal-eerie-black-17px-2" @click="toggleExpand">
      <span><span class="inter-normal-eerie-black-17px-2">View All Other Comments</span> </span>
    </a>
  </div>
  <div v-else class="post-text-16">
    <a v-if="nextPageURL" class="post-text-16 valign-post-text-bottom inter-normal-eerie-black-17px-2" @click="getNextPage" style="margin-right: 100px">
      <span class="inter-normal-eerie-black-17px-2">View More</span>
    </a>
    <div class="way-container" v-else-if="nextPageURL !== '' || loading == 'E'">
      <Loading :loading="'L'" :key="loading" :loaderror="loading == 'E'" class="way-div" v-waypoint="{ active: true, callback: onWaypointDown, options: intersectionOptions }" id="More">
      </Loading>
    </div>
    <a class="post-text-16 valign-post-text-bottom inter-normal-eerie-black-17px-2" @click="toggleExpand">
      <span><span class="inter-normal-eerie-black-17px-2">Hide Comments  </span> </span>
    </a>
  </div>
</div>
</template>
<script>
  /* eslint-disable no-console */
import ItemList from './ItemList';
import PostComment from './PostComment';

export default {
  mixins: [ItemList],
  name: 'CommentList', 
  components: {
    PostComment,
  },
  props: {
    'post': Object,
    'user': Object,
  },
  data() {
    return {
        expand: false,
        intersectionOptions: {
            root: null,
            //rootMargin: '0px 0px 0px 0px',
            threshold: [0.25, 0.75] // [0.25, 0.75] if you want a 25% offset!
        },
        loading: null, //false,
    }
  },
  computed: {
    show_items() {
      return this.expand ? this.items : this.items.slice(0, 2);
    },
  },
  methods: {
    setupURL() {
        this.url = `/api/posts/comments/list/${this.post.pk}`;
    },
    toggleExpand() {
      this.expand = !this.expand;
    },
    setPage(data) {
        data.map((e) => this.items.push(e));
    },
    onCommentDelete(comment) {
      this.api.delete(`posts/delete/comments/${comment.id}`, {}, true).then((data) => {
        if (data.success ){
          this.$emit("ondeletecomment", this.post.num_comments -1)
          this.$bvToast.toast(`Comment has been deleted`, {
            title: `Success`,
            variant: 'success',
            autoHideDelay: 5000,
          })
        }else{
          this.$bvToast.toast(`We could not process the request.`, {
            title: `Please try again`,
            variant: 'danger',
            autoHideDelay: 5000,
          })
        }
      }).catch((error)=>{
        console.log(error);
        this.$bvToast.toast(`We could not process the request.`, {
          title: `Please try again`,
          variant: 'danger',
          autoHideDelay: 5000,
        })
      }).finally(() => {
        this.overlayLoading = false
      });
    }
  },
}
</script>
