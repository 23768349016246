<template>
  <div class="body">
    <LoadingOverlay v-if="overlayLoading"/>

    <div id="" class="my-earning screen">
      <div class="back" @click="$router.back()">
        <div class="overlap-group-8">
          <div class="page-disabled">
            <div class="ic-carat-left"></div>
          </div>
          <img class="vector-3" src="../assets/vector-20@2x.png" alt="Vector" />
        </div>
        <div class="place inter-medium-log-cabin-17px"><span class="inter-medium-log-cabin-17px">Back</span></div>
      </div>
      <div class="overlap-group71">
        <h1 class="text-27 valign-text-middle inter-bold-black-25px">
          <span><span class="inter-bold-black-25px">My Earnings</span> </span>
        </h1>
        <div class="balance-row">
          <h2>Available Funds</h2>
          <div class="withdraw-div">
            <h1>${{walletBalance}}</h1>
            <div class="submit-btn" @click="handleWithdraw">
                <div class="overlap-group6">
                  <div class="center-text text valign-text-middle inter-regular-white-17px">
                    <span
                      ><span class="inter-normal-white-17px" >Withdraw</span>
                    </span>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="group-427319040">
          <b-row class="w-100" lg="2"	>
            <b-col><date-picker 
              v-model="dateRangeFilter" 
              placeholder="Select Filter Date Range" 
              format="MMM DD, YYYY"
              date-format="MMM DD, YYYY"
              type="date"
              :disabled-date="disabledDate"
              range>
            </date-picker></b-col>
          </b-row>
          <b-row class="w-100">
            <b-table
              :thClass="['custom-thead']"
              :striped="striped"
              :bordered="bordered"
              :borderless="borderless"
              :outlined="outlined"
              :hover="hover"
              :items="items"
              :fields="fields"
              :head-variant="headVariant"
            ></b-table>
          </b-row>
          <b-row class="w-100" align-content="end">
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalCount"
              :per-page="pagination.perPage"
              first-number
              last-number
              @change="changePageNumber"
              class="end-content"
            ></b-pagination>
          </b-row>
        </div>
      </div>
    </div>
    <footer class="footer">
      <h4 class="text-center">&copy; Khal: All rights reserved </h4>
    </footer>
    <WithdrawRequestModal v-if="this.showWithdrawModal" :balance="walletBalance" @closed="onHideDetail" @refetch="fetchEarningReport"/>

  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapGetters } from 'vuex';
import LoadingOverlay from "./LoadingOverlay.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import WithdrawRequestModal from './WithdrawRequestModal.vue';



export default {
  name: 'MyEarning',
  components: { 
    DatePicker,
    LoadingOverlay,
    WithdrawRequestModal 
  },
  computed: {
    user () {
      return this.getUserInfo()
    },

  },
  props: {
    
  },
  watch:{
    dateRangeFilter(){
      this.fetchEarningReport()
    }
  },
  data() {
    return {
      showWithdrawModal:false,
      errors:[],
      overlayLoading:false,
      striped: false,
      bordered: false,
      borderless: false,
      outlined: false,
      hover: true,
      headVariant: 'light',
      pagination: {
        totalCount: 0,
        perPage: 5,
        currentPage: 1,
        offset:0
      },
      items: [],
      walletBalance: 0,
      dateRangeFilter:[],
      fields: ['Date', 'Description', 'Amount'],

    };
  },
  mounted() {
    
  },
  updated() {
  
  },
  created: function(){
    this.fetchEarningReport();
  },
  methods: {
    ...mapGetters(['getUserInfo']),
    disabledDate(date) {
      return date > new Date();
    },
    onHideDetail() {
      this.showWithdrawModal = false;
    },
    changePageNumber(pageNum){
      this.pagination.currentPage=pageNum;
      this.fetchEarningReport()
    },
    handleWithdraw(){
      if(this.walletBalance >= 20){
        this.showWithdrawModal = true
      }else{
        this.$bvToast.toast(`Hey, you must have minimum $20 in your wallet to create a withdrawal request`, {
          title: `Cannot withdraw your balance`,
          variant: 'danger',
          autoHideDelay: 5000,
        })
      }
    },
    fetchEarningReport(){
      this.overlayLoading = true;
      let payload = {
        limit: this.pagination.perPage,
        offset: (this.pagination.currentPage-1) * this.pagination.perPage
      }
      if(this.dateRangeFilter.length > 0 && this.dateRangeFilter[0] && this.dateRangeFilter[1]){
        payload['from_date'] = this.dateRangeFilter[0].toLocaleDateString('en-CA');
        payload['to_date'] = this.dateRangeFilter[1].toLocaleDateString('en-CA');
      }

      console.log(payload);
      this.api.get(`api/monetization/user-earnings`,payload).then((data) => {
        if(data.success){
          if(data.total){
            this.walletBalance = data.total
          }
          if(data.result){
            let tempList = []
            this.pagination.totalCount = data.count;
            data.result.forEach(function(earning) {
              tempList.push({ Date: earning.created_at, Description: earning.description, Amount: '$'+earning.receiver_received_amount },)
            });
            this.items = tempList
          }
        }
        
      }).finally(() => {
        this.overlayLoading = false
      });
    },

    handleWithdrawRequest() {
      
    },
  }
}
</script>
<style>
.body {
}
  .field-message{
    color:#333
  }
  .balance-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    border: 1px solid #CCDBE2;
    border-radius: 10px;
    margin-top: 30px;
  }

  .balance-row h2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    margin: 0px;
    /* identical to box height */
    color: #000000;
    align-self: center;
  }

  .my-earning .submit-btn{
    display: flex;
    width: 100%;
    align-items: flex-end;
  }

  .balance-row .withdraw-div{
    display: flex;
    flex-direction: row;
  }

  .withdraw-div h1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #000000;
    margin-right: 20px;
    align-self: center;
    margin: 0px 20px 0px 0px;
  }

  .my-earning .overlap-group71 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-right: 4.0px;
  margin-top: 25px;
  padding: 44px 36px;
  width: 100%;
}
.my-earning .text-27 {
  align-self: flex-start;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}


.my-earning {
  align-items: flex-start;
  background-color: var(--zumthor-2);
  border: 1px none;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width:100%;
}

.end-content{
  justify-content: end;
}

.page-link{
  z-index: auto;
}

.my-earning .back {
  align-items: center;
  display: flex;
  gap: 17px;
  min-width: 94px;
  cursor: pointer;
  width: -webkit-fill-available;
}

.my-earning .overlap-group6 {
  align-items: center;
  margin-left: auto;
  background-color: var(--eerie-black);
  border-radius: 50px;
  display: flex;
  margin-right: 14px;
  padding: 13px 30px;
  font-weight: 400;
  font-size: 17px;
  cursor: pointer;
  width: auto;
  overflow: hidden !important;
}

.center-text{
  margin: auto;
}

.my-earning .overlap-group-8 {
  border-radius: 115.62px;
  height: 37px;
  position: relative;
  width: 37px;
}

.my-earning .page-disabled {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 115.62px;
  display: flex;
  height: 37px;
  left: 0;
  overflow: hidden;
  padding: 4.6px 4.6px;
  position: absolute;
  top: 0;
  width: 37px;
}

.my-earning .ic-carat-left {
  
  background-image: url('../assets/ic---carat---down-13@2x.png');
  background-size: 100% 100%;
  height: 28px;
  width: 28px;
}

.my-earning .vector-3 {
  height: 12px;
  left: 14px;
  position: absolute;
  top: 12px;
  width: 7px;
}

.my-earning .place {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2.0px;
  min-height: 21px;
  min-width: 40px;
}

.my-earning .group-427319040 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-right: 6.0px;
  margin-top: 40px;
  width: 100%;
}


.table>thead {
  background: #EDF4FC !important;
  border-radius: 10px !important;
  padding: 10px;
} 

.table>thead::after {
  border-radius: 10px !important;
  position: absolute;
} 


.table>thead > tr > th{
  padding: 15px;
}

.table>thead> tr > th:first-child{
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px
}

.table>thead> tr > th:last-child{
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px
}
      

.w-100{
  width: 100%;
}

.pagination-outer{ text-align: center; }
.pagination{
    font-family: 'Poppins', sans-serif;
    display: inline-flex;
    position: relative;
}
.pagination li button.page-link, .pagination li span.page-link{
    color: #000;
    background-color: #eee;
    font-size: 18px;
    font-weight: 500;
    line-height: 37px;
    height: 37px;
    width: 37px;
    padding: 0;
    margin: 0 5px;
    border-radius: 0;
    border: none;
    overflow: hidden;
    position: relative;
    z-index: 1;
    text-align: center;
    transition: all 0.4s ease 0s;
}
.pagination li:first-child button.page-link,
.pagination li:last-child button.page-link{
    font-size: 25px;
    line-height: 35px;
    font-weight: 300;
}
.pagination li button.page-link:hover,
.pagination li button.page-link:focus,
.pagination li.active button.page-link:hover,
.pagination li.active button.page-link{
    color: #fff;
    background-color: transparent;
}
.pagination li button.page-link:before{
    content: '';
    background-color: #000;
    height: 100%;
    width: 100%;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: all 0.3s ease 0s;
}
.pagination li button.page-link:hover:before,
.pagination li button.page-link:focus:before,
.pagination li.active button.page-link:hover:before,
.pagination li.active button.page-link:before{
    border-radius: 50% 0 0 50%/50% 0 0 50%;
    opacity: 1;
    transform: rotate(90deg);
}
@media only screen and (max-width: 480px){
    .pagination{
        font-size: 0;
        display: inline-block;
    }
    .pagination li{
        display: inline-block;
        vertical-align: top;
        margin: 10px 0;
    }
}

.mx-datepicker > div > input {
  border: 1px solid #CCDBE2; 
  border-radius: 50px;
  height: 50px;
}


</style>
