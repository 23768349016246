<template>
<div :class="kls">
  <div class="doubts container-fluid icomment-form comment-new">
    <div class="form-group row">
      <form v-html="inhtml"/>
      <a class="btn btn-primary btn-right comment-save comment-edit" @click="commentEdit">
        <span class="glyphicon glyphicon-upload"></span>
      </a>
      <a class="btn btn-danger btn-right comment-save comment-del" @click="commentDel">
        <span class="glyphicon glyphicon-trash"></span>
      </a>
    </div>
  </div>
</div>
</template>
<script>

const getLogger = require('webpack-log');
const log = getLogger({ name: 'vue-khal' });
import { mapMutations } from 'vuex';
//import AsyncButton from './AsyncButton';

export default {
  //extends: AsyncButton,
  name: 'EditableComment',
  components: {
  },
  props: [
    'parent',
    'comment',
    'abase',
    'epoint',
    'kls',
    'inhtml',
    'inkey',
  ],
  data() {
    return {
    }
  },
  /*beforeCreate() {
    this.$options.components.PostComments = require("./PostComments.vue").default;
  },*/
  mounted() {
    log.info ("Editable comment " + this.comment  + " and " + this.kls + " and " + this.api);
    if (this.comment)
      log.info ("Editable ** " + this.comment.num_replies);
    this.$nextTick(function() {
      log.info ("El query selector");
      let input = this.$el.querySelector('.comment-input');
      if (input) { 
        input.addEventListener('keypress',
                               this.handleKey.bind(this))
        input.focus();
      }
      else {
        this.showLogin();
      }
      
      log.info ("El query selector done");
    })
  },
  methods: {
    ...mapMutations(['showLogin']),
    handleKey(event) { 
      if (event.which == 13 && !event.shiftKey) {
        event.preventDefault();
        this.commentEdit();
      }
    },
    commentDel() {
      if (!this.comment) {
        this.$emit('hide');
      }
    },
    commentEdit() {
      let inp = this.$el.querySelector('.comment-input');
      let data = {
        'text': inp.value
      }
      let url = "/" + this.abase + "/e" + this.epoint + "/" + this.parent.pk  + "/" + this.inkey + "/";
      log.info ("Input Data is " + data.text + " and abase " + this.url);
      this.api.asyncButton(url, data).then((data) => {
        log.info ("Out Data is " + data);
        this.$emit("clicked");
      });
    }
  }
}
</script>
